import { thecauldronpools } from "../../configure";

const tokens = {
  56: {},

};

thecauldronpools.map(({chainId, token, tokenAddress,tokenAddress2,tokenAddress3 })=> {
  tokens[chainId][token] = {
    tokenAddress: tokenAddress,
    tokenAddress2: tokenAddress2,
    tokenAddress3: tokenAddress3,
    tokenBalance: 0
  }
  tokens[chainId] = {
    tokenBalance: 0
  }
  return '';
})

// console.log(tokens)

const initialState = {
  thecauldronpools,
  tokens,
};

export default initialState;