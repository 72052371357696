import React from "react";
import Grid from "@material-ui/core/Grid";
import { Row, Col } from "reactstrap";
import { StakePools } from "features/stake/sections";
import Countdown from "react-countdown";
import "moment-timezone";
import Hidden from "@material-ui/core/Hidden";
import { useTime2 } from "../redux/hooks";
import { Box, Typography } from "@material-ui/core";
import logo12 from "assets/img/g_logo.png"


export default function SectionTitle({ pool }) {

  const time2 = useTime2(pool.earnContractAddress);

  return (
    <Grid container style={{ paddingTop: "4px", paddingLeft: "16px" }} id="container">

<Row className="mt-5 w-100">
          <Col md={12}>
              <Box className="nftttl_bx nftttl_bx_v2">
                  <Box className="img_g_bx">
                      <Box component="img" src={logo12}/>
                  </Box>
                  <Typography component="h3">Completed Pools</Typography>
                  <Typography component="p">please remove funds, pools will disapear 30 days after completion.</Typography>
              </Box>
              
          </Col>
          
          
          
      </Row>

    {/*   <StakePools />
      <div
        className="row boost_info"
        style={{ marginLeft: "auto", marginRight: 10 }}
      >
        {time2 != 0 ? (

          <div
            className="row boost_info"
            style={{ marginLeft: "auto", marginRight: 10 }}
          >
            <div className="col text-right mt-2">
              <h6>
                Boost Pool Timelock:
            <br />15 Days
             </h6>
            </div>
            <div className="col text-right mt-2">
              <h6>
                Boost Timelock Remaining
               <br />
                {time2 && <Countdown date={time2 * 1000} />}

              </h6>
            </div>
          </div>

        ) : (
          <Hidden smDown>
            <Grid
              item
              xs={2}
              container
              justify="center"
              alignItems="center"
            ></Grid>
          </Hidden>

        )}
      </div> */}


    </Grid>
  );
}
