
export const zapOrSwap = async ({web3, address, address2, contract, call, call2, amount, dispatch, router}) => {
  // console.log(`=====================================deposit begin=====================================`)
  console.log(`
    address:${address}\n
    address2:${address2}\n
    call:${call}\n
    call2:${call2}\n
    amount:${amount}
  `)
  const data = await _zapOrSwap({web3, address,address2, contract, call,call2, amount, dispatch, router});
  // console.log(`=====================================deposit success=====================================`)
  return data;
}

const _zapOrSwap = ({contract, amount, call, call2, address, address2, dispatch, router}) => {
  return new Promise((resolve, reject) => {
    // console.log(isAll)
    if (call==='deposit') {
    contract.methods[call]().send({ from: address, gas: 1000000, value:amount }).on('transactionHash', function(hash){
      console.log(hash)

    })
    .on('receipt', function(receipt){
      resolve()
    })
    .on('error', function(error) {
      console.log(error)
      reject(error)
    })
    .catch((error) => {
      console.log(error)
      reject(error)
    })

  } else if (call2==='easySell3') {
    contract.methods[call](amount).send({ from: address, gas: 1000000, }).on('transactionHash', function(hash){
      console.log(hash)
      console.log(contract)

    })
    .on('receipt', function(receipt){
      resolve()
    })
    .on('error', function(error) {
      console.log(error)
      reject(error)
    })
    .catch((error) => {
      console.log(error)
      reject(error)
    })

  } else if (call==='depositTokens') {
    contract.methods[call]( address2, amount).send({ from: address, gas: 1000000,}).on('transactionHash', function(hash){
      console.log(hash)
      console.log(address2)

    })
    .on('receipt', function(receipt){
      resolve()
    })
    .on('error', function(error) {
      console.log(error)
      reject(error)
    })
    .catch((error) => {
      console.log(error)
      reject(error)
    })

  } else if (call2==='easyBuy2') {
    contract.methods[call]().send({ from: address, gas: 1000000, value:amount}).on('transactionHash', function(hash){
      console.log(hash)
      console.log(contract)

    })
    .on('receipt', function(receipt){
      resolve()
    })
    .on('error', function(error) {
      console.log(error)
      reject(error)
    })
    .catch((error) => {
      console.log(error)
      reject(error)
    })

  } else if (call2==='std') {
    const nowInSeconds = Math.floor(Date.now() / 1000)
    const expiryDate = nowInSeconds + 900;

    contract.methods[call](amount, 0, ['0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7', '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619', '0x01fa5b3a5d77bcf705dd505bbcbb34bce310e7fe'], address, expiryDate ).send({ from: address, gas: 2000000 }).on('transactionHash', function(hash){
      console.log(hash)
      console.log(expiryDate)

    })
    .on('receipt', function(receipt){
      resolve()
    })
    .on('error', function(error) {
      console.log(error)
      reject(error)
    })
    .catch((error) => {
      console.log(error)
      reject(error)
    })

  } else if (call2==='std2') {
    const nowInSeconds = Math.floor(Date.now() / 1000)
    const expiryDate = nowInSeconds + 900;   

    contract.methods[call](amount, 0, ['0x01fa5b3a5d77bcf705dd505bbcbb34bce310e7fe', '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619', '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7'], address, expiryDate ).send({ from: address, gas: 2000000 }).on('transactionHash', function(hash){
    })
    .on('receipt', function(receipt){
      resolve()
    })
    .on('error', function(error) {
      console.log(error)
      reject(error)
    })
    .catch((error) => {
      console.log(error)
      reject(error)
    })

  } else if (call2==='stda') {
    const nowInSeconds = Math.floor(Date.now() / 1000)
    const expiryDate = nowInSeconds + 900;

    contract.methods[call](amount, 0, ['0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7', '0x01fa5b3a5d77bcf705dd505bbcbb34bce310e7fe'], address, expiryDate ).send({ from: address, gas: 2000000 }).on('transactionHash', function(hash){
      console.log(hash)
      console.log(expiryDate)

    })
    .on('receipt', function(receipt){
      resolve()
    })
    .on('error', function(error) {
      console.log(error)
      reject(error)
    })
    .catch((error) => {
      console.log(error)
      reject(error)
    })

  } else if (call2==='std2a') {
    const nowInSeconds = Math.floor(Date.now() / 1000)
    const expiryDate = nowInSeconds + 900;   

    contract.methods[call](amount, 0, ['0x01fa5b3a5d77bcf705dd505bbcbb34bce310e7fe', '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7'], address, expiryDate ).send({ from: address, gas: 2000000 }).on('transactionHash', function(hash){
    })
    .on('receipt', function(receipt){
      resolve()
    })
    .on('error', function(error) {
      console.log(error)
      reject(error)
    })
    .catch((error) => {
      console.log(error)
      reject(error)
    })


  } else {

  contract.methods[call](amount).send({ from: address,gas: 1000000, value:amount }).on('transactionHash', function(hash){
    console.log(hash)

  })
  .on('receipt', function(receipt){
    resolve()
  })
  .on('error', function(error) {
    console.log(error)
    reject(error)
  })
  .catch((error) => {
    console.log(error)
    reject(error)
  })
   } })
}