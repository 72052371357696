import { nftpools } from "../../configure";

const tokens = {
  137: {},

};

nftpools.map(({chainId, token, tokenAddress,tokenAddress2,tokenAddress3 })=> {
  tokens[chainId][token] = {
    tokenAddress: tokenAddress,
    tokenAddress2: tokenAddress2,
    tokenAddress3: tokenAddress3,
    tokenBalance: 0
  }
  tokens[chainId] = {
    tokenBalance: 0
  }
  return '';
})

// console.log(tokens)

const initialState = {
  nftpools,
  tokens,
  time1:[],
};

export default initialState;