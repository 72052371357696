/* eslint-disable */
import React, { useState } from 'react';
import BigNumber from "bignumber.js";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Grid from '@material-ui/core/Grid';
import { useConnectWallet } from '../../home/redux/hooks';
import { useFetchPoolBalances} from '../redux/hooks';
import sectionPoolsStyle from "../jss/sections/sectionPoolsStyle";
import PoolHeader from './PoolHeader'
import PoolContent from './PoolContent';
const useStyles = makeStyles(sectionPoolsStyle);

export default function SectionPools() {
  const { networkId } = useConnectWallet();
  let { stakingpools } = useFetchPoolBalances();
  const [openedCardList, setOpenCardList] = useState([]);
  const classes = useStyles();

  const openCard = id => {
    return setOpenCardList(
      openedCardList => {
        if (openedCardList.includes(id)) {
          return openedCardList.filter(item => item !== id)
        } else {
          return [...openedCardList, id]
        }
      }
    )
  }



  const forMat = number => {
    return new BigNumber(
      number
    ).multipliedBy(
      new BigNumber(10000)
    ).dividedToIntegerBy(
      new BigNumber(1)
    ).dividedBy(
      new BigNumber(10000)
    ).toNumber()
  }


  return (

      <Grid item xs={12} className="mt-3 col-12" >


        {stakingpools.filter(pool => (pool.chainId === networkId )).map((pool, index) => (

          <Accordion className="cstmaccrdn"
            expanded={Boolean(openedCardList.includes(index))}
            TransitionProps={{ unmountOnExit: true }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={(event) => {
                event.stopPropagation();
                openCard(index)
              }}
            >
              <PoolHeader index={index} pool={pool} classes={classes} openedCardList={openedCardList} openCard={openCard} />
            </AccordionSummary>
            <AccordionDetails>
              <PoolContent index={index} pool={pool} classes={classes} openedCardList={openedCardList} openCard={openCard} />
            </AccordionDetails>
          </Accordion>
        ))}

      
      </Grid>
      
  )
}
