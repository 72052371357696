import { staking1 } from "../../configure";

const pools = [

	{
		name: 'DMAGIC', 
		token: 'Stake dMagic & Boost your Stakings', 
		tokenDecimals: 18,
		tokenAddress: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7', 
		earnedToken: 'DMAGIC',
		earnedToken2: '',
		earnedTokenDecimals: 18,
		earnedTokenAddress: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',
		earnContractAddress: '0x8EeDBb427Dc81e6fF8884C4c31Fc0cf3875d202a',
		earnContractAbi: staking1
	},


];

const poolsInfo = [{
	name: "DMAGIC",
	staked: 0,
	tvl: 0,
	apy: 0,
}]


const allowance = [0];
const balance = [0];
const currentlyStaked = [0];
const canWithdrawTime = [0];


const initialState = {
	pools,
	allowance,
	currentlyStaked,
	canWithdrawTime,
	balance,
	poolsInfo,
	fetchPoolsInfoPending: false,
	checkApprovalPending: [false],
	fetchBalancePending: [false],
	fetchCurrentlyStakedPending: [false],
	fetchApprovalPending: [false],
	fetchStakePending: [false],
	fetchWithdrawPending: [false],
	fetchWithdraw2Pending: [false],
};

export default initialState;