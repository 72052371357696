import React from 'react';
import ReactDOM from 'react-dom';
import Root from './Root';
import * as serviceWorker from './serviceWorker';

import "assets/scss/material-kit-pro-react.scss?v=1.9.0";
import './styles/index.scss';
import "assets/css/blk-design-system-pro-react.css";
import './i18n';

ReactDOM.render(
      
        <>
         <Root />
        </>,
       
 document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();