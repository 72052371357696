import React,{ useState, useEffect } from 'react';
import classNames from "classnames";
import { useTranslation } from 'react-i18next';
import BigNumber from 'bignumber.js'
import moment from 'moment';
import { byDecimals } from 'features/helpers/bignumber';
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomButtons from "components/CustomButtons/Button.js";
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {stakePoolsStyle} from "../jss/sections/stakePoolsStyle";
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';

import { useConnectWallet } from '../../home/redux/hooks';
import { useCheckApproval, useFetchPoolsInfo, useFetchBalance, useFetchCurrentlyStaked, useFetchApproval, useFetchStake, useFetchWithdraw } from '../redux/hooks';

const useStyles = makeStyles(stakePoolsStyle);

export default function StakePool(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { address } = useConnectWallet();
  const { allowance, checkApproval } = useCheckApproval();
  const { pools } = useFetchPoolsInfo();
  const { balance, fetchBalance } = useFetchBalance();
  const { currentlyStaked, fetchCurrentlyStaked } = useFetchCurrentlyStaked();
  const { fetchApproval  } = useFetchApproval();
  const { fetchStake, fetchStakePending, fetchStake2 } = useFetchStake();
  const { fetchWithdraw, fetchWithdraw2 } = useFetchWithdraw();
  const [ index, setIndex] = useState(Number(props.match.params.index) - 1);
  const [ showInput, setShowInput ] = useState(false);
  const [ isNeedApproval, setIsNeedApproval] = useState(true);
  const [ stakeAble,setStakeAble ] = useState(true);
  const [ withdrawAble ] = useState(true);
  const [ myBalance,setMyBalance ] = useState(new BigNumber(balance[index]));
  const [ myCurrentlyStaked, setMyCurrentlyStaked] = useState(new BigNumber(currentlyStaked[index]));
  const [ inputVal, setInputVal] = useState(0);

  const changeInputVal = (event) => {
    let value = event.target.value;
    const changeIsNumber = /^[0-9]+\.?[0-9]*$/;
    if (!value) return setInputVal(value);
    if (changeIsNumber.test(value)) {
      value = value.replace(/(^[0-9]+)(\.?[0-9]*$)/, (word, p1, p2) => { 
        return Number(p1).toString() + p2;
      });
      if (new BigNumber(Number(value)).comparedTo(showInput === 'stake'?myBalance:myCurrentlyStaked) === 1) return setInputVal((showInput === 'stake'?myBalance.toString():myCurrentlyStaked.toString()));
      setInputVal(value)
    }
  }

  useEffect(() => {
    setIndex(Number(props.match.params.index) - 1);
  }, [Number(props.match.params.index)]);

  useEffect(() => {
    setIsNeedApproval(Boolean(allowance[index] === 0));
  }, [allowance[index], index]);


  const onApproval = () => {
    fetchApproval(index);
  }

  useEffect(() => {
    setStakeAble(!Boolean(fetchStakePending[index]));
  }, [fetchStakePending[index], index]);

  const onStake = () => {
    if (index === 0) {
    const amount = new BigNumber(inputVal).multipliedBy(new BigNumber(10).exponentiatedBy(pools[index].tokenDecimals)).toString(10);
    fetchStake(index, amount);
    } else {
      const amount = new BigNumber(inputVal).multipliedBy(new BigNumber(10).exponentiatedBy(pools[index].tokenDecimals)).toString(10);
      fetchStake2(index, amount);
    }
  }

  const momentFormatTime = (timestamp) =>{
    return moment(timestamp).format('YYYY-MM-DD HH:mm:ss')
  }

  const onWithdraw = () => {
    if (index === 0) {
    const amount = new BigNumber(inputVal).multipliedBy(new BigNumber(10).exponentiatedBy(pools[index].tokenDecimals)).toString(10);
    fetchWithdraw(index, amount);
    } else {
    const amount = new BigNumber(inputVal).multipliedBy(new BigNumber(10).exponentiatedBy(pools[index].tokenDecimals)).toString(10);
    fetchWithdraw2(index, amount);
    }
  }

  useEffect(() => {
    const amount = byDecimals(balance[index], pools[index].tokenDecimals);
    setMyBalance(amount);
  }, [balance[index], index]);

  useEffect(() => {
    const amount = byDecimals(currentlyStaked[index], pools[index].tokenDecimals);
    setMyCurrentlyStaked(amount);
  }, [currentlyStaked[index], index]);

  useEffect(() => {
    if (address) {
      checkApproval(index);
      fetchBalance(index);
      fetchCurrentlyStaked(index);
      const id = setInterval(() => {
        checkApproval(index);
        fetchBalance(index);
        fetchCurrentlyStaked(index);
      }, 100000);
      return () => clearInterval(id);
    }
  }, [address, index]);

  return (
  
    <Grid container className="for_drk_bg3">
      <div className="space-100"></div>
  <CustomButtons
                      href={`/#/staking/`}
                      className={classes.stakeButton3}
                    >
                     back
                    </CustomButtons>
      
      <div className="space-50"></div>

      <Grid item xs={12}>
        <div className={classes.detailTitle}>{`Boost DRAX with ${pools[index].name}`}</div>
      </Grid>
      <Grid item xs={12} className={classes.detailContentUp}>
        <GridContainer spacing={3} className={classNames({
          [classes.contentTitle]:true,
          [classes.contentTitleMarginBottom]:true,
        })}>
          <GridItem sm={(index=== 3 || index===4) ? 4: 3} xs={12} className={classNames({
              [classes.contentTitleItem]:true,
              [classes.contentTitleItemBorder]:true,
            })}>
                 <br></br>
            <br></br>
              <Hidden smUp>
                
                <Typography variant="body2" gutterBottom className={classes.contentItemTitle}>{t('Stake-Balancer-Your-Balance')}</Typography>
              </Hidden>
              <div className={classes.contentItemTitleVal}>{`${Math.floor(myBalance.toNumber() * 10000)/ 10000} ${pools[index].name}`}</div>
              <Hidden xsDown>
                <div className={classes.contentItemTitle}>{t('Stake-Balancer-Your-Balance')}</div>
              </Hidden>
          </GridItem>
          <GridItem sm={(index=== 3 || index===4) ? 4: 3} xs={12} className={classNames({
            [classes.contentTitleItem]:true,
            [classes.contentTitleItemBorder]:true,
          })}>
            <Hidden smUp>
              <Typography variant="body2" gutterBottom className={classes.contentItemTitle}>{t('Stake-Balancer-Current-Staked')}</Typography>
            </Hidden>
            <br></br>
            <br></br>
            <div className={classes.contentItemTitleVal}>{`${Math.floor(myCurrentlyStaked.toNumber() * 10000)/ 10000} ${pools[index].name}`}</div>
            <Hidden xsDown>
              <div className={classes.contentItemTitle}>{t('Stake-Balancer-Current-Staked')}</div>
            </Hidden>
          </GridItem>
          </GridContainer>



          <GridContainer className={classNames({
          [classes.contentTitle]:true,
        })}>


          <GridItem sm={10} xs={2}>
          <div className="tbl_header2">
            <Grid >

             <span> Multiplier Curve</span>
            </Grid>
          </div>
          </GridItem>
        </GridContainer>


          <GridContainer  className={classNames({
          [classes.contentTitle]:true,
        })}>


          <GridItem sm={10} xs={12}>
          <div className="tbl_header2">
            <Grid item sm={3}>
             <span> 1.1x</span>
            </Grid>
            <Grid item sm={3}>
              1.2X
            </Grid>
            <Grid item sm={3}>
              1.3X
            </Grid>
            <Grid item sm={3}>
              1.4X
            </Grid>
            <Grid item sm={3}>
              1.5X
            </Grid>
          </div>
          </GridItem>
          
 
        </GridContainer>
     

        <GridContainer className={classNames({
          [classes.contentTitle]:true,
        })}>


          <GridItem sm={10} xs={12}>
          <div className="tbl_header2">
            <Grid item sm={3} >
             <span> 500 dMagic</span>
            </Grid>
            <Grid item sm={3}>
              1,000 dMagic
            </Grid>
            <Grid item sm={3}>
              1,700 dMagic
            </Grid>
            <Grid item sm={3}>
              2,600 dMagic
            </Grid>
            <Grid item sm={3}>
              3,700 dMagic
            </Grid>
          </div>
          </GridItem>
          
 
        </GridContainer>
     
     
      
      </Grid>
        <Grid item xs={12} className={classes.detailContentDown}>
          {
            showInput ? (
              <div>
                <GridItem className={classes.inputContainer}>
                  <div className={classes.flexBox}>
                    <div className={classes.inputAvatarContainer}>
                      <Avatar 
                        alt={pools[index].name}
                        src={require(`../../../images/${pools[index].name}-logo.png`)}
                        className={classes.inputAvatar}
                        />
                    </div>
                    <InputBase value={inputVal} style={{fontSize:'20px',lineHeight:'32px'}} onChange={changeInputVal} autoFocus 
                      className={classNames({
                        [classes.inputTxt]:true,
                        [classes.mobileInput]:true,
                      })} />
                    <Hidden xsDown>
                      <div className={classes.inputSubTxt}>{`Balance: ${showInput === 'stake' ? Math.floor(myBalance.toNumber() * 10000)/ 10000: myCurrentlyStaked.toString()}`}</div>

                    </Hidden>
                  </div>
                  <div className={classes.flexBox}>

    
                    <Hidden smUp>
                      <div style={{height:'38px',lineHeight:'38px',marginLeft:'0',paddingLeft:'8px',borderLeft:'1px solid rgb(255,255,255,0.1)'}} className={classes.inputTxt}>{pools[index].name}</div>
                    </Hidden>
                    <Hidden xsDown>
                      <button className="btn btn-stk"
                        disabled={!Boolean(showInput === 'stake' ? stakeAble : withdrawAble)}
                        onClick={showInput === 'stake' ? onStake: onWithdraw}
                        >
                        {showInput === 'stake' ? t('Stake-Button-Stake'):t('Stake-Button-Unstake-Tokens')}
                      </button>
                    </Hidden>
                    <Hidden xsDown>
                      <IconButton
                        className={classes.inputCloseIcon}
                        onClick={(event) => {
                          event.stopPropagation();
                          setShowInput(false);
                        }}
                      >
                        <i className="fa fa-times"/>
                      </IconButton>
                    </Hidden>
                  </div>
                </GridItem>
                <Hidden smUp>
                    <div style={{margin:'10px 0px 24px 45px'}} className={classes.inputSubTxt}>{`Balance: ${showInput === 'stake' ? myBalance.toString(): myCurrentlyStaked.toString()}`}</div>
                    <div className={classes.flexCenter}>
    
                    </div>
                    <div className={classes.flexCenter}>
                      <button className="btn btn-stk"
                            style={{width:'300px',height:'44px',marginBottom:'14px',marginRight:'0'}}
                            onClick={(event) => {
                              event.stopPropagation();
                              setShowInput(false);
                            }}
                            >
                            {t('Stake-Button-Unstake-Tokens')}
                      </button>
                    </div>
                </Hidden>
              </div>
            ) : (
              <GridContainer className={classes.contentTitle}>
                 <div className="space-50"></div>
                <GridItem md={3} sm={6} xs={12} className={classes.flexCenter} style={{'flexDirection':'column'}}>
                  { isNeedApproval ? (
                    <button className="btn btn-stk"
                      onClick={onApproval}
                      >
                      {t('Stake-Button-Approval')}
                    </button>
                  ): (<button className="btn btn-stk"
                    onClick={(event)=>{
                      event.stopPropagation();
                      setShowInput('stake');
                    }}
     >
                    {t('Stake-Button-Stake-Tokens')}
                  </button>
                  )}
                  <div className={classNames({
                    [classes.stakeHintContainer]:false,
                    [classes.stakeHintContainerHidden]:true,
                  })}></div>
                </GridItem>
               
                <GridItem md={3} sm={6} xs={12} className={classes.flexCenter} style={{'flexDirection':'column'}}>
                  <button className="btn btn-stk"
                    disabled={!Boolean(withdrawAble)}
                    onClick={(event)=>{
                      event.stopPropagation();
                      setShowInput('unstake');
                    }}
      >
                    {t('Stake-Button-Unstake-Tokens')}
                  </button>
                 
                </GridItem>

                <div className="space-100 aaa"></div>
                <div className="space-100 aaa"></div>
                <div className="space-100 aaa"></div>
              </GridContainer>
            )
          }
        </Grid>
    </Grid>
  )
}
