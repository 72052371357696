import React, { useEffect, useState, useCallback, } from 'react';
import ZapCommand from './sections/ZapCommand';
import appStyle from "./jss/sections/appStyle"
import { makeStyles } from "@material-ui/core/styles";
import {
  Col,Row, NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane
} from 'reactstrap'
import classnames from "classnames";
import ZapCommandSu from 'features/swapsu/sections/ZapCommandSu';
import Lottie from 'react-lottie';
import animationData from "../../lotties/zapper_confirm4";
import dmagic2 from '../../assets/img/dmagic2.png'
import zapper2 from '../../assets/img/Zapper2.svg'
import zapper3 from '../../assets/img/Zapper3.png'
import bottom_logo from '../../assets/img/bottom_logo.png'
import ssswp_ic from '../../assets/img/ssswp_icon.svg'
import Quickswap_BW from '../../assets/img/Quickswap_BW.svg'
import Hidden from '@material-ui/core/Hidden';
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Statistics from 'features/Statistic';


const useStyles = makeStyles(appStyle);
export default function ZapPage() {



  const classes = useStyles();

  const [hTabs, sethTabs] = React.useState(1);

  const toggleTabs = (e, stateName, index) => {
    e.preventDefault();
    switch (stateName) {
      case "hTabs":
        sethTabs(index);
        break;
      default:
    }
  };

  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };



  const [isStopped, setIsStopped] = useState(true);



  const handlePlay = useCallback(() => {
    setIsStopped(false);
  }, []);

  const handleStop = useCallback(() => {
    setIsStopped(true);
  }, []);

   

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  return (
    <>



        <div className="page-header content-center frldr" id="container">


      
          <Lottie 
            options={defaultOptions}
            height={1530}
            width={1530}
          />

          <div className="section section-pills text-center content-center for_drk_bg2">
    
     

            <div className="frbttmcntnt space-100">
              <img
                  className=""
                  width="348"
                  height="auto"
                  src={bottom_logo}
                  alt=""
                />
              <SnackbarContent className={classes.container}
                message={
                      <span >
                      <Statistics />
                      </span>
                  }
                  close
                  color="success"
                />
            </div>
            
            <div className="space-50 mt-4" />
            <Col xs={12} className=" mt-5">
        <label className="stk_lbl">
          {" "}
          Network Details:<span className="g_dot"></span>
          <span>Polygon</span> {' '}
     
          <a href="https://bsc.defimagic.io/#" >
          <span className="r_dot"></span>
          <span>BSC</span>
          </a>
        </label>
      </Col>
            <div id="navigation-pills " >
              <div className="title">
                <Hidden smDown>
                  <img
                    className="mr-2 fr_def text-center"
                    width="155"
                    height="auto"
                    background-color="#000000"
                    src={dmagic2}
                    alt="logo"
                  />
                  <br></br>
                  <img
                    className="mr-2 fr_def text-center"
                    width="30"
                    height="auto"
                    background-color="#000000"
                    src={zapper2}
                    alt="logo"
                  />
                  <img
                    className="mr-2 fr_def text-center"
                    width="80"
                    height="auto"
                    background-color="#000000"
                    src={zapper3}
                    alt="logo"
                  />
                </Hidden>
              </div>
              <Row className="justify-content-center">
                <Col md="12">
                  <br></br>
                  <p className="category"></p>
                  <Nav className="nav-pills-primary justify-content-center" pills role="tablist" >
                    <NavItem >
                      <NavLink
                        style={{
                          backgroundColor: '#000000'
                        }}
                        className={classnames({
                          active: hTabs === 1
                        }), 'btn btn_green btnzap c_black btn_wtgdnt_v2'}
                        onClick={(e) => toggleTabs(e, "hTabs", 1)}
                      >DRAX {' '}
                        <img
                          className="mr-2"
                          width="26"
                          height="26"
                          src={ssswp_ic}
                          alt="logo"
                        />
                          Sushiswap
                    </NavLink>
                    </NavItem>
                   {/*  <div className="space-25"></div> */}
                    <NavItem>
                      <NavLink
                        style={{
                          backgroundColor: '#000000'
                        }}
                        className={classnames({
                          active: hTabs === 2,
                        }), 'btn btn_green btnzap c_black btn_wtgdnt_v2'}
                        onClick={(e) => toggleTabs(e, "hTabs", 2)}
                      >NIGHT {' '}
                        <img
                          className="mr-2"
                          width="26"
                          height="26"
                          src={Quickswap_BW}
                          alt="logo"
                        />
                        
                         Quickswap
                    </NavLink>
                    </NavItem>

                  </Nav>
                  <TabContent className="tab-space" activeTab={"hTabs" + hTabs}>
                    <TabPane tabId="hTabs1"  >
                      <Row>
                        <Col xs={12} md={12} className="rmvpdngrsp">


                        
                          < ZapCommandSu
                            handlePlay={() => handlePlay("First Item is Clicked!")}
                        />
                   
                        </Col>
                      </Row>
                      <div className="space-100" />
                      <div className="space-50" />
                    </TabPane>
                    <TabPane tabId="hTabs2">
                      <Row>
                        <Col xs={12} md={12} className="rmvpdngrsp">
                          <ZapCommand
                            handlePlay={() => handlePlay("First Item is Clicked!")}
                          />
                        </Col>
                      </Row>
                      <div className="space-100" />
                      <div className="space-50" />
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
              {/* end nav pills */}
            </div>
          </div>
        </div>
        {/* <div className="space-100" />
        <div className="space-100" /> */}
        {/* <div className="space-100" /> */}
    </>
  );
}