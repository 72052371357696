import { useCallback,  } from 'react';
import BigNumber from "bignumber.js";
import { useDispatch, useSelector } from 'react-redux';
import {
  SWAP_FETCH_FULL_SHARE_BEGIN,
  SWAP_FETCH_FULL_SHARE_SUCCESS,
  SWAP_FETCH_FULL_SHARE_FAILURE,
} from './constants';
import { byDecimals } from 'features/helpers/bignumber';

export function fetchPricePerFullShare(token, receiveToken, amount) {
  return (dispatch, getState) => {
    // optionally you can have getState as the second argument
    dispatch({ type: SWAP_FETCH_FULL_SHARE_BEGIN });
    // Return a promise so that you could control UI flow without states in the store.
    // For example: after submit a form, you need to redirect the page to another when succeeds or show some errors message if fails.
    // It's hard to use state to manage it, but returning a promise allows you to easily achieve it.
    // e.g.: handleSubmit() { this.props.actions.submitForm(data).then(()=> {}).catch(() => {}); }
    const promise = new Promise((resolve, reject) => {
      // doRequest is a placeholder Promise. You should replace it with your own logic.
      // See the real-word example at:  https://github.com/supnate/rekit/blob/master/src/features/home/redux/fetchRedditReactjsList.js
      // args.error here is only for test coverage purpose.
      const { home, swap } = getState();
      const { address, web3 } = home;
      const { tokens } = swap;
      const item = tokens.filter(item => { return item.name === token })[0]
      const { abi, address: contractAddress} = item.receivableList.filter(item => { return item.name === receiveToken })[0].contract

      const contract = new web3.eth.Contract(abi, '0x7f8D518e8204702255e539236c90E9104292e8d7');
      const contract2 = new web3.eth.Contract(abi, '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff');


      if (item.name === "MATIC" && receiveToken === "DMAGIC" || item.name === "AXMATIC" && receiveToken === "DMAGIC " ) {
      contract.methods.estimateBuy(amount).call({ from: address }).then(
        data => {

          const newPricePerFullShare = byDecimals(data,token.Decimals).toFixed(4);
          dispatch({
            type: SWAP_FETCH_FULL_SHARE_SUCCESS,
            data: newPricePerFullShare,
            tokens
          });
          console.log(newPricePerFullShare)
          resolve();
        },
      ).catch(
        error => {
          dispatch({
            type: SWAP_FETCH_FULL_SHARE_FAILURE,
            tokens
          });
          reject(error.message || error);
        }
      )}

    // else IF
    else if (item.name === "DMAGIC" && receiveToken === "MATIC" || item.name === "DMAGIC" && receiveToken === "AXMATIC " ) {
      contract.methods.estimateSell(amount).call({ from: address }).then(
        data => {
          const newPricePerFullShare = byDecimals(data,token.Decimals).toFixed(4);
          dispatch({
            type: SWAP_FETCH_FULL_SHARE_SUCCESS,
            data: newPricePerFullShare,
            tokens
          });
          console.log(newPricePerFullShare)
          resolve();
        },
      ).catch(
        error => {
          dispatch({
            type: SWAP_FETCH_FULL_SHARE_FAILURE,
            tokens
          });
          reject(error.message || error);
        }
      )}

       // else IF
    else if (item.name === "DMAGIC" && receiveToken === "AXIOMS" ) {
      contract2.methods.getAmountsOut(amount, ['0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7', '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619', '0x01fa5b3a5d77bcf705dd505bbcbb34bce310e7fe']).call({ from: address }).then(
        data => {
          const newPricePerFullShare = byDecimals(data[2],18).toFixed(4);
          console.log(data)
          dispatch({
            type: SWAP_FETCH_FULL_SHARE_SUCCESS,
            data: newPricePerFullShare,
            tokens
          });
          console.log(newPricePerFullShare)
          resolve();
        },
      ).catch(
        error => {
          dispatch({
            type: SWAP_FETCH_FULL_SHARE_SUCCESS,
            tokens
          });
          reject(error.message || error);
        }
      )}

      else if (item.name === "AXIOMS" && receiveToken === "DMAGIC   " ) { 
        contract2.methods.getAmountsOut(amount, ['0x01fa5b3a5d77bcf705dd505bbcbb34bce310e7fe', '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619', '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7']).call({ from: address }).then(
          data => {
            const newPricePerFullShare = byDecimals(data[2],18).toFixed(4);
            console.log(data)
            dispatch({
              type: SWAP_FETCH_FULL_SHARE_SUCCESS,
              data: newPricePerFullShare,
              tokens
            });

            resolve();
          },
        ).catch(
          error => {
            dispatch({
              type: SWAP_FETCH_FULL_SHARE_SUCCESS,
              tokens
            });
            reject(error.message || error);
          }
        )}


    });
    
    return promise;
  }
}


export function useFetchPricePerFullShare() {
  // args: false value or array
  // if array, means args passed to the action creator
  const dispatch = useDispatch();

  const { pricePerFullShare} = useSelector(
    state => ({
      pricePerFullShare: state.swap.pricePerFullShare,
    })
  );

  const boundAction = useCallback(
    (tokens, receiveToken, amount) => dispatch(fetchPricePerFullShare(tokens, receiveToken, amount)),
    [dispatch],
  );

  return {
    pricePerFullShare,
    fetchPricePerFullShare: boundAction,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case SWAP_FETCH_FULL_SHARE_BEGIN:
      // Just after a request is sent
      return {
        ...state,
      };

    case SWAP_FETCH_FULL_SHARE_SUCCESS:
      // The request is success
      return {
        ...state,
        pricePerFullShare: action.data
      };

    case SWAP_FETCH_FULL_SHARE_FAILURE:
      // The request is failed
      return {
        ...state,
      };

    default:
      return state;
  }
}