import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  CardBody,
  CardHeader,
  CardFooter,
  CardTitle,
  Card,
} from "reactstrap";

export default function DemoFooter() {
  return (
    <>
      <footer className="footer bgsmbls01">
        <Container>
          <Row>
            <Col md="12">
              {/* <div className="space-100" /> */}
              <div className="prltv_s dsp_inlnbk ftrabsltimg">
                <img
                  alt="..."
                  className="img-fluid"
                  src={require("assets/img/drk_img_03_01.jpg")}
                />
                <div className="img_lines"></div>
                <div className="wtis_lines"></div>
              </div>
              <div className="text-center">
                <div className="prltv_s dsp_inlnbk mb-101">
                  <img
                    alt="..."
                    src={require("assets/img/footer_img.jpg")}
                    width="auto"
                  />
                  <div className="p_abslt">
                    <div className="frtplines"></div>
                    <div className="footer_lg_bx">
                      <img
                        alt="..."
                        src={require("assets/img/ftrtxt.png")}
                        className="img-fluid"
                      />
                    </div>
                    <div className="ftrmgklg">
                      <img
                        alt="..."
                        src={require("assets/img/ftrgrnlg.png")}
                        className="mgk_imglg"
                      />
                    </div>
                    <div className="ftrcttngtxt align-items-end justify-content-center">
                      <div className="lft_bx"></div>
                      <div className="rgt_bx"></div>
                      your gateway to cutting edge crypto
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <Row>
                  <Col md="4">
                    <Card className="card-profile profile-bg">
                      <CardHeader
                        style={{
                          backgroundImage:
                            "url(" +
                            require("assets/img/nftbg2backup.png") +
                            ")",
                        }}
                      >
                        <div className="card-avatar">
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <img
                              alt="..."
                              className="img img-raised"
                              src={require("assets/img/Leaf.png")}
                            />
                          </a>
                        </div>
                      </CardHeader>
                      <CardBody>
                        <CardTitle tag="h3">Dan Cohen (Leaf)</CardTitle>
                        <h6 className="category text-white">
                          Smart Contract Strategist & Development
                        </h6>
                        <p className="card-description">
                          Founder of DeFi Magic <br></br>
                          Founder of Axioms Advisory<br></br>
                          DeFi Innovator<br></br>
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>

              <div className="btn-wrapper profile text-center frlftrt_lns">
                <div className="ftr_b_blft"></div>
                <div className="ftr_b_brgt"></div>
                <div className="ftr_box">
                  <img
                    alt="..."
                    src={require("assets/img/ftr_img.png")}
                    className="img-fluid"
                  />
                  <p>Follow for updates and announcements:</p>
                  <div className="social_links">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://t.me/defimagic"
                    >
                      <i className="fab fa-telegram-plane"></i>
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://twitter.com/defi_magic"
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://defimagic.medium.com/"
                    >
                      <i className="fab fa-medium-m"></i>
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <div className="footer_btm_scnd">
          <Container>
            <Row>
              <Col md="12" className="d-flex">
                © 2021 DeFi Magic. All rights reserved.
                <p className="text-right ml-auto">
                  Although still considered experimental, Defi Magic Smart
                  Contracts have been successfully audited by Quillaudits
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
    </>
  );
}
