import React, { useState, useEffect } from "react";
import moment from "moment";
import { useConnectWallet } from "../features/home/redux/hooks";
import { Col, Row } from "reactstrap";
import BigNumber from "bignumber.js";

let maticPriceFeed = null;
let draxPriceFeed = null;
let dmagicPriceFeed = null;

let lastTimeSynced = new Date();

export const fetchMaticPrice = async () => {
  const nextSyncedTime = lastTimeSynced.getTime() + 3600000;
  if (maticPriceFeed === null || new Date().getTime() > nextSyncedTime) {
    console.log(
      "next fetch price feed time:",
      moment(nextSyncedTime).format("llll")
    );
    const response = await fetch(
      "https://min-api.cryptocompare.com/data/pricemultifull?fsyms=Matic&tsyms=USD"
    );
    maticPriceFeed = await response.json();
  }
  return maticPriceFeed;
};

export const fetchDraxPrice = async () => {
  const nextSyncedTime = lastTimeSynced.getTime() + 3600000;
  if (draxPriceFeed === null || new Date().getTime() > nextSyncedTime) {
    const response = await fetch(
      "https://api.coingecko.com/api/v3/simple/price?ids=drax&vs_currencies=usd"
    );
    draxPriceFeed = await response.json();
  }
  return draxPriceFeed;
};

export const fetchDmagicPrice = async () => {
  const nextSyncedTime = lastTimeSynced.getTime() + 3600000;
  if (dmagicPriceFeed === null || new Date().getTime() > nextSyncedTime) {

    const response = await fetch(
      "https://api.coingecko.com/api/v3/simple/price?ids=dark-magic&vs_currencies=usd"
    );
    dmagicPriceFeed = await response.json();
  }
  return dmagicPriceFeed;
};

export default function Statistics(props) {
  const { web3, address } = useConnectWallet();
  const [maticprice, setmaticprice] = useState();
  const [dmprice, setDmPrice] = useState(0);
  const [drprice, setDrPrice] = useState(0);

  useEffect(() => {
    const getMaticInfo = async () => {
      const {
        RAW: {
          MATIC: {
            USD: { PRICE },
          },
        },
      } = await fetchMaticPrice();
      const data = {
        MaticPrice: PRICE,
      };
      const dmagicMilPrice = PRICE;
      data.dmagicMilPrice = dmagicMilPrice;
      setmaticprice(data.dmagicMilPrice);
    };
      getMaticInfo();
    }
  , []);

  useEffect(() => {
    const getDraxInfo = async () => {
      const {
        drax: { usd },
      } = await fetchDraxPrice();
      const data = {
        DraxPrice: usd,
      };
      const draxMilPrice = usd;
      data.draxMilPrice = draxMilPrice;
      setDrPrice(data.draxMilPrice);
    };
 
      getDraxInfo();
    }
  , []);

  useEffect(() => {
    const getDmagicInfo = async () => {
      const {
        ["dark-magic"]: { usd },
      } = await fetchDmagicPrice();

      const data = {
        DmagicPrice: usd,
      };
      const dmagicMilPrice = usd;
      data.dmagicMilPrice = dmagicMilPrice;
      setDmPrice(data.dmagicMilPrice);
    };

      getDmagicInfo();
    
  }, []);

  useEffect(() => {
    if (address && web3) {
      const id = setInterval(() => {}, 500000);
      return () => clearInterval(id);
    }
  }, [address, web3]);

  return (
    <div className="pg_bttm_dv">
      <Row>
        <Col>
          <h5>Matic </h5>
          {BigNumber(maticprice).toFixed(2)} USD
        </Col>
        <Col>
          <h5>AxMatic</h5>
          {BigNumber(maticprice).toFixed(2)} USD
        </Col>

        <Col>
          <h5>DMagic</h5>
          {BigNumber(dmprice).toFixed(2)} USD
        </Col>

        <Col>
          <h5>DRAX</h5>
          {BigNumber(drprice).toFixed(2)} USD
        </Col>
      </Row>
    </div>
  );
}
