import { AxMatic, axMaticDirectABI, axMaticLiq, dragonDirect, sushirouter } from "../../configure";

const AxMaticAddress =       '0x1221591c1d77A9c334aBb0fe530ae6EE3aF51Af9'  // Matic <-> axMatic           checked 2
const axMaticDirectAddress = '0x15aE9edB45f54beb13778f8E0df83DBC45427921'  //  Matic <-> dMagic           checked 2
const dragonDirectAddress =  '0xD4c18Bf16Eb1e767c83E28382C4519A7736D75D8'  // Matic -> Dragon'            checked 2
const DRAXliqContract =      '0x1Ba3510A9ceEb72E5CdBa8bcdDe9647E1f20fB4b'  //                             checked 2
const routerAddress =        '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506'  // sushirouter


export const allowance = [
  {   // Confirmed Working 
    name: 'MATIC',
    address: '0x0000000000000000000000000000000000001010', //  checked
    pools: [{
      name: 'AxMaticAddress' ,
      address: AxMaticAddress, 
      allowance: 1
    },
    {
      name: 'axMaticDirectAddress' ,
      address: axMaticDirectAddress, 
      allowance: 1
    },
    {
      name: 'dragonDirectAddress' ,
      address: dragonDirectAddress, 
      allowance: 1
    }]
  },
  {  
    name: 'DMAGIC',
    address: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',  //  checked
    pools: [{
      name: 'axMaticDirectAddress',
      address: axMaticDirectAddress, 
      allowance: 0
    },
    {
      name: 'dragonDirectAddress',
       address: dragonDirectAddress, 
       allowance: 0
    },
    { 
      name: 'routerAddress' ,
      address: routerAddress, 
      allowance: 0,
  },
 ]
},
  {   
    name: 'AXMATIC',
    address: '0x1221591c1d77A9c334aBb0fe530ae6EE3aF51Af9',  //   checked
    pools: [
      {
        name: 'dragonDirectAddress',
        address: dragonDirectAddress, 
        allowance: 0,
      },
      {
      name: 'AxMaticAddress',
      address: AxMaticAddress, 
      allowance: 0,
    },
      {
      name: 'axMaticDirectAddress',
      address: axMaticDirectAddress, 
      allowance: 0,
    }
  ]
  },

  {   
    name: 'AXMATIC-DMAGIC-SLP',
    address: '0xf5a739B544b392a3ffF893a91c53e7408116dFc5',   // checked
    pools: [{
      name: 'DRAXliqContract' ,
      address: DRAXliqContract, 
      allowance: 0,
    }
    ]
  },

  {   
    name: 'DRAX',
    address: '0x1Ba3510A9ceEb72E5CdBa8bcdDe9647E1f20fB4b',   // checked   
    pools: [{
      name: 'dragonDirectAddress' ,
      address: dragonDirectAddress, 
      allowance: 0,
    }]
  },

  {   
    name: 'AXIOMS',
    address: '0x01fa5b3a5d77bcf705dd505bbcbb34bce310e7fe',   // checked   
    pools: [{
      name: 'routerAddress' ,
      address: routerAddress, 
      allowance: 0,
    }]
  },




]

export const tokens = [

  {  // Working !! 
    name: 'MATIC',
    description: 'MATIC',
    address: '0x0000000000000000000000000000000000001010', //  checked
    decimals: 18,
    balance: 0,
    pricePerFullShare: '0',
    receivableList:[
      {
        name: 'DMAGIC',
        type: 'zap',
        contract: {
          name: 'axMaticDirectAddress',
          address: axMaticDirectAddress,
          abi: axMaticDirectABI,
          call: 'easyBuy',
          call2: 'easyBuy2'
       }
      },
        {
          name: 'AXMATIC',
          type: 'zap',
          contract: {
           name: 'AxMaticAddress',
           address: AxMaticAddress,
           abi: AxMatic,
          call: 'deposit',
          call2: 'deposit'
          }
      },
      {
        name: 'DRAX',
        type: 'zap',
        contract: {
          name: 'dragonDirectAddress',
          address: dragonDirectAddress,
          abi: dragonDirect,
          call: 'easyBuy',
          call2: 'easyBuy2'
        },
    }
    ]
  },

  {  
    name: 'DMAGIC',
    description: 'DMAGIC',
    address: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',  //  checked
    decimals: 18,
    balance: 0,
    pricePerFullShare: '0',
    receivableList:[
      {
        name: 'MATIC',
        type: 'zap',
        contract: {
          name: 'axMaticDirectAddress',
          address: axMaticDirectAddress,
          abi: axMaticDirectABI,
          call: 'easySell',
          call2: 'easySell3'
        }
      },
      {
        name: 'DRAX ',
        type: 'zap',
        contract: {
          address2: '',  // 
          name: 'dragonDirectAddress',
          address: dragonDirectAddress,
          abi: dragonDirect,
          call: 'easyBuyFromDmagic',
          call2: 'easySell3'
        },
      },
      {
        name: 'AXMATIC ',
        type: 'zap',
        contract: {
          address2: '',  // 
          name: 'axMaticDirectAddress',
          address: axMaticDirectAddress,
          abi: axMaticDirectABI,
          call: 'easySellToAxMatic',
          call2: 'easySell3'
        },
      },

      {
        name: 'AXIOMS',
        type: 'zap',
        contract: {
          address2: '0x01fa5b3a5d77bcf705dd505bbcbb34bce310e7fe',  // 
          name: 'routerAddress',
          address: routerAddress,
          abi: sushirouter,
          call: 'swapExactTokensForTokensSupportingFeeOnTransferTokens',
          call2: 'stda',
          router: routerAddress,
        },
      },
    ]
  },
  {   
    name: 'AXMATIC',
    description: 'AXMATIC',
    address: '0x1221591c1d77A9c334aBb0fe530ae6EE3aF51Af9', //   checked
    decimals: 18,
    balance: 0,
    pricePerFullShare: '0',
    receivableList:[
      {
        name: 'DRAX  ',
        type: 'zap',
        contract: {
          address2: '',  // 
          name: 'dragonDirectAddress',
          address: dragonDirectAddress,
          abi: dragonDirect,
          call: 'easyBuyFromAxMatic',
          call2: 'easySell3'
        }
      },
      {
        name: 'MATIC ',
        type: 'zap',
        contract: {
          name: 'AxMaticAddress',
          address: AxMaticAddress,
          abi: AxMatic,
          call: 'withdraw',
          call2: 'easySell3'
        }
      },
      {
        name: 'DMAGIC ',
        type: 'zap',
        contract: {
          name: 'axMaticDirectAddress',
          address: axMaticDirectAddress,
          abi: axMaticDirectABI,
          call: 'easyBuyFromAxMatic',
          call2: 'easySell3'
        }
      },

    ]
  },
  {   
    name: 'AXMATIC-DMAGIC-SLP',
    description: 'AXMATIC-DMAGIC-SLP',
    address: '0xf5a739B544b392a3ffF893a91c53e7408116dFc5',  //  checked 2
    address2: '0xf5a739B544b392a3ffF893a91c53e7408116dFc5',  //  checked 2
    decimals: 18,
    balance: 0,
    pricePerFullShare: '0',
    receivableList:[
      {
        name: 'DRAX   ',
        type: 'zap',
        contract: {
          address2: '0xf5a739B544b392a3ffF893a91c53e7408116dFc5',  // checked 2
          name: 'DRAXliqContract',
          address: DRAXliqContract,
          abi: axMaticLiq,
          call: 'depositTokens',
          call2: 'depositTokens'
        },
      }
    ]
  },

  {   
    name: 'DRAX',
    description: 'DRAX',
    address: '0x1Ba3510A9ceEb72E5CdBa8bcdDe9647E1f20fB4b', //  checked 2
    decimals: 18,
    balance: 0,
    pricePerFullShare: '0',
    receivableList:[
      {
        name: 'MATIC   ',
        type: 'zap',
        contract: {
          name: 'dragonDirectAddress',
          address: dragonDirectAddress,
          abi: dragonDirect,
          call: 'easySellToMatic',
          call2: 'easySell3'
        }
      },
      {
        name: 'AXMATIC  ',
        type: 'zap',
        contract: {
         name: 'dragonDirectAddress',
         address: dragonDirectAddress,
         abi: dragonDirect,
        call: 'easySellToAxMatic',
        call2: 'easySell3'
        }
    },
    {
      name: 'DMAGIC  ',
      type: 'zap',
      contract: {
       name: 'dragonDirectAddress',
       address: dragonDirectAddress,
       abi: dragonDirect,
      call: 'easySellTodMagic',
      call2: 'easySell3'
      }
  }
    ]
  },

  {   
    name: 'AXIOMS',
    description: 'AXIOMS',
    address: '0x01fa5b3a5d77bcf705dd505bbcbb34bce310e7fe', //  checked 2
    decimals: 18,
    balance: 0,
    pricePerFullShare: '0',
    receivableList:[
      {
        name: 'DMAGIC   ',
        type: 'zap',
        contract: {
          address2: '0x01fa5b3a5d77bcf705dd505bbcbb34bce310e7fe',  // 
          name: 'routerAddress',
          address: routerAddress,
          abi: sushirouter,
          call: 'swapExactTokensForTokensSupportingFeeOnTransferTokens',
          call2: 'std2a',
          router: routerAddress,
        }
      },
    ]
  },


]

const initialState = {
  tokens,
  allowance,
  fetchBalancesPending: false,
  checkApprovalPending: false,
  fetchApprovalPending: false,
  fetchZapOrSwapPending: false
};

export default initialState;