
export const ezioupools = [
  { 
    chainId: 137,
    id: '1',
    tokenAddress: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',   // DMAGIC   
    tokenAddress2: '',  // AXMATIC
    tokenAddress3: '',  // MATIC
    earnContractAddress: '0xfFE4B4cB5855f587F2eDA4FD52b8B73952c1BBd5',
    title: "DMAGIC to MBAT",
    call: '1',
    image: "DMAGIC-logo",
    ratio: "1:1",
    image2: "NEWMBAT-logo",
    recieve: "MBAT",
    tokenDecimals: 18,
    returnTokenDecimals: 9,
  },
  { 
    chainId: 137,
    id: '2',
    tokenAddress: '0xEEf10C9Bf17c9d2C9619fd29447B231EA0Fde548',   // DMAGIC   
    tokenAddress2: '',  // AXMATIC
    tokenAddress3: '',  // MATIC
    earnContractAddress: '0xD99223236EF9576E28d3856514257dd1Df3A4A74',
    title: "NIGHT to DMAGIC",
    call: '1',
    tokenDecimals: 18,
    image: "NIGHTBANE-logo",
    ratio: "1:2",
    image2: "DMAGIC-logo",
  },

  { 
    chainId: 137,
    id: '3',
    tokenAddress: '0x1Ba3510A9ceEb72E5CdBa8bcdDe9647E1f20fB4b',   // DMAGIC   
    tokenAddress2: '',  // AXMATIC
    tokenAddress3: '',  // MATIC
    earnContractAddress: '0x64DebCd58c59e92f96Fd87F718F1d997f2C9B258',
    title: "DRAX TO DMAGIC",
    call: '2',
    tokenDecimals: 18,
    image: "DRAX-logo",
    ratio: "1:2",
    image2: "DMAGIC-logo",

  }, 

  { 
    chainId: 137,
    id: '4',
    tokenAddress: '0x01fA5b3A5d77BcF705DD505bBcBb34bce310E7FE',   // DMAGIC   
    tokenAddress2: '',  // AXMATIC
    tokenAddress3: '',  // MATIC
    earnContractAddress: '0x5DC29FA307eEed853220908Dc5c98cacD5919F54',
    title: "AXIOMS to DMAGIC",
    call: '3',
    tokenDecimals: 18,
    image: "AXIOMS-logo",
    image2: "DMAGIC-logo",
    ratio: "100:1"
  },
]
