import React from "react";
// import ParticleTwo from "./Particles/ParticleTwo";
import {
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";
import { SocialButtons } from 'components/SocialButtons'


export default function First() {
  return (
    <>
     <div className="bgsmbls01">
    <section>
      <div className="cd-section" id="testimonials">
        <div className="testimonials-1 pb-0 as-pt-0 mnsmrgn">
        

        <Container>
        
        <Row>
        
          <Col lg="6" className="cstm_col_6">
            <img
              alt="..."
              className="img-fluid mb-3"
              src={require("assets/img/drkmgc_lg.png")}
            />
            <p className="def_p sb_p">Welcome, brave Heroes from the Kingdom of Axioms!</p>
            <p className="def_p p_txind">Through the cryptic portal you’ve crossed, unbinding yourselves from the <span className="greentxt">Lands of Ethereum</span>, and entering into the realm of magic, where new opportunities await and enchanting adventures begin. </p>
            
          </Col>
          
          <Col lg="6" className="cstm_col_7">
            <div className="prltv_s mb-182">
              <div className="sc01_imgtp_lines"></div>
              <img
                alt="..."
                className="img-fluid w-100"
                src={require("assets/img/drk_img_01.jpg")}
              />

              <div className="img_lines"></div>
              <div className="sc01_bx_lines"></div>
              
            </div>
            
          </Col>
          
        </Row>
        
      </Container>
        </div>
        
      </div>
      </section>
      <div className="cd-section" id="testimonials">

        <div className="testimonials-3 pt-0 pb-0">


          <Container>
            <Row className="row-grid justify-content-between align-items-center text-left ">
            </Row>
            <Row className="fnt175">
            <Col lg="7">
              <div className="prltv_s mb-45">
                <img
                  alt="..."
                  className="img-fluid"
                  src={require("assets/img/drk_img_02.jpg")}
                />
                <div className="sc2_box_1"></div>
                <div className="lgwtlns">
                  <img
                    alt="..."
                    className="img-fluid"
                    src={require("assets/img/mgk_lg_wtln.png")}
                  />
                </div>
                <div className="sc2_box_2"></div>
                <div className="sc2_box_3"></div>
                <div className="bx_tp_ovrl"></div>
                <div className="bx_bt_ovrl"></div>
              </div>
              
            </Col>
            <Col lg="5" className="fr_rsp_p">
              <p className="def_p">The Wizards of the Cypher Guild, once trapped in the Mystical Cube and now possessed by its powerful ancient magic, have escaped across their liquid portal into the Smartlands of Binance. Meanwhile the Emerald Dragons, whos’ mighty breath of dark magic helped free the wizards, have returned through the Gateway of Hash, and back to the stone Mountains of Polygon.</p>
              <p className="def_p">Your quest to harness the all-powerful ancient magic released from the mystical cube starts now. Are you wise and brave enough to challenge the Wizards as they conquer the <span className="greentxt">Smartlands of Binance</span>? Or will you test your agility and cunning in the jagged Mountains of Polygon, daring to face a sleeping dragon while they guard their treasures?</p>
              <p className="def_p">The most formidable and the most foolhardy amongst you will try both.</p>
              <p className="def_p">To those of greatest courage, I leave you with this sacred blessing:</p>
              <p className="def_p def_p_w_qt">
                May the heavens of good fortune shine upon you. When there is fear, breathe deep, be brave and hold strong. When blood pours and the weak tremble, be bold and gather, as bleeding will always stop. When your load is heavy with ample bounty, be thankful, humble and in gratitude, and most important you must rest and take profit, so you can do battle another day.
                <img
                alt="..."
                className="qtimg"
                src={require("assets/img/qt_img.svg")}
              />
              </p>
              <p className="def_p pr-205">
                <span className="greentxt">
                  -LairTu<br />
                  The noble and mighty paladin
                </span>
              </p>
            </Col>
              {/* <div
                className="carousel slide carousel-team"
                id="carousel-testimonials3"
              >
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="info text-left bg-dark">
                      <div className="icon icon-white">
                      <p className="subtitle text-secondary">
                      Your gateway
                      </p>
                      </div>
                      <p className="description text-white">
                      Your gateway to cutting edge projects, all powered by BInance Smart Chain...
                      Your gateway to cutting edge projects, all powered by BInance Smart Chain...
                      Your gateway to cutting edge projects, all powered by BInance Smart Chain...
                      </p>
                      <div className="author">
                      
                        <span>Leaf, Axioms</span>
                      </div>
                    </div>
                    <Card
                      className="card-blog card-background"
                      data-animation={true}
                    >
                      <div
                        className="full-background"
                        style={{
                          backgroundImage:
                            "url(" +
                            require("assets/img/magic_gateway.jpg")
                               +
                            ")",
                        }}
                      />
                      <CardBody>
                        <div className="content-bottom ml-auto">
                        
                        </div>
                      </CardBody>
                    </Card>
                  </div>

                </div>
              </div> */}
            </Row>
          </Container>
        </div>
 
      </div>
      </div>
    </>
  );
}
