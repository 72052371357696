import { erc20ABI, nft1 } from 'features/configure/abi';
import { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../common/redux/actions';
import { ethers } from 'ethers';
import { useConnectWallet } from '../../home/redux/hooks'; 
export { useFetchPoolBalances } from './fetchPoolBalances';


export function useApprove(tokenAddress, poolAddress) {
    const { web3, address } = useConnectWallet();
    const [isPending, setIsPending] = useState(false);
    const dispatch = useDispatch();


    const handleApprove = useCallback(async () => {
        setIsPending(true);
        try {
            await new Promise((resolve, reject) => {
                const contract = new web3.eth.Contract(erc20ABI, tokenAddress);

                contract.methods.approve(poolAddress, ethers.constants.MaxUint256.toString(10)).send({ from: address })
                .on('transactionHash', function(hash){
                    dispatch(enqueueSnackbar({
                        message: hash,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'success'
                        },
                        hash
                    }));
                })
                .on('receipt', function(receipt){
                    resolve()
                })
                .on('error', function(error) {
                    console.log(error)
                    reject(error)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
            });
        } finally {
            setIsPending(false);
        }
    }, [dispatch, setIsPending, web3, address, poolAddress, tokenAddress]);

    return { isPending, onApprove: handleApprove };
  }

  export function useApprove2(tokenAddress2, poolAddress) {
    const { web3, address } = useConnectWallet();
    const [isPending, setIsPending] = useState(false);
    const dispatch = useDispatch();


    const handleApprove = useCallback(async () => {
        setIsPending(true);
        try {
            await new Promise((resolve, reject) => {
                const contract = new web3.eth.Contract(erc20ABI, tokenAddress2);

                contract.methods.approve(poolAddress, ethers.constants.MaxUint256.toString(10)).send({ from: address })
                .on('transactionHash', function(hash){
                    dispatch(enqueueSnackbar({
                        message: hash,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'success'
                        },
                        hash
                    }));
                })
                .on('receipt', function(receipt){
                    resolve()
                })
                .on('error', function(error) {
                    console.log(error)
                    reject(error)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
            });
        } finally {
            setIsPending(false);
        }
    }, [dispatch, setIsPending, web3, address, poolAddress, tokenAddress2]);

    return { isPending, onApprove2: handleApprove };
  }


  export function useApprove3(tokenAddress3, poolAddress) {
    const { web3, address } = useConnectWallet();
    const [isPending, setIsPending] = useState(false);
    const dispatch = useDispatch();


    const handleApprove = useCallback(async () => {
        setIsPending(true);
        try {
            await new Promise((resolve, reject) => {
                const contract = new web3.eth.Contract(erc20ABI, tokenAddress3);

                contract.methods.approve(poolAddress, ethers.constants.MaxUint256.toString(10)).send({ from: address })
                .on('transactionHash', function(hash){
                    dispatch(enqueueSnackbar({
                        message: hash,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'success'
                        },
                        hash
                    }));
                })
                .on('receipt', function(receipt){
                    resolve()
                })
                .on('error', function(error) {
                    console.log(error)
                    reject(error)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
            });
        } finally {
            setIsPending(false);
        }
    }, [dispatch, setIsPending, web3, address, poolAddress, tokenAddress3]);

    return { isPending, onApprove3: handleApprove };
  }



export function useAllowance(tokenAddress, spender) {
    const { web3, address } = useConnectWallet();
    const [allowance, setAllowance] = useState("0");

    const fetchAllowance = useCallback(async () => {
        if (tokenAddress === '') {
            setAllowance(ethers.constants.MaxUint256.toString(10))
            return;
        }
        const contract = new web3.eth.Contract(erc20ABI, tokenAddress);

        const allowance = await contract.methods.allowance(address, spender).call()
        setAllowance(allowance)
    }, [address, spender, setAllowance, tokenAddress, web3])

    useEffect(() => {
        if (web3 && address) {
            fetchAllowance()
        
        let refreshInterval = setInterval(fetchAllowance, 10000)
        return () => clearInterval(refreshInterval)  }
    }, [web3, address, fetchAllowance])

    return allowance
}

export function useAllowance2(tokenAddress2, spender) {
    const { web3, address } = useConnectWallet();
    const [allowance2, setAllowance2] = useState("0");

    const fetchAllowance2 = useCallback(async () => {
        if (tokenAddress2 === '') {
            setAllowance2(ethers.constants.MaxUint256.toString(10))
            return;
        }
        const contract = new web3.eth.Contract(erc20ABI, tokenAddress2);

        const allowance2 = await contract.methods.allowance(address, spender).call()
        setAllowance2(allowance2)
    }, [address, spender, setAllowance2, tokenAddress2, web3])

    useEffect(() => {
        if (web3 && address) {
            fetchAllowance2()
        
        let refreshInterval = setInterval(fetchAllowance2, 10000)
        return () => clearInterval(refreshInterval)  }
    }, [web3, address, fetchAllowance2])

    return allowance2
}

export function useAllowance3(tokenAddress3, spender) {
    const { web3, address } = useConnectWallet();
    const [allowance3, setAllowance3] = useState("0");

    const fetchAllowance3 = useCallback(async () => {
        if (tokenAddress3 === '') {
            setAllowance3(ethers.constants.MaxUint256.toString(10))
            return;
        }
        const contract = new web3.eth.Contract(erc20ABI, tokenAddress3);

        const allowance3 = await contract.methods.allowance(address, spender).call()
        setAllowance3(allowance3)
    }, [address, spender, setAllowance3, tokenAddress3, web3])

    useEffect(() => {
        if (web3 && address) {
            fetchAllowance3()
        
        let refreshInterval = setInterval(fetchAllowance3, 10000)
        return () => clearInterval(refreshInterval)  }
    }, [web3, address, fetchAllowance3])

    return allowance3
}




export function useBalanceOf(tokenAddress) {
    const { web3, address } = useConnectWallet();
    const [balance, setBalance] = useState("0");

    const fetchBalance = useCallback(async () => {
        let balance;
        if (tokenAddress === '') {
            balance = await web3.eth.getBalance(address);
        } else {
            const contract = new web3.eth.Contract(erc20ABI, tokenAddress);

            balance = await contract.methods.balanceOf(address).call()
        }
        setBalance(balance)
    
    }, [address, setBalance, tokenAddress, web3])

    useEffect(() => {
        if (web3 && address) {
            fetchBalance()
        
        let refreshInterval = setInterval(fetchBalance, 10000)
        return () => clearInterval(refreshInterval) }
    }, [web3, address, fetchBalance])

    return balance
}


export function useMaticPrice(poolAddress) {
    const { web3, address } = useConnectWallet();
    const [MP, setMP] = useState("0");

    const fetchMP = useCallback(async () => {
        const contract = new web3.eth.Contract(nft1, poolAddress);

        const MP = await contract.methods.itemPriceForMatic().call({ from: address })
        setMP(MP) 
    }, [address, setMP, poolAddress, web3])
    
    useEffect(() => {
        if (web3 && address) {
            fetchMP()
        
        let refreshInterval = setInterval(fetchMP, 3000)
        return () => clearInterval(refreshInterval)  }
    }, [web3, address, fetchMP])
    return MP
}

export function useDmagicPrice(poolAddress) {
    const { web3, address } = useConnectWallet();
    const [DMP, setDMP] = useState("0");

    const fetchDMP = useCallback(async () => {
        const contract = new web3.eth.Contract(nft1, poolAddress);

        const DMP = await contract.methods.itemPriceForDMagic().call({ from: address })
        setDMP(DMP) 
    }, [address, setDMP, poolAddress, web3])
    
    useEffect(() => {
        if (web3 && address) {
            fetchDMP()
        
        let refreshInterval = setInterval(fetchDMP, 3000)
        return () => clearInterval(refreshInterval) }
    }, [web3, address, fetchDMP])

    return DMP
}

export function useTokenLeft(poolAddress) {
    const { web3, address } = useConnectWallet();
    const [TL, setTL] = useState("0");

    const fetchTL = useCallback(async () => {
        const contract = new web3.eth.Contract(nft1, poolAddress);

        const TL = await contract.methods.tokenRemainingToBeSold().call({ from: address })
        setTL(TL) 
    }, [address, setTL, poolAddress, web3])
    
    useEffect(() => {
        if (web3 && address) {
            fetchTL()
        
        let refreshInterval = setInterval(fetchTL, 10000000)
        return () => clearInterval(refreshInterval) }
    }, [web3, address, fetchTL])

    return TL
}

export function useTokenTotal(poolAddress) {
    const { web3, address } = useConnectWallet();
    const [TTS, setTTS] = useState("0");

    const fetchTTS = useCallback(async () => {
        const contract = new web3.eth.Contract(nft1, poolAddress);

        const TTS = await contract.methods.totalTokenToSell().call({ from: address })
        setTTS(TTS) 
    }, [address, setTTS, poolAddress, web3])
    
    useEffect(() => {
        if (web3 && address) {
            fetchTTS()
        
        let refreshInterval = setInterval(fetchTTS, 10000000)
        return () => clearInterval(refreshInterval) }
    }, [web3, address, fetchTTS])

    return TTS
}

export function useYouOwn(poolAddress) {
    const { web3, address } = useConnectWallet();
    const [YO, setYO] = useState("0");

    const fetchYO = useCallback(async () => {
        const contract = new web3.eth.Contract(nft1, poolAddress);

        const YO = await contract.methods.balanceOf(address).call({ from: address })
        setYO(YO) 
    }, [address, setYO, poolAddress, web3])
    
    useEffect(() => {
        if (web3 && address) {
            fetchYO()
        
        let refreshInterval = setInterval(fetchYO, 10000000)
        return () => clearInterval(refreshInterval) }
    }, [web3, address, fetchYO])

    return YO
}


export function useTotalSupply(poolAddress) {
    const { web3, address } = useConnectWallet();
    const [TS, setTS] = useState("0");

    const fetchTS = useCallback(async () => {
        const contract = new web3.eth.Contract(nft1, poolAddress);

        const TS = await contract.methods.totalSupply().call({ from: address })
        setTS(TS) 
    }, [address, setTS, poolAddress, web3])
    
    useEffect(() => {
        if (web3 && address) {
            fetchTS()
        
        let refreshInterval = setInterval(fetchTS, 10000000)
        return () => clearInterval(refreshInterval) }
    }, [web3, address, fetchTS])
    return TS
}


/* export function useTokenName(poolAddress) {
    const { web3, address } = useConnectWallet();
    const [TN, setTN] = useState("0");

    const fetchTN = useCallback(async () => {
        const contract = new web3.eth.Contract(nft1, poolAddress);

        const TN = await contract.methods.name().call({ from: address })
        setTN(TN) 
    }, [address, setTN, poolAddress, web3])
    
    useEffect(() => {
        if (web3 && address) {
            fetchTN()
        
        let refreshInterval = setInterval(fetchTN, 10000000)
        return () => clearInterval(refreshInterval) }
    }, [web3, address, fetchTN])

    return TN
} */

/* export function useTokenSymbol(poolAddress) {
    const { web3, address } = useConnectWallet();
    const [TOS, setTOS] = useState("0");

    const fetchTOS = useCallback(async () => {
        const contract = new web3.eth.Contract(nft1, poolAddress);

        const TOS = await contract.methods.symbol().call({ from: address })
        setTOS(TOS) 
    }, [address, setTOS, poolAddress, web3])
    
    useEffect(() => {
        if (web3 && address) {
            fetchTOS()
        
        let refreshInterval = setInterval(fetchTOS, 10000000)
        return () => clearInterval(refreshInterval) }
    }, [web3, address, fetchTOS])

    return TOS
} */




// nft1
export function useDeposit(poolAddress, tokenAddress) {
    const { web3, address } = useConnectWallet();
    const [isPending, setIsPending] = useState(false);
    const dispatch = useDispatch();


    const handleDeposit = useCallback(async (amount) => {
        setIsPending(true);
        try {
            await new Promise(async (resolve, reject) => {
                const contract = new web3.eth.Contract(nft1, poolAddress);
             contract.methods.purchaseTokenByDMagic( amount ).send({ from: address })
                .on('transactionHash', function(hash){
                    dispatch(enqueueSnackbar({
                        message: hash,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'success'
                        },
                        hash
                    }));
                }) 
                .on('receipt', function(receipt){
                    resolve()
                })
                .on('error', function(error) {
                    console.log(error)
                    reject(error)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
            });
            
        } finally {
            setIsPending(false);
        }
    }, [dispatch, setIsPending, web3, address, poolAddress, ]);

    return { isPending, onDeposit: handleDeposit };
}

export function useDeposit2(poolAddress, tokenAddress2) {
    const { web3, address } = useConnectWallet();
    const [isPending, setIsPending] = useState(false);
    const dispatch = useDispatch();


    const handleDeposit2 = useCallback(async (amount) => {
        setIsPending(true);
        try {
            await new Promise(async (resolve, reject) => {
                const contract = new web3.eth.Contract(nft1, poolAddress);
             contract.methods.purchaseTokenByAxMatic( amount ).send({ from: address})
            
                .on('transactionHash', function(hash){
                    dispatch(enqueueSnackbar({
                        message: hash,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'success'
                        },
                        hash
                    }));
                })
                .on('receipt', function(receipt){
                    resolve()
                })
                .on('error', function(error) {
                    console.log(error)
                    reject(error)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
            }); 
        } finally {
            setIsPending(false);
        }
    }, [dispatch, setIsPending, web3, address, poolAddress]);

    return { isPending, onDeposit2: handleDeposit2 };
}


export function useDeposit3(poolAddress, tokenAddress3) {
    const { web3, address } = useConnectWallet();
    const [isPending, setIsPending] = useState(false);
    const dispatch = useDispatch();


    const handleDeposit3 = useCallback(async (amount) => {
        setIsPending(true);
        try {
            await new Promise(async (resolve, reject) => {
                const contract = new web3.eth.Contract(nft1, poolAddress);
             contract.methods.purchaseTokenByMatic().send({ from: address, value: amount })
                .on('transactionHash', function(hash){
                    dispatch(enqueueSnackbar({
                        message: hash,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'success'
                        },
                        hash
                    }));
                })
                .on('receipt', function(receipt){
                    resolve()
                })
                .on('error', function(error) {
                    console.log(error)
                    reject(error)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
            });
        } finally {
            setIsPending(false);
        }
    }, [dispatch, setIsPending, web3, address, poolAddress]);

    return { isPending, onDeposit3: handleDeposit3 };
}