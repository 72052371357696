import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import GridItem from "components/Grid/GridItem.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import CustomButtons from "components/CustomButtons/Button.js";
import Avatar from "@material-ui/core/Avatar";
import {
  StyledTableCell,
  StyledTableRow,
  stakePoolsStyle,
} from "../jss/sections/stakePoolsStyle";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { useFetchPoolsInfo } from "../redux/hooks";
import magic_wand from "assets/img/magic-wand.svg";

const useStyles = makeStyles(stakePoolsStyle);

export default function StakePools(props) {
  const classes = useStyles();
  const { pools, fetchPoolsInfo } = useFetchPoolsInfo();

  useEffect(() => {
    fetchPoolsInfo();
  }, [fetchPoolsInfo]);

  return (
    <Grid container style={{ paddingTop: "4px" }}>
      <Hidden xsDown>
        <GridItem>
          <Table className={classes.table} aria-label="customized table">
            <TableBody
              classes={{
                root: classes.tableBodyRoot,
              }}
            >
              {pools.map((pool, index) => (
                <StyledTableRow
                  key={pool.name}
                  style={{
                    display: Boolean(index !== 0) ? "none" : "table-row",
                  }}
                >

                      <StyledTableCell className={classes.firstCellContent2}>
                        <CustomButtons
                          href={`/#/stake/boost/${index + 1}`}
                          className={
                            "btn btn_green btnzap c_black btn_wtgdnt_v2 boost_btn aaaaaaaaa"
                          }
                        >
                          <img
                            className="mr-2 fr_def"
                            width="25"
                            height="auto"
                            background-color="#000000"
                            src={magic_wand}
                            alt="logo"
                          />
                          DRAX Vault Boost - Ended!
                        </CustomButtons>
                        <br />
                  
                      </StyledTableCell>

                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </GridItem>
      </Hidden>

      <Hidden smUp>
        <GridItem>
          {pools.map((pool, index) => (
            <div
              key={`mobile-${index}`}
              className={classes.mobileContainer}
              style={{ display: Boolean(index !== 0) ? "none" : "" }}
            >
              <div
                className={classes.avatarContainer}
                style={{ width: "80px", height: "80px", borderRadius: "40px" }}
              >
                <Avatar
                  alt={pool.name}
                  src={require(`../../../images/${pool.name}-logo.png`)}
                  style={{ width: "54px", height: "54px" }}
                />
              </div>

              <CustomButtons
                href={`/#/stake/boost/${index + 1}`}
                className={classes.stakeButton}
                style={{ width: "180px", height: "44px" }}
              >
                Boost
              </CustomButtons>
              <br />
              <Box className="bst_btm_sp">
                <Typography compoent="p">Boost Pool Timelock: <span>5 Day</span></Typography>
                <Typography compoent="p">Boost Timelock Remaining: <span>00:00:00:00</span></Typography>
              </Box>
            </div>
          ))}
        </GridItem>
      </Hidden>
    </Grid>
  );
}

StakePools.defaultProps = {
  fromPage: "page",
};
