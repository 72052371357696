import React, { useEffect } from 'react';
import { Landing } from 'features/Landing';


export default function HomePage() {

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);
  
  return (
    <>
      <Landing />
    </>
  );
}