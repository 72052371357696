import { App } from '../features/home';
import { PageNotFound } from '../features/common';
import homeRoute from '../features/home/route';
//import vaultRoute from '../features/vault/route';
import stakeRoute from '../features/stake/route';
import stakingRoute from '../features/staking/route';
import stakingv2Route from '../features/stakingv2/route';
import commonRoute from '../features/common/route';
import zapRoute from '../features/swap/route';
import zapsuRoute from '../features/swapsu/route';
import nftRoute from '../features/nft/route';
import mtsRoute from '../features/mts/route';
//import checkRoute from '../features/check/route';
import _ from 'lodash';
import thecauldronRoute from '../features/thecauldron/route';

const childRoutes = [
  homeRoute,
  //vaultRoute,
  commonRoute,
  stakeRoute,
  stakingRoute,
  stakingv2Route,
  zapRoute,
  nftRoute,
  mtsRoute,
  zapsuRoute,
  thecauldronRoute,

  //checkRoute
];

const routes = [
  {
    path: '/',
    component: App,
    childRoutes: [
      ...childRoutes,
      { path: '*', name: 'Page not found', component: PageNotFound },
    ].filter((r) => r.component || (r.childRoutes && r.childRoutes.length > 0)),
  },
];

// Handle isIndex property of route config:
//  Dupicate it and put it as the first route rule.
function handleIndexRoute(route) {
  if (!route.childRoutes || !route.childRoutes.length) {
    return;
  }

  const indexRoute = _.find(route.childRoutes, (child) => child.isIndex);
  if (indexRoute) {
    const first = { ...indexRoute };
    first.path = '';
    first.exact = true;
    first.autoIndexRoute = true; // mark it so that the simple nav won't show it.
    route.childRoutes.unshift(first);
  }
  route.childRoutes.forEach(handleIndexRoute);
}

routes.forEach(handleIndexRoute);
export default routes;
