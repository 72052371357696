import React, { useEffect, useState } from 'react';
import appStyle from "./sections/appStyle"
import { makeStyles } from "@material-ui/core/styles";
import SectionPools from './sections/SectionPools';
import { Col } from 'reactstrap'
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(appStyle);

export default function TheCauldron() {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  return (
    <>
  <SectionPools />
    </>
  );
}