
export const stakingpoolsv2 = [


  { 
    chainId: 137,
    id: 'drax4',
    name: 'DRAX',
    token: 'DRAX',
    tokenDescription: 'DRAX',
    tokenDescription2: '',
    tokenAddress: '0x1Ba3510A9ceEb72E5CdBa8bcdDe9647E1f20fB4b',
    tokenAddress1: '0x20741A87AAFbFEABF29D42f04987Dc502f87270C',
    tokenAddress2: '0x5572cca754B34707B1eA6dABF2683B4F5AbCc1DD',
    tokenAddress3: '0x58BAF9A771F9C8f1814Ab00C06206908cDC5D20A',
    tokenAddress4: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',
    tokenDecimals: 18,
    earnedToken: 'DMAGIC',
    claimedTokenAddress: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',
    earnedTokenAddress: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',
    earnContractAddress: '0x3480d1F4BEec2afc042e2be325Bf34aFA00C3680',
    pricePerFullShare: 1,
    timelock: "20",
    penalty: "2%",
    classV: "1",
    duration: "90 days",
    startdate: "2022-02-19",
    boost1: "10%",
    boost2: "20%",
    boost3: "40%",
    multip: "2.0",
    status: 'aktive'
  },

  { 
    chainId: 137,
    id: 'nightbane3',
    name: 'NIGHTBANE',
    token: 'NIGHTBANE',
    tokenDescription: 'NIGHTBANE',
    tokenDescription2: '',
    tokenAddress: '0xEEf10C9Bf17c9d2C9619fd29447B231EA0Fde548',
    tokenAddress1: '0x20741A87AAFbFEABF29D42f04987Dc502f87270C',
    tokenAddress2: '0x5572cca754B34707B1eA6dABF2683B4F5AbCc1DD',
    tokenAddress3: '0x58BAF9A771F9C8f1814Ab00C06206908cDC5D20A',
    tokenAddress4: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',
    tokenDecimals: 18,
    earnedToken: 'DMAGIC',
    claimedTokenAddress: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',
    earnedTokenAddress: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',
    earnContractAddress: '0x46D2C936b721677B7CCFc4e0f8447163abd425B5',
    pricePerFullShare: 1,
    timelock: "20",
    penalty: "2%",
    classV: "1",
    duration: "90 days",
    startdate: "2022-02-19",
    boost1: "10%",
    boost2: "20%",
    boost3: "40%",
    multip: "2.0",
    status: 'aktive'

  },

  { 
    chainId: 137,
    id: 'dmagic',
    name: 'DMAGIC',
    token: 'DMAGIC',
    tokenDescription: 'DMAGIC',
    tokenDescription2: '',
    tokenAddress: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',
    tokenAddress1: '0x20741A87AAFbFEABF29D42f04987Dc502f87270C',
    tokenAddress2: '0x5572cca754B34707B1eA6dABF2683B4F5AbCc1DD',
    tokenAddress3: '0x58BAF9A771F9C8f1814Ab00C06206908cDC5D20A',
    tokenAddress4: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',
    tokenDecimals: 18,
    earnedToken: 'DMAGIC',
    claimedTokenAddress: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',
    earnedTokenAddress: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',
    earnContractAddress: '0x170DadA74C0897Db92e98dc59Cbe416A1CC4f1BD',
    pricePerFullShare: 1,
    timelock: "40",
    penalty: "2%",
    classV: "1",
    duration: "60 days",
    startdate: "2022-02-19",
    boost1: "10%",
    boost2: "20%",
    boost3: "40%",
    multip: "2.0",
    status: 'aktive'

  },

 /*  { 
    chainId: 137,
    id: 'drax3',
    name: 'DRAX',
    token: 'DRAX',
    tokenDescription: 'DRAX',
    tokenDescription2: '',
    tokenAddress: '0x1Ba3510A9ceEb72E5CdBa8bcdDe9647E1f20fB4b',
    tokenAddress1: '0x20741A87AAFbFEABF29D42f04987Dc502f87270C',
    tokenAddress2: '0x5572cca754B34707B1eA6dABF2683B4F5AbCc1DD',
    tokenAddress3: '0x58BAF9A771F9C8f1814Ab00C06206908cDC5D20A',
    tokenAddress4: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',
    tokenDecimals: 18,
    earnedToken: 'DMAGIC',
    claimedTokenAddress: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',
    earnedTokenAddress: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',
    earnContractAddress: '0xC37AEC6deeC8FBaF5c64da834b1C2042357C512D',
    pricePerFullShare: 1,
    timelock: "20",
    penalty: "10%",
    classV: "1",
    duration: "60 days",
    startdate: "2021-09-20",
    boost1: "10%",
    boost2: "20%",
    boost3: "40%",
    multip: "2.0",
    status: 'inaktive'

  },

  { 
    chainId: 137,
    id: 'nightbane3',
    name: 'NIGHTBANE',
    token: 'NIGHTBANE',
    tokenDescription: 'NIGHTBANE',
    tokenDescription2: '',
    tokenAddress: '0xEEf10C9Bf17c9d2C9619fd29447B231EA0Fde548',
    tokenAddress1: '0x20741A87AAFbFEABF29D42f04987Dc502f87270C',
    tokenAddress2: '0x5572cca754B34707B1eA6dABF2683B4F5AbCc1DD',
    tokenAddress3: '0x58BAF9A771F9C8f1814Ab00C06206908cDC5D20A',
    tokenAddress4: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',
    tokenDecimals: 18,
    earnedToken: 'DMAGIC',
    claimedTokenAddress: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',
    earnedTokenAddress: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',
    earnContractAddress: '0xee31633005D6dcE1789f0220d83c9a84b0203201',
    pricePerFullShare: 1,
    timelock: "20",
    penalty: "10%",
    classV: "1",
    duration: "90 days",
    startdate: "2021-09-28",
    boost1: "10%",
    boost2: "20%",
    boost3: "40%",
    multip: "2.0",
    status: 'inaktive'

  },


  { 
    chainId: 137,
    id: 'drax2',
    name: 'DRAX',
    token: 'DRAX',
    tokenDescription: 'DRAX',
    tokenDescription2: 'Burst',
    tokenAddress: '0x1Ba3510A9ceEb72E5CdBa8bcdDe9647E1f20fB4b',
    tokenAddress1: '0x20741A87AAFbFEABF29D42f04987Dc502f87270C',
    tokenAddress2: '0x5572cca754B34707B1eA6dABF2683B4F5AbCc1DD',
    tokenAddress3: '0x58BAF9A771F9C8f1814Ab00C06206908cDC5D20A',
    tokenAddress4: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',
    tokenDecimals: 18,
    earnedToken: 'DMAGIC',
    claimedTokenAddress: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',
    earnedTokenAddress: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',
    earnContractAddress: '0xD768a14B557B91137B063db28C7F608062268C15',
    pricePerFullShare: 1,
    timelock: "20",
    penalty: "10%",
    classV: "1",
    duration: "30 days",
    startdate: "2021-09-10",
    boost1: "10%",
    boost2: "20%",
    boost3: "40%",
    multip: "2.0",
    status: 'inaktive'

  },

  { 
    chainId: 137,
    id: 'dmagic',
    name: 'DMAGIC',
    token: 'DMAGIC',
    tokenDescription: 'DMAGIC',
    tokenDescription2: '',
    tokenAddress: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',
    tokenAddress1: '0x20741A87AAFbFEABF29D42f04987Dc502f87270C',
    tokenAddress2: '0x5572cca754B34707B1eA6dABF2683B4F5AbCc1DD',
    tokenAddress3: '0x58BAF9A771F9C8f1814Ab00C06206908cDC5D20A',
    tokenAddress4: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',
    tokenDecimals: 18,
    earnedToken: 'DMAGIC',
    claimedTokenAddress: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',
    earnedTokenAddress: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',
    earnContractAddress: '0x2006EcAf614fEA0dFaB767C83E85625DB36DAb56',
    pricePerFullShare: 1,
    timelock: "40",
    penalty: "10%",
    classV: "1",
    duration: "90 days",
    startdate: "2021-07-21",
    boost1: "10%",
    boost2: "20%",
    boost3: "40%",
    multip: "2.0",
    status: 'inaktive'

  },


  { 
    chainId: 137,
    id: 'nightbane2',
    name: 'NIGHTBANE',
    token: 'NIGHTBANE',
    tokenDescription: 'NIGHTBANE',
    tokenDescription2: 'Burst -ENDED-',
    tokenAddress: '0xEEf10C9Bf17c9d2C9619fd29447B231EA0Fde548',
    tokenAddress1: '0x20741A87AAFbFEABF29D42f04987Dc502f87270C',
    tokenAddress2: '0x5572cca754B34707B1eA6dABF2683B4F5AbCc1DD',
    tokenAddress3: '0x58BAF9A771F9C8f1814Ab00C06206908cDC5D20A',
    tokenAddress4: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',
    tokenDecimals: 18,
    earnedToken: 'DMAGIC',
    claimedTokenAddress: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',
    earnedTokenAddress: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',
    earnContractAddress: '0x1840230E92380837d627bb05A22e163C5f6dB579',
    pricePerFullShare: 1,
    timelock: "0",
    penalty: "0%",
    classV: "2",
    duration: "7 days",
    startdate: "2021-07-23",
    boost1: "20%",
    boost2: "50%",
    boost3: "100%",
    multip: "3.0",
    status: 'inaktive'

  },

  { 
    chainId: 137,
    id: 'nightbane',
    name: 'NIGHTBANE',
    token: 'NIGHTBANE',
    tokenDescription: 'NIGHTBANE',
    tokenDescription2: '',
    tokenAddress: '0xEEf10C9Bf17c9d2C9619fd29447B231EA0Fde548',
    tokenAddress1: '0x20741A87AAFbFEABF29D42f04987Dc502f87270C',
    tokenAddress2: '0x5572cca754B34707B1eA6dABF2683B4F5AbCc1DD',
    tokenAddress3: '0x58BAF9A771F9C8f1814Ab00C06206908cDC5D20A',
    tokenAddress4: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',
    tokenDecimals: 18,
    earnedToken: 'DMAGIC',
    claimedTokenAddress: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',
    earnedTokenAddress: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',
    earnContractAddress: '0xD69Ce95CD1354B8Aa14ebd165d8A7E8CADB6F34d',
    pricePerFullShare: 1,
    timelock: "20",
    penalty: "5%",
    classV: "1",
    duration: "60 days",
    startdate: "2021-07-21",
    boost1: "10%",
    boost2: "20%",
    boost3: "40%",
    multip: "2.0",
    status: 'inaktive'

  },
  { 
    chainId: 137,
    id: 'drax',
    name: 'DRAX',
    token: 'DRAX',
    tokenDescription: 'DRAX',
    tokenDescription2: '',
    tokenAddress: '0x1Ba3510A9ceEb72E5CdBa8bcdDe9647E1f20fB4b',
    tokenAddress1: '0x20741A87AAFbFEABF29D42f04987Dc502f87270C',
    tokenAddress2: '0x5572cca754B34707B1eA6dABF2683B4F5AbCc1DD',
    tokenAddress3: '0x58BAF9A771F9C8f1814Ab00C06206908cDC5D20A',
    tokenAddress4: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',
    tokenDecimals: 18,
    earnedToken: 'DMAGIC',
    claimedTokenAddress: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',
    earnedTokenAddress: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',
    earnContractAddress: '0x2f1346C3e9967671d11e60BAdbCB806578A26885',
    pricePerFullShare: 1,
    timelock: "20",
    penalty: "5%",
    classV: "1",
    duration: "60 days",
    startdate: "2021-07-21",
    boost1: "10%",
    boost2: "20%",
    boost3: "40%",
    multip: "2.0",
    status: 'inaktive'

  }, */


  
]
