
export const nftpools = [
  { 
    chainId: 137,
    id: 'nft1',
    tokenAddress: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',   // DMAGIC   
    tokenAddress2: '0x1221591c1d77a9c334abb0fe530ae6ee3af51af9',  // AXMATIC
    tokenAddress3: '',  // MATIC
    earnContractAddress: '0x20741A87AAFbFEABF29D42f04987Dc502f87270C',
    class1: "an UNCOMMON",
    class2: "MODERATELY VALUABLE",
    name: "Boneskin Amethyst",
    name2: "BOAM",
    status: "sold",
    link: "https://opensea.io/collection/boneskin-amethyst"
  },

  { 
    chainId: 137,
    id: 'nft3',
    tokenAddress: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',   // DMAGIC   
    tokenAddress2: '0x1221591c1d77a9c334abb0fe530ae6ee3af51af9',  // AXMATIC
    tokenAddress3: '',  // MATIC
    earnContractAddress: '0x5572cca754B34707B1eA6dABF2683B4F5AbCc1DD',
    class1: "a RARE",
    class2: "VALUABLE",
    name: "Boneskin Sapphire",
    name2: "BOSA",
    status: "sold",
    link: "https://opensea.io/collection/boneskin-sapphire"


  },

  { 
    chainId: 137,
    id: 'nft2',
    tokenAddress: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',   // DMAGIC   
    tokenAddress2: '0x1221591c1d77a9c334abb0fe530ae6ee3af51af9',  // AXMATIC
    tokenAddress3: '',  // MATIC
    earnContractAddress: '0x58BAF9A771F9C8f1814Ab00C06206908cDC5D20A',
    class1: "a RARE",
    class2: "VERY VALUABLE",
    name: "Boneskin Emerald",
    name2: "BOEM",
    status: "sold",
    link: "https://opensea.io/collection/boneskin-emerald"

  },

  

]
