export const STAKE_FETCH_POOLS_INFO_BEGIN = 'STAKE_FETCH_POOLS_INFO_BEGIN';
export const STAKE_FETCH_POOLS_INFO_SUCCESS = 'STAKE_FETCH_POOLS_INFO_SUCCESS';
export const STAKE_FETCH_POOLS_INFO_FAILURE = 'STAKE_FETCH_POOLS_INFO_FAILURE';

export const STAKE_CHECK_APPROVAL_BEGIN = 'STAKE_CHECK_APPROVAL_BEGIN';
export const STAKE_CHECK_APPROVAL_SUCCESS = 'STAKE_CHECK_APPROVAL_SUCCESS';
export const STAKE_CHECK_APPROVAL_FAILURE = 'STAKE_CHECK_APPROVAL_FAILURE';

export const STAKE_FETCH_BALANCE_BEGIN = 'STAKE_FETCH_BALANCE_BEGIN';
export const STAKE_FETCH_BALANCE_SUCCESS = 'STAKE_FETCH_BALANCE_SUCCESS';
export const STAKE_FETCH_BALANCE_FAILURE = 'STAKE_FETCH_BALANCE_FAILURE';

export const STAKE_FETCH_CURRENTLY_STAKED_BEGIN = 'STAKE_FETCH_CURRENTLY_STAKED_BEGIN';
export const STAKE_FETCH_CURRENTLY_STAKED_SUCCESS = 'STAKE_FETCH_CURRENTLY_STAKED_SUCCESS';
export const STAKE_FETCH_CURRENTLY_STAKED_FAILURE = 'STAKE_FETCH_CURRENTLY_STAKED_FAILURE';

export const STAKE_FETCH_CAN_WITHDRAW2_TIME_BEGIN = 'STAKE_FETCH_CAN_WITHDRAW2_TIME_BEGIN';
export const STAKE_FETCH_CAN_WITHDRAW2_TIME_SUCCESS = 'STAKE_FETCH_CAN_WITHDRAW2_TIME_SUCCESS';
export const STAKE_FETCH_CAN_WITHDRAW2_TIME_FAILURE = 'STAKE_FETCH_CAN_WITHDRAW2_TIME_FAILURE';

export const STAKE_FETCH_CAN_WITHDRAW_TIME_BEGIN = 'STAKE_FETCH_CAN_WITHDRAW_TIME_BEGIN';
export const STAKE_FETCH_CAN_WITHDRAW_TIME_SUCCESS = 'STAKE_FETCH_CAN_WITHDRAW_TIME_SUCCESS';
export const STAKE_FETCH_CAN_WITHDRAW_TIME_FAILURE = 'STAKE_FETCH_CAN_WITHDRAW_TIME_FAILURE';

export const STAKE_FETCH_APPROVAL_BEGIN = 'STAKE_FETCH_APPROVAL_BEGIN';
export const STAKE_FETCH_APPROVAL_SUCCESS = 'STAKE_FETCH_APPROVAL_SUCCESS';
export const STAKE_FETCH_APPROVAL_FAILURE = 'STAKE_FETCH_APPROVAL_FAILURE';

export const STAKE_FETCH_STAKE_BEGIN = 'STAKE_FETCH_STAKE_BEGIN';
export const STAKE_FETCH_STAKE_SUCCESS = 'STAKE_FETCH_STAKE_SUCCESS';
export const STAKE_FETCH_STAKE_FAILURE = 'STAKE_FETCH_STAKE_FAILURE';

export const STAKE_FETCH_STAKE2_BEGIN = 'STAKE_FETCH_STAKE2_BEGIN';
export const STAKE_FETCH_STAKE2_SUCCESS = 'STAKE_FETCH_STAKE2_SUCCESS';
export const STAKE_FETCH_STAKE2_FAILURE = 'STAKE_FETCH_STAKE2_FAILURE';

export const STAKE_FETCH_WITHDRAW2_BEGIN = 'STAKE_FETCH_WITHDRAW2_BEGIN';
export const STAKE_FETCH_WITHDRAW2_SUCCESS = 'STAKE_FETCH_WITHDRAW2_SUCCESS';
export const STAKE_FETCH_WITHDRAW2_FAILURE = 'STAKE_FETCH_WITHDRAW2_FAILURE';

export const STAKE_FETCH_WITHDRAW_BEGIN = 'STAKE_FETCH_WITHDRAW_BEGIN';
export const STAKE_FETCH_WITHDRAW_SUCCESS = 'STAKE_FETCH_WITHDRAW_SUCCESS';
export const STAKE_FETCH_WITHDRAW_FAILURE = 'STAKE_FETCH_WITHDRAW_FAILURE';
