import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import BigNumber from "bignumber.js";
import { byDecimals } from "features/helpers/bignumber";
import {
  useEarned,
  useBalanceOf,
  useDmagicAPY,
  useMultiplyingFactor,
} from "../redux/hooks";

const forMat = (number) => {
  return new BigNumber(number)
    .multipliedBy(new BigNumber(100000))
    .dividedToIntegerBy(new BigNumber(1))
    .dividedBy(new BigNumber(100000))
    .toNumber();
};

const forMatApy = (number) => {
  return new BigNumber(number)
    .multipliedBy(new BigNumber(100))
    .dividedToIntegerBy(new BigNumber(1))
    .dividedBy(new BigNumber(100))
    .toNumber();
};


  
export default function PoolHeader({ pool, classes }) {

    const tokenBalance = useBalanceOf(pool.tokenAddress);
    let balanceSingle = byDecimals(tokenBalance, pool.tokenDecimals);
    const depositedBalance = useBalanceOf(pool.earnContractAddress);
    let singleDepositedBalance = byDecimals(depositedBalance, pool.itokenDecimals);
    const earned = useEarned(pool.earnContractAddress);
    const formattedEarned = byDecimals(earned);
    const dmagicAPY = useDmagicAPY(pool.earnContractAddress);
    const Multi = useMultiplyingFactor(pool.earnContractAddress);

    return (
      <Grid
      container
      direction="row"
      alignItems="center"
      className="head_prnt"
    >                
 <Grid item xs={12} sm={1} >
          <Grid container alignItems="center" spacing={2}>
            <div className="d-flex fr_mbl_rsp">
                <div className="stk_frs_img">
                  <img
                      alt={pool.token}
                      src={require(`../../../images/${pool.token}-logo.png`)}
                      className="mr-3"
                  />
                </div>
              </div>
          </Grid>
      </Grid>
      <Grid item sm={2} xs={12} >
        <Typography
            className={classes.iconContainerMainTitle}
            variant="body2"
            gutterBottom
            noWrap
          >
            {pool.tokenDescription}
          </Typography>
          <Typography
            className={classes.iconContainerSubTitle}
            variant="body2"
            gutterBottom
            noWrap
          >
            {" "}
            {pool.tokenDescription2}{" "}
          </Typography>
      </Grid>
      <Grid item sm={2} xs={12} >
        <Typography
            className={classes.iconContainerMainTitle}
            variant="body2"
            gutterBottom
            noWrap
          >
            {forMat(balanceSingle)}
          </Typography>
          <Typography
            className={classes.iconContainerSubTitle}
            variant="body2"
            gutterBottom
            noWrap
          >
            {" "}
            {pool.token}{" "}
          </Typography>
      </Grid>
      <Grid item sm={2} xs={12} >
        <Typography
            className={classes.iconContainerMainTitle}
            variant="body2"
            gutterBottom
            noWrap
          >
            {forMat(singleDepositedBalance)}{" "}
          </Typography>
          <Typography
            className={classes.iconContainerSubTitle}
            variant="body2"
            gutterBottom
            noWrap
          >
            {" "}
            {pool.token}{" "}
          </Typography>
      </Grid>
      <Grid item sm={3} xs={12} >
        <Typography
          className={classes.iconContainerMainTitle}
          variant="body2"
          gutterBottom
          noWrap
        >
          {forMat(formattedEarned)} {pool.earnedToken} {" "}
        </Typography>
        <Typography
          className={classes.iconContainerSubTitle}
          variant="body2"
          gutterBottom
          noWrap
        >
          {" "}
          {forMatApy(byDecimals(dmagicAPY, 6).toNumber()) } % APR
        </Typography>
      </Grid>
      <Grid item sm={2} xs={12}>
        <Typography
            className={classes.iconContainerMainTitle}
            variant="body2"
            gutterBottom
            noWrap
          >
            X {byDecimals(Multi, 6).toNumber()}
          </Typography>
      </Grid>
      {/* <Grid item md={10} xs={3}>
        <Grid item container justify="space-between">
          <Hidden smDown>
            <Grid item xs={2} container justify="center" alignItems="center">
              <Grid item style={{ width: "200px", textAlign: "center" }}>
                
              </Grid>
            </Grid>
          </Hidden>
          <Hidden mdDown>
            <Grid item xs={3} container justify="center" alignItems="center">
              <Grid item style={{ width: "200px", textAlign: "center" }}>
                
              </Grid>
            </Grid>
          </Hidden>
          <Grid item xs={3} container justify="center" alignItems="center">
            <Grid item style={{ width: "350px", textAlign: "center" }}>
              
            </Grid>

            <Grid item style={{ textAlign: "center" }}>
                            <Typography className={classes.iconContainerMainTitle} variant="body2" gutterBottom noWrap>{forMat(formattedEarned2)} { pool.earnedToken2 }</Typography>
                            <Typography className={classes.iconContainerSubTitle} variant="body2" gutterBottom noWrap>{byDecimals(dmagicAPY,6).toNumber()} % APY</Typography>
                            
                        </Grid>  
          </Grid>
          <Grid item xs={3} container justify="center" alignItems="center">
            <Grid item style={{ width: "200px", textAlign: "center" }}>
              
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}
    </Grid>
  );
}
