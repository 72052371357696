import React, { useState } from "react";
import BigNumber from "bignumber.js";
import { byDecimals } from "features/helpers/bignumber";
import {
  Grid,
  Box,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";

import {
  useAllowance,
  useApprove,
  useDeposit,
  useRatio,
  useMAX,
  useBalanceOf,
} from "../redux/hooks";

export default function PoolContent({ index, ezioupool }) {
  const [amount, setAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const allowance = useAllowance(
    ezioupool.tokenAddress,
    ezioupool.earnContractAddress
  );
  const { isPending: isApprovePending, onApprove } = useApprove(
    ezioupool.tokenAddress,
    ezioupool.earnContractAddress
  );

  const [error, setError] = useState("");

  const buy = async (e) => {
    e.preventDefault();
    setError("");
    if (amount === 0) {
      setError("Please enter a valid amount");
      return;
    }
    try {
      setIsLoading(true);

      setIsLoading(false);
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  };

  const { onDeposit, isPending: isDepositPending } = useDeposit(
    ezioupool.earnContractAddress,
    ezioupool.tokenAddress
  );

  const tokenBalance = useBalanceOf(ezioupool.tokenAddress);
  let balanceSingle = byDecimals(tokenBalance, ezioupool.tokenDecimals);

  const RATIO = useRatio(ezioupool.earnContractAddress);
  const MAX = useMAX(ezioupool.earnContractAddress);

  //const onOUT = useOutput(ezioupool.earnContractAddress)

  const forMat = (number) => {
    return new BigNumber(number)
      .multipliedBy(new BigNumber(10000))
      .dividedToIntegerBy(new BigNumber(1))
      .dividedBy(new BigNumber(10000))
      .toNumber();
  };

  return (
    <>
      <Grid item xs={12} sm={6} lg={4}>
        <Box className="ex_cmn_bx ex_cmn_bx_wtbrdr">
          <Box className="ex_cmn_pddng ex_cmn_brdr_b pb-2">
            <Box
              component="img"
              src={require(`../../../images/${ezioupool.image}.png`)}
              alt=""
              className="img-fluid-exiou"
              width="100px"
            />
                     <Box
              component="img"
              src={require(`../../../images/arrow.png`)}
              alt=""
              className="img-fluid-exiou"
              width="50px"
            />
          <Box
              component="img"
              src={require(`../../../images/${ezioupool.image2}.png`)}
              alt=""
              className="img-fluid-exiou"
              width="100px"
            />
            <Box className="ex_shdwbx ex_shdwbx_inline">
              <Typography component="h4">{ezioupool.title}</Typography>
            </Box>
          </Box>
          <Box className="ex_cmn_pddng">
            <Typography component="h4">
              Ratio: {RATIO[1]} : {RATIO[0]}
            </Typography>
            <Typography component="h4">Max Swap Amount: {MAX==0?0:forMat(byDecimals(MAX, ezioupool.returnTokenDecimal))}</Typography>
            <br></br>
            <Typography component="h4">
              Wallet Balance: {forMat(balanceSingle)} {ezioupool.token}
            </Typography>

            <form className="saleForm">
              <Box className="ex_cmn_pddng">
                <input
                  className="input_fld"
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value<0?0:e.target.value)}
                  required
                />
                 <Typography component="h4">
                    Receive: {forMat(amount * RATIO[0] / RATIO[1])} {ezioupool.recieve==null ? "DMAGIC":"MBAT"}
                  </Typography>
          
              </Box>
              <Box className="ex_cmn_pddng">
                <div className="progress_action mt-3">

                {allowance === "0" ? (
                  <Button
                    type="submit"
                    className="ex_acntbtn"
                    onClick={(event) => {
                      onApprove();
                    }}
                  >
                    {isLoading ? "Approving..." : "Approve"}
                    {isLoading && (
                      <CircularProgress
                        style={{ margin: "5px 0 0 10px" }}
                        size={24}
                      />
                    )}
                  </Button>

                ) : ( 
                  <Button
                    type="submit"
                    disabled={isDepositPending || amount >= forMat(balanceSingle) || amount >= MAX  || amount === 0}
                    className="ex_acntbtn"
                    onClick={(event) => {
                      onDeposit(
                        new BigNumber(amount)
                          .multipliedBy(
                            new BigNumber(10).exponentiatedBy(
                              ezioupool.tokenDecimals
                            )
                          )
                          .toString(10)
                      );
                      console.log(new BigNumber(amount));
                    }}
                  >
                    {isLoading ? "Verifying..." : "Submit"}
                    {isLoading && (
                      <CircularProgress
                        style={{ margin: "5px 0 0 10px" }}
                        size={24}
                      />
                    )}
                  </Button>
                )
                }
                </div>
              </Box>
            </form>
          </Box>
          {/*           <Box className="ex_cmn_pddng">
            {allowance === "0" ? (
              <div className="progress_action mt-3">
                <button
                  className="ex_acntbtn"
                  onClick={() => {
                    handleClose2();
                    onApprove();
                  }}
                  disabled={isApprovePending[index]}
                >
                  {isApprovePending
                    ? `${"Approving..."}`
                    : `${"Approve for EZIOU"}`}
                </button>
              </div>
            ) : (
              <div className="progress_action  mt-3">
                <button
                  className="ex_acntbtn"
                  onFocus={(event) => event.stopPropagation()}
                  disabled={isDepositPending}
                  onClick={(event) => {
                    handleClickOpen2();
                  }}
                >
                  {" "}
                  {"Deposit EZIOU"}
                </button>
              </div>
            )}
          </Box> */}
        </Box>
      </Grid>
    </>
  );
}
