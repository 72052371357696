import React, { useEffect } from 'react';
import appStyle from "./sections/appStyle"
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box } from '@material-ui/core';
import { Col, Row } from 'reactstrap';
import classnames from 'classnames';
import EziouScOne from './sections/EziouScOne';

const useStyles = makeStyles(appStyle);

export default function ExiouPage() {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  return (
    <>
      <Box className="nft_bg">
        <Box className={classes.container}>

        <Grid
            container
            style={{ paddingTop: '4px'}}
            id="container"
          >
            <div className="space-100 d-nn" />
            <Col xs={12} className="text-right mt-5">
              <label className="stk_lbl">
                {' '}
                Network Details:<span className="g_dot"></span>
                <span>Polygon</span>{' '}
                <a href="https://bsc.defimagic.io/#">
                  <span className="r_dot"></span>
                  <span>BSC</span>
                </a>
              </label>
            </Col>
          </Grid>

      
          <Row className="mt-5 justify-content-center">
              <Col md={12} className="text-center mt-2">
                  <h3 className="cldrn_txt exou_txt mb-0">
                    <Box component="img" src="/static/img/magic_ic.png" alt="" />
                    Greed
                  </h3>
              </Col>
              <Col xl={10} className="mt-4">
                  <EziouScOne />
              </Col>

          </Row>
        </Box>
        
      </Box>
      
    </>
  );
}
