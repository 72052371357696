import { erc20ABI, stakingv2, nft1 } from 'features/configure/abi';
import { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../common/redux/actions';
import { ethers, } from 'ethers';
import { useConnectWallet } from '../../home/redux/hooks'; 
export { useFetchPoolBalances } from './fetchPoolBalances';


export function useApprove(tokenAddress, poolAddress) {
    const { web3, address } = useConnectWallet();
    const [isPending, setIsPending] = useState(false);
    const dispatch = useDispatch();


    const handleApprove = useCallback(async () => {
        setIsPending(true);
        try {
            await new Promise((resolve, reject) => {
                const contract = new web3.eth.Contract(erc20ABI, tokenAddress);

                contract.methods.approve(poolAddress, ethers.constants.MaxUint256.toString(10)).send({ from: address })
                .on('transactionHash', function(hash){
                    dispatch(enqueueSnackbar({
                        message: hash,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'success'
                        },
                        hash
                    }));
                })
                .on('receipt', function(receipt){
                    resolve()
                })
                .on('error', function(error) {
                    console.log(error)
                    reject(error)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
            });
        } finally {
            setIsPending(false);
                   }
    }, [dispatch, setIsPending, web3, address, poolAddress, tokenAddress]);

    return { isPending, onApprove: handleApprove };
  }

  export function useApprove1(tokenAddress1, poolAddress) {
    const { web3, address } = useConnectWallet();
    const [isPending, setIsPending] = useState(false);
    const dispatch = useDispatch();

    const handleApprove1 = useCallback(async (owner11) => {
        setIsPending(true);
        try {
            await new Promise((resolve, reject) => {
                const contract = new web3.eth.Contract(nft1, tokenAddress1);
                contract.methods.approve(poolAddress, owner11).send({ from: address })
                .on('transactionHash', function(hash){
                    dispatch(enqueueSnackbar({
                        message: hash,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'success'
                        },
                        hash
                    }));
                })
                .on('receipt', function(receipt){
                    resolve()
                })
                .on('error', function(error) {
                    console.log(error)
                    reject(error)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
            });
        } finally {
            setIsPending(false);
        }
    }, [dispatch, setIsPending, web3, address, poolAddress, tokenAddress1]);

    return { isPending, onApprove1: handleApprove1 };
  }

  export function useApprove2(tokenAddress2, poolAddress) {
    const { web3, address } = useConnectWallet();
    const [isPending, setIsPending] = useState(false);
    const dispatch = useDispatch();


    const handleApprove2 = useCallback(async (owner22) => {
        setIsPending(true);
        try {
            await new Promise((resolve, reject) => {
                const contract = new web3.eth.Contract(nft1, tokenAddress2);

                contract.methods.approve(poolAddress,  owner22).send({ from: address })
                .on('transactionHash', function(hash){
                    dispatch(enqueueSnackbar({
                        message: hash,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'success'
                        },
                        hash
                    }));
                })
                .on('receipt', function(receipt){
                    resolve()
                })
                .on('error', function(error) {
                    console.log(error)
                    reject(error)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
            });
        } finally {
            setIsPending(false);
        }
    }, [dispatch, setIsPending, web3, address, poolAddress, tokenAddress2]);

    return { isPending, onApprove2: handleApprove2 };
  }

  export function useApprove3(tokenAddress3, poolAddress) {
    const { web3, address } = useConnectWallet();
    const [isPending, setIsPending] = useState(false);
    const dispatch = useDispatch();


    const handleApprove3 = useCallback(async (owner33) => {
        setIsPending(true);
        try {
            await new Promise((resolve, reject) => {
                const contract = new web3.eth.Contract(nft1, tokenAddress3);

                contract.methods.approve(poolAddress, owner33).send({ from: address })
                .on('transactionHash', function(hash){
                    dispatch(enqueueSnackbar({
                        message: hash,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'success'
                        },
                        hash
                    }));
                })
                .on('receipt', function(receipt){
                    resolve()
                })
                .on('error', function(error) {
                    console.log(error)
                    reject(error)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
            });
        } finally {
            setIsPending(false);
        }
    }, [dispatch, setIsPending, web3, address, poolAddress, tokenAddress3]);

    return { isPending, onApprove3: handleApprove3 };
  }

  export function useApprove4(tokenAddress4, poolAddress) {
    const { web3, address } = useConnectWallet();
    const [isPending, setIsPending] = useState(false);
    const dispatch = useDispatch();


    const handleApprove4 = useCallback(async () => {
        setIsPending(true);
        try {
            await new Promise((resolve, reject) => {
                const contract = new web3.eth.Contract(erc20ABI, tokenAddress4);

                contract.methods.approve(poolAddress, ethers.constants.MaxUint256.toString(10)).send({ from: address })
                .on('transactionHash', function(hash){
                    dispatch(enqueueSnackbar({
                        message: hash,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'success'
                        },
                        hash
                    }));
                })
                .on('receipt', function(receipt){
                    resolve()
                })
                .on('error', function(error) {
                    console.log(error)
                    reject(error)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
            });
        } finally {
            setIsPending(false);
        }
    }, [dispatch, setIsPending, web3, address, poolAddress, tokenAddress4]);

    return { isPending, onApprove4: handleApprove4 };
  }

export function useAllowance(tokenAddress, spender) {
    const { web3, address } = useConnectWallet();
    const [allowance, setAllowance] = useState("0");

    const fetchAllowance = useCallback(async () => {
        if (tokenAddress === '') {
            setAllowance(ethers.constants.MaxUint256.toString(10))
            return;
        }
        const contract = new web3.eth.Contract(erc20ABI, tokenAddress);

        const allowance = await contract.methods.allowance(address, spender).call()
        setAllowance(allowance)
    }, [address, spender, setAllowance, tokenAddress, web3])

    useEffect(() => {
        if (web3 && address) {
            fetchAllowance()
        
        let refreshInterval = setInterval(fetchAllowance, 5000)
        return () => clearInterval(refreshInterval)  }
    }, [web3, address, fetchAllowance])

    return allowance
}


export function useBalanceOf(tokenAddress) {
    const { web3, address } = useConnectWallet();
    const [balance, setBalance] = useState("0");

    const fetchBalance = useCallback(async () => {
        let balance;
        if (tokenAddress === '') {
            balance = await web3.eth.getBalance(address);
        } else {
            const contract = new web3.eth.Contract(erc20ABI, tokenAddress);

            balance = await contract.methods.balanceOf(address).call()
        }
        setBalance(balance)
    
    }, [address, setBalance, tokenAddress, web3])

    useEffect(() => {
        if (web3 && address) {
            fetchBalance()
        
        let refreshInterval = setInterval(fetchBalance, 5000)
        return () => clearInterval(refreshInterval) }
    }, [web3, address, fetchBalance])

    return balance
}

// stakingv2
export function useEarned(poolAddress) {
    const { web3, address } = useConnectWallet();
    const [earned, setEarned] = useState("0");

    const fetchEarned = useCallback(async () => {
        const contract = new web3.eth.Contract(stakingv2, poolAddress);

        const earned = await contract.methods.totalEarnedRewardToken1( address ).call({ from: address })
        setEarned(earned) 
    }, [address, setEarned, poolAddress, web3])
    
    useEffect(() => {
        if (web3 && address) {
            fetchEarned()
        
        let refreshInterval = setInterval(fetchEarned, 5000)
        return () => clearInterval(refreshInterval)  }
    }, [web3, address, fetchEarned])

    return earned
}

export function useTime1(poolAddress) {
    const { web3, address } = useConnectWallet();
    const [time1, setTime1] = useState('');

    const fetchTime1 = useCallback(async () => {
        const contract = new web3.eth.Contract(stakingv2, poolAddress);

        const time1 = await contract.methods.lockingPeriodStaking( address ).call({ from: address })
        setTime1(time1) 
       
    }, [address, setTime1, poolAddress, web3])
    
    useEffect(() => {
        if (web3 && address) {
            fetchTime1()
 }
    }, [web3, address, fetchTime1])

       console.log()

       return time1
}

export function useDmagicAPY(poolAddress) {
    const { web3, address } = useConnectWallet();
    const [APY2, setAPY2] = useState("0");

    const fetchAPY2 = useCallback(async () => {
        const contract = new web3.eth.Contract(stakingv2, poolAddress);

        const APY2 = await contract.methods.getRewardToken1APY().call({ from: address })
        setAPY2(APY2) 
    }, [address, setAPY2, poolAddress, web3])
    
    useEffect(() => {
        if (web3 && address) {
            fetchAPY2()
        
 }
    }, [web3, address, fetchAPY2])

    return APY2
}

/* export function usePosBoost(poolAddress) {
    const { web3, address } = useConnectWallet();
    const [PB, setPB] = useState("0");

    const fetchPB = useCallback(async () => {
        const contract = new web3.eth.Contract(stakingv2, poolAddress);

        const PB = await contract.methods.getNFTBoostPossibleByAddress(address).call({ from: address })
        setPB(PB) 
    }, [address, setPB, poolAddress, web3])
    
    useEffect(() => {
        if (web3 && address) {
            fetchPB()
 }
    }, [web3, address, fetchPB])

    return PB
} */


export function useMultiplyingFactor(poolAddress) {
    const { web3, address } = useConnectWallet();
    const [Multi, setMulti] = useState("0");

    const fetchMulti = useCallback(async () => {
        const contract = new web3.eth.Contract(stakingv2, poolAddress);

        const Multi = await contract.methods.getMultiplyingFactor(address).call({ from: address })
        setMulti(Multi) 
    }, [address, setMulti, poolAddress, web3])
    
    useEffect(() => {
        if (web3 && address) {
            fetchMulti()
        
        let refreshInterval = setInterval(fetchMulti, 6000)
        return () => clearInterval(refreshInterval)}
    }, [web3, address, fetchMulti])

    return Multi
}

// stakingv2
export function useDeposit(poolAddress, tokenAddress) {
    const { web3, address } = useConnectWallet();
    const [isPending, setIsPending] = useState(false);
    const dispatch = useDispatch();


    const handleDeposit = useCallback(async (amount) => {
        setIsPending(true);
        try {
            await new Promise(async (resolve, reject) => {
                const contract = new web3.eth.Contract(stakingv2, poolAddress);
                 contract.methods.stake(amount).send({ from: address })
                .on('transactionHash', function(hash){
                    dispatch(enqueueSnackbar({
                        message: hash,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'success'
                        },
                        hash
                    }));
                })
                .on('receipt', function(receipt){
                    resolve()
                })
                .on('error', function(error) {
                    console.log(error)
                    reject(error)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
            });
        } finally {
            setIsPending(false);
        }
    }, [dispatch, setIsPending, web3, address, poolAddress ]);

    return { isPending, onDeposit: handleDeposit };
}

// stakingv2
export function useWithdraw(poolAddress) {
    const { web3, address } = useConnectWallet();
    const [isPending, setIsPending] = useState(false);
    const dispatch = useDispatch();


    const handleWithdraw = useCallback(async (amount) => {
        setIsPending(true);
        try {
            await new Promise(async (resolve, reject) => {
                const contract = new web3.eth.Contract(stakingv2, poolAddress);
                contract.methods.withdraw(amount).send({ from: address })
                .on('transactionHash', function(hash){
                    dispatch(enqueueSnackbar({
                        message: hash,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'success'
                        },
                        hash
                    }));
                })
                .on('receipt', function(receipt){
                    resolve()
                })
                .on('error', function(error) {
                    console.log(error)
                    reject(error)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
            });
        } finally {
            setIsPending(false);
        }
    }, [dispatch, setIsPending, web3, address, poolAddress]);

    return { isPending, onWithdraw: handleWithdraw };
}

export function useFetchGetReward(poolAddress) {
    const { web3, networkId, address } = useConnectWallet();
    const [isPending, setIsPending] = useState(false);
    const dispatch = useDispatch();


    const handleGetReward = useCallback(async () => {
        setIsPending(true);
        try {
            await new Promise(async (resolve, reject) => {
                const contract = new web3.eth.Contract(stakingv2, poolAddress);
                contract.methods.getReward().send({ from: address})
                .on('transactionHash', function(hash){
                    dispatch(enqueueSnackbar({
                        message: hash,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'success'
                        },
                        hash
                    }));
                })
                .on('receipt', function(receipt){
                    resolve()
                })
                .on('error', function(error) {
                    console.log(error)
                    reject(error)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
            });
        } finally {
            setIsPending(false);
        }
    }, [dispatch, setIsPending, web3, address, poolAddress, networkId]);

    return { isPending, onGetReward: handleGetReward };
}

export function useBoost1(poolAddress, tokenAddress) {
    const { web3, address } = useConnectWallet();
    const [isPending, setIsPending] = useState(false);
    const dispatch = useDispatch();

    const handleBoost1 = useCallback(async (owner11) => {
        setIsPending(true);
        try {
            await new Promise(async (resolve, reject) => {
                const contract = new web3.eth.Contract(stakingv2, poolAddress);
                 contract.methods.boostByNFT('0x20741A87AAFbFEABF29D42f04987Dc502f87270C', owner11).send({ from: address })
                .on('transactionHash', function(hash){
                    dispatch(enqueueSnackbar({
                        message: hash,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'success'
                        },
                        hash
                    }));
                })
                .on('receipt', function(receipt){
                    resolve()
                })
                .on('error', function(error) {
                    console.log(error)
                    reject(error)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
            });
        } finally {
            setIsPending(false);
        }
    }, [dispatch, setIsPending, web3, address, poolAddress ]);

    return { isPending, onBoost1: handleBoost1 };
}


export function useBoost2(poolAddress, tokenAddress) {
    const { web3, address } = useConnectWallet();
    const [isPending, setIsPending] = useState(false);
    const dispatch = useDispatch();

    const handleBoost2 = useCallback(async (owner22) => {
        setIsPending(true);
        try {
            await new Promise(async (resolve, reject) => {
                const contract = new web3.eth.Contract(stakingv2, poolAddress);
                 contract.methods.boostByNFT('0x5572cca754B34707B1eA6dABF2683B4F5AbCc1DD', owner22).send({ from: address }) 
                .on('transactionHash', function(hash){
                    dispatch(enqueueSnackbar({
                        message: hash,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'success'
                        },
                        hash
                    }));
                })
                .on('receipt', function(receipt){
                    resolve()
                })
                .on('error', function(error) {
                    console.log(error)
                    reject(error)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
            });
        } finally {
            setIsPending(false);
        }
    }, [dispatch, setIsPending, web3, address, poolAddress ]);

    return { isPending, onBoost2: handleBoost2 };
}

export function useBoost3(poolAddress, tokenAddress) {
    const { web3, address } = useConnectWallet();
    const [isPending, setIsPending] = useState(false);
    const dispatch = useDispatch();

const handleBoost3 = useCallback(async (owner33) => {
    setIsPending(true);
    try {
        await new Promise(async (resolve, reject) => {
            const contract = new web3.eth.Contract(stakingv2, poolAddress);
             contract.methods.boostByNFT('0x58BAF9A771F9C8f1814Ab00C06206908cDC5D20A', owner33).send({ from: address })
            .on('transactionHash', function(hash){
                dispatch(enqueueSnackbar({
                    message: hash,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success'
                    },
                    hash
                }));
            })
            .on('receipt', function(receipt){
                resolve()
            })
            .on('error', function(error) {
                console.log(error)
                reject(error)
            })
            .catch((error) => {
                console.log(error)
                reject(error)
            })
        });
    } finally {
        setIsPending(false);
    }
}, [dispatch, setIsPending, web3, address, poolAddress ]);

return { isPending, onBoost3: handleBoost3 };
}


export function useBoost4(poolAddress, tokenAddress) {
    const { web3, address } = useConnectWallet();
    const [isPending, setIsPending] = useState(false);
    const dispatch = useDispatch();

const handleBoost4 = useCallback(async (amount) => {
    setIsPending(true);
    try {
        await new Promise(async (resolve, reject) => {
            const contract = new web3.eth.Contract(stakingv2, poolAddress);
             contract.methods.boostByToken(amount).send({ from: address })
            .on('transactionHash', function(hash){
                dispatch(enqueueSnackbar({
                    message: hash,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success'
                    },
                    hash
                }));
            })
            .on('receipt', function(receipt){
                resolve()
            })
            .on('error', function(error) {
                console.log(error)
                reject(error)
            })
            .catch((error) => {
                console.log(error)
                reject(error)
            })
        });
    } finally {
        setIsPending(false);
    }
}, [dispatch, setIsPending, web3, address, poolAddress ]);

return { isPending, onBoost4: handleBoost4 };
}


export function useOwner(poolAddress) {
    const { web3, address } = useConnectWallet();
    const [Owner, setOwner] = useState("0");

    const fetchOwner = useCallback(async () => {
        const contract = new web3.eth.Contract(nft1, '0x20741A87AAFbFEABF29D42f04987Dc502f87270C');
        const owner = await contract.methods.tokenOfOwnerByIndex( address, 0 ).call({ from: address })
        setOwner(owner) 
    }, [address, setOwner, poolAddress, web3])
    
    useEffect(() => {
        if (web3 && address) {
            fetchOwner()
 }
    }, [web3, address, fetchOwner])
    return Owner
}

export function useOwner2(poolAddress) {
    const { web3, address } = useConnectWallet();
    const [Owner2, setOwner2] = useState("0");

    const fetchOwner2 = useCallback(async () => {
        const contract = new web3.eth.Contract(nft1, '0x5572cca754B34707B1eA6dABF2683B4F5AbCc1DD'); 

        const owner2 = await contract.methods.tokenOfOwnerByIndex( address, 0 ).call({ from: address })
        setOwner2(owner2) 
    }, [address, setOwner2, poolAddress, web3])
    
    useEffect(() => {
        if (web3 && address) {
            fetchOwner2()
        
}
    }, [web3, address, fetchOwner2])
    return Owner2
} 

export function useOwner3(poolAddress) {
    const { web3, address } = useConnectWallet();
    const [Owner3, setOwner3] = useState("0");

    const fetchOwner3 = useCallback(async () => {
        const contract = new web3.eth.Contract(nft1, '0x58BAF9A771F9C8f1814Ab00C06206908cDC5D20A');

        const owner3 = await contract.methods.tokenOfOwnerByIndex( address, 0 ).call({ from: address })
        setOwner3(owner3) 
    }, [address, setOwner3, poolAddress, web3])
    
    useEffect(() => {
        if (web3 && address) {
            fetchOwner3()
  }
    }, [web3, address, fetchOwner3])
    return Owner3
} 
