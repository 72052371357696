
export const thecauldronpools = [
  { 
    chainId: 56,
    id: 'stri',
    tokenAddress: '0x1Ba3510A9ceEb72E5CdBa8bcdDe9647E1f20fB4b',   // DMAGIC   
    tokenAddress2: '',  // AXMATIC
    tokenAddress3: '',  // MATIC
    earnContractAddress: '0xDB9241de40e2fCd6093e8AF54a9A57a4509a6766',
  },

  { 
    chainId: 56,
    id: 'dd',
    tokenAddress: '0x1Ba3510A9ceEb72E5CdBa8bcdDe9647E1f20fB4b',   // DMAGIC   
    tokenAddress2: '',  // AXMATIC
    tokenAddress3: '',  // MATIC
    earnContractAddress: '0xDB9241de40e2fCd6093e8AF54a9A57a4509a6766',
  },

  { 
    chainId: 56,
    id: 'mr',
    tokenAddress: '0x1Ba3510A9ceEb72E5CdBa8bcdDe9647E1f20fB4b',   // DMAGIC   
    tokenAddress2: '',  // AXMATIC
    tokenAddress3: '',  // MATIC
    earnContractAddress: '0xDB9241de40e2fCd6093e8AF54a9A57a4509a6766',
  },

  { 
    chainId: 56,
    id: 'pi',
    tokenAddress: '0x1Ba3510A9ceEb72E5CdBa8bcdDe9647E1f20fB4b',   // DMAGIC   
    tokenAddress2: '',  // AXMATIC
    tokenAddress3: '',  // MATIC
    earnContractAddress: '0xDB9241de40e2fCd6093e8AF54a9A57a4509a6766',
  },
  { 
    chainId: 56,
    id: 'ax',
    tokenAddress: '',   // DMAGIC   
    tokenAddress2: '',  // AXMATIC
    tokenAddress3: '',  // MATIC
    earnContractAddress: '0x8Ea816A97371f5F38F3Cd5b76e7D85656e69D112',
    earnContractAddress2: '0xe68e40eeEb0a86D9CC17a138Ff7DE88A7B19Dd9f',
    earnContractAddress3: '0x25EBB716d31fB7018Bc65861DCF8CE5dE692090D',
  },
]