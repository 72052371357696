import React, { useState, useEffect } from "react";
import BigNumber from "bignumber.js";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useConnectWallet } from "../../home/redux/hooks";
import {
  useFetchBalances,
  useCheckApproval,
  useFetchApproval,
  useFetchZapOrSwap,
  useFetchPricePerFullShare,
} from "../redux/hooks";
import GridItem from "components/Grid/GridItem.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import CustomButtons from "components/CustomButtons/Button.js";
import zapCommandStyle from "../jss/sections/zapCommandStyle";
import Avatar from "@material-ui/core/Avatar";
import { byDecimals, calculateReallyNum } from "features/helpers/bignumber";
import { inputLimitPass, inputFinalVal } from "features/helpers/utils";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Slider from "@material-ui/core/Slider";
import { Grid, Box } from "@material-ui/core";
import { client } from "../../../common/gqlClient";
import { gql } from "@apollo/client";

const useStyles = makeStyles(zapCommandStyle);

export default function ZapCommand({ handlePlay }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { web3, address } = useConnectWallet();
  const { tokens, fetchBalances } = useFetchBalances();
  const { allowance, checkApproval } = useCheckApproval();
  const { fetchApproval, fetchApprovalPending } = useFetchApproval();
  const { fetchZapOrSwap } = useFetchZapOrSwap();
  const [isApproval, setIsApproval] = useState(1);
  const [showIndex, setShowIndex] = useState(0);
  const [subInfo, setSubInfo] = useState({});
  const { fetchPricePerFullShare, pricePerFullShare } =
    useFetchPricePerFullShare();
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [drdmprice, setDrDmPrice] = useState(0);
  const [ dmdrprice, setDmDrPrice] = useState(0);

  useEffect(() => {
    if (address && web3) {
      fetchBalances();
      checkApproval()
      const id = setInterval(() => {
        fetchBalances();
       
      }, 7000);
      return () => clearInterval(id);
    }
  }, [address, web3, fetchBalances, checkApproval]);

  useEffect(() => {
    if (!Boolean(showIndex && subInfo.contract)) return;
    const item = allowance.filter((item) => {
      return item.name === tokens[showIndex].name;
    })[0];
    const pool = item.pools.filter((item) => {
      return item.name === subInfo.contract.name;
    })[0];
    setIsApproval(!Boolean(pool.allowance === 0));
  }, [tokens, showIndex, address, subInfo.contract, allowance]);

  const onFetchApproval = () => {
    fetchApproval(tokens[showIndex].name, subInfo.contract.name);
  };

  const onFetchPricePerFullShare2 = () => {
    if (
      (subInfo.name === "DMAGIC  " && tokens[showIndex].name === "NIGHTBANE") ||
      (subInfo.name === "NIGHTBANE " && tokens[showIndex].name === "DMAGIC")
    ) {
      const price = DrDmPrice(
        tokens[showIndex].name,
        subInfo.name,
        new BigNumber(sendJson.num)
          .multipliedBy(
            new BigNumber(10).exponentiatedBy(tokens[showIndex].decimals)
          )
          .toString(10)
      );
      setDrDmPrice(price);
    }
    if (
      (subInfo.name === "NIGHTBANE" && tokens[showIndex].name === "MATIC") ||
      (subInfo.name === "MATIC   " && tokens[showIndex].name === "NIGHTBANE") ||
      (subInfo.name === "NIGHTBANE  " && tokens[showIndex].name === "AXMATIC") ||
      (subInfo.name === "AXMATIC  " && tokens[showIndex].name === "NIGHTBANE")
    ) {
      const price2 = DmDrPrice(
        tokens[showIndex].name,
        subInfo.name,
        new BigNumber(sendJson.num)
          .multipliedBy(
            new BigNumber(10).exponentiatedBy(tokens[showIndex].decimals)
          )
          .toString(10)
      );
      setDmDrPrice(price2);
    }
    fetchPricePerFullShare(
      tokens[showIndex].name,
      subInfo.name,
      new BigNumber(sendJson.num)
        .multipliedBy(
          new BigNumber(10).exponentiatedBy(tokens[showIndex].decimals)
        )
        .toString(10)
    );
  };

  const onFetchZapOrSwap = async () => {
    setIsLoading(true);
    setSuccess(false);
    try {
      await fetchZapOrSwap(
        tokens[showIndex].name,
        subInfo.name,
        new BigNumber(sendJson.num)
          .multipliedBy(
            new BigNumber(10).exponentiatedBy(tokens[showIndex].decimals)
          )
          .toString(10)
      );
      setSuccess(true);
      checkApproval() ;
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  };
  const marks = [
    {
      value: 0,
      label: "0%",
    },
    {
      value: 25,
      label: "25%",
    },
    {
      value: 50,
      label: "50%",
    },
    {
      value: 75,
      label: "75%",
    },
    {
      value: 100,
      label: "100%",
    },
  ];
  function valuetext(value) {
    return `${value}%`;
  }


  // DRAX and DMAGIC Price
  const DrDmPrice = (token0, token1, amount) => {
    client
      .query({
        query: gql`
          query {
            pairs(where: { name_contains: "DRAX-DMAGIC" }) {
              reserve0
              reserve1
            }
          }
        `,
      })
      .then((result) => {
        let reserve0 = Number(result.data.pairs[0].reserve0);
        let reserve1 = Number(result.data.pairs[0].reserve1);
        if (token0 === "DRAX") {
          setDrDmPrice(((amount * (reserve1 / reserve0)) / 2) );
        } else {
          setDrDmPrice(((amount * (reserve0 / reserve1)) / 2) );
        }
      })
      .catch((e) => console.log(e));
  };

  // AXMATIC PRICE

  const DmDrPrice = (token0, token1, amount) => {
    client
      .query({
        query: gql`
          query {
            pairs(where: { name_contains: "AXMATIC-DMAGIC" }) {
              reserve0
              reserve1
            }
          }
        `,
      })
      .then((result) => {
        let reserve0 = Number(result.data.pairs[0].reserve0);
        let reserve1 = Number(result.data.pairs[0].reserve1);
        if (token0 === "MATIC" || token0 === "AXMATIC") {
          setDmDrPrice(((amount * (reserve1 / reserve0)) / 2) );
        } else {
          setDmDrPrice(((amount * (reserve0 / reserve1)) / 2) );
        }
      })
      .catch((e) => console.log(e));
  };

  const handleMainDropdownClick = (event) => {
    setShowIndex(event.key);
    setSendJson({ num: 0, slider: 0 });

    if (
      !tokens[event.key].receivableList.find((item) => {
        return item.name === subInfo.name;
      })
    ) {
      setSubInfo({});
    }
  };
  let mainDropdownList = [];
  tokens.map((item, index) => {
    mainDropdownList.push(
      <div className={classes.memuStyle} key={index}>
        <Avatar
          alt={item.name}
          src={require(`../../../images/${item.name}-logo.png`)}
          className={classNames({
            [classes.marginRight]: true,
            [classes.avatar]: true,
          })}
        />
        <span className={classes.avatarFont}>{item.name}</span>
      </div>
    );
    return true;
  });

  const handleSubDropdownClick = (event) => {
    setSuccess(false);
    let targetInfo = tokens[showIndex].receivableList.find((item) => {
      return item.name === event.key;
    });
    if (targetInfo) {
      setSubInfo(targetInfo);
      checkApproval();
    } else {
      checkApproval();
      setSubInfo({});
    }
  };

  const singleSubDropDownNode = (item) => {
    return (
      <div className={classes.subMemuStyle} key={item.name}>
        <Avatar
          alt={item.name}
          src={require(`../../../images/${item.name}-logo.png`)}
          className={classNames({
            [classes.marginRight]: true,
            [classes.avatar]: true,
          })}
        />
        <span className={classes.avatarFont}>{item.name}</span>
      </div>
    );
  };
  let subDropdownList = [];
  tokens[showIndex].receivableList.map((item) => {
    subDropdownList.push(singleSubDropDownNode(item));
  });

  const [sendJson, setSendJson] = useState({ num: 0, slider: 0 });
  const changeMainInput = (total, tokenDecimals, event) => {
    event.stopPropagation();
    let value = event.target.value;
    if (!inputLimitPass(value, tokenDecimals)) {
      return;
    }
    let sliderNum = 0;
    let inputVal = Number(value.replace(",", ""));
    if (value) {
      sliderNum = byDecimals(inputVal / total, 0).toFormat(2) * 100;
    }
    setSendJson({
      num: inputFinalVal(value, total, tokenDecimals),
      slider: sliderNum,
    });
  };

  const changeSliderVal = (total, event, sliderNum) => {
    event.stopPropagation();
    setSendJson({
      num: sliderNum === 0 ? 0 : calculateReallyNum(total, sliderNum),
      slider: sliderNum,
    });
  };

  let buttonTxt = "choose a pair!";
  if (subInfo.type) {
    buttonTxt = subInfo.type;
  }
  let balanceTotal = byDecimals(
    tokens[showIndex].balance,
    tokens[showIndex].decimals
  );
  return (
    <div className={classes.container}>
      <div className={classes.zapContainer}>
        <div className={classes.boxContainer}>
          <div className={classes.boxHeader}>
            <div className={classes.boxHeaderMain}>{t("Swap-Send")}</div>

            <div className={classes.boxHeaderSub}>{`${t(
              "Swap-Balance"
            )}: ${balanceTotal.toFormat(4)} ${tokens[showIndex].name}`}</div>
          </div>
          <br></br>
          <Grid item lg={12} className="cstmaccrdn3">
            <FormControl fullWidth>
              <CustomOutlinedInput
                value={sendJson.num}
                onChange={changeMainInput.bind(
                  this,
                  balanceTotal.toNumber(),
                  tokens[showIndex].decimals
                )}
                endAdornment={
                  <div className={classes.endAdornment}>
                    <div className={classes.maxButtonBox}>
                      <CustomButtons
                        onClick={() => {
                          setSendJson({
                            num: balanceTotal.toFixed(4),
                            slider: 100,
                          });
                        }}
                        className={classes.maxButton}
                      >
                        {t("Swap-Max")}
                      </CustomButtons>
                    </div>
                    <CustomDropdown
                      navDropdown
                      hoverColor="primary"
                      darkModal
                      buttonText={
                        <div className={classes.memuStyle}>
                          <Avatar
                            alt={tokens[showIndex].name}
                            src={require(`../../../images/${tokens[showIndex].name}-logo.png`)}
                            className={classNames({
                              [classes.marginRight]: true,
                              [classes.avatar]: true,
                            })}
                          />
                          <span className={classes.avatarFont}>
                            {tokens[showIndex].name}
                          </span>
                        </div>
                      }
                      buttonProps={{
                        className: classes.navLink,
                        color: "transparent",
                      }}
                      onClick={handleMainDropdownClick}
                      dropdownList={mainDropdownList}
                    />
                  </div>
                }
              />
              <br></br>
            </FormControl>
            <Box mddown="true">
              <GridItem className="progress_bx2">
                <Slider
                  width="80%"
                  value={sendJson.slider}
                  onChange={changeSliderVal.bind(this, balanceTotal.toNumber())}
                  getAriaValueText={valuetext}
                  aria-labelledby="discrete-slider-custom"
                  step={1}
                  valueLabelDisplay="auto"
                  marks={marks}
                />
              </GridItem>
            </Box>
          </Grid>
        </div>
        <img alt="..." src={require("assets/img/zappersli.svg")} height="3%" />{" "}
        {""}
        <div className={classes.boxContainer}>
          <div className={classes.boxHeader}>
            <div className={classes.boxHeaderMain}>
              Receive: {/*  MATIC <-> AXMATIC */}
              {(subInfo.name === "MATIC " &&
                tokens[showIndex].name === "AXMATIC") ||
              (subInfo.name === "AXMATIC" && tokens[showIndex].name === "MATIC")
                ? sendJson.num
                : ""}
              {/* // MATIC <-> DMAGIC */}
              {(subInfo.name === "DMAGIC" &&
                tokens[showIndex].name === "MATIC") ||
              (subInfo.name === "MATIC" && tokens[showIndex].name === "DMAGIC")
                ? pricePerFullShare
                : ""}
              {/* // AXMATC <-> DMGAIC */}
              {(subInfo.name === "DMAGIC " &&
                tokens[showIndex].name === "AXMATIC") ||
              (subInfo.name === "AXMATIC " &&
                tokens[showIndex].name === "DMAGIC")
                ? pricePerFullShare
                : ""}
              {/* // AXMATC <-> DMGAIC */}
              {subInfo.name === "NIGHTBANE   " &&
              tokens[showIndex].name === "AXMATIC-DMAGIC-LP"
                ? sendJson.num
                : ""}
              {/* // DRAX <-> DMGAIC */}
              {(subInfo.name === "DMAGIC  " &&
                tokens[showIndex].name === "NIGHTBANE") ||
              (subInfo.name === "NIGHTBANE " && tokens[showIndex].name === "DMAGIC")
                ? byDecimals(drdmprice).toFixed(4)
                : " "}
                               {/* // AXMATC <-> DMGAIC */}
              {(subInfo.name === "AXIOMS" &&
              tokens[showIndex].name === "DMAGIC") ||
              (subInfo.name === "DMAGIC   " &&
              tokens[showIndex].name === "AXIOMS")
                ? pricePerFullShare
                : ""}
              {/* // MATIC <-> DRAX */}
{/*               {(subInfo.name === "DRAX" &&
                tokens[showIndex].name === "MATIC") ||
              (subInfo.name === "MATIC   " && tokens[showIndex].name === "DRAX")
                ? byDecimals(dmdrprice).toFixed(4)
                : " "}
              {(subInfo.name === "DRAX  " &&
                tokens[showIndex].name === "AXMATIC") ||
              (subInfo.name === "AXMATIC  " &&
                tokens[showIndex].name === "DRAX")
                ? byDecimals(dmdrprice).toFixed(4)
                : " "} */}
            </div>
            <div className={classes.boxHeaderSub}>max. 10% Slippage</div>
          </div>

          <div className={classes.boxHeader}>
            <div className={classes.boxHeaderSub}> ** estimated in beta</div>
            <div className={classes.boxHeaderSub}>
              *** NIGHT (locks AXMATIC-DMAGIC-LP)
            </div>
          </div>

          <button
            className="btn btn_green2 c_black btn_wtgdnt_v2 "
            onClick={() => {
              onFetchPricePerFullShare2();
            }}
            disabled={
              !Boolean(tokens[showIndex].name && subInfo.contract) ||
              subInfo.contract === undefined ||
              sendJson.num === 0
            }
          >
            {" "}
            Get estimated outcome
          </button>
          <br></br>
          <FormControl fullWidth variant="outlined">
            <CustomDropdown
              popperClassName={classes.papperNav}
              navDropdown
              hoverColor="primary"
              darkModal
              buttonText={
                subInfo.name &&
                tokens[showIndex].receivableList.find((item) => {
                  return item.name === subInfo.name;
                }) &&
                singleSubDropDownNode(subInfo)
              }
              buttonProps={{
                className: classes.receiveStyle,
              }}
              onClick={handleSubDropdownClick}
              dropdownList={subDropdownList}
            />
          </FormControl>
        </div>
        <button
          className="btn btn_green btnzap c_black btn_wtgdnt_v2"
          onClick={() => {
            handlePlay();
            isApproval ? onFetchZapOrSwap() : onFetchApproval();
          }}
          disabled={
            (!Boolean(tokens[showIndex].name && subInfo.contract) &&
              fetchApprovalPending) ||
            subInfo.contract === undefined
          }
        >
          {isApproval && success ? "Success" : buttonTxt}{" "}
          {success ? (
            ""
            ) : (
            ""
          )}
          {isLoading && <div className="asloader">Loading...</div>}
        </button>
      </div>
    </div>
  );
}
