import { ezioupools } from "../../configure";

const tokens = {
  137: {},

};

ezioupools.map(({chainId, token, tokenAddress,tokenAddress2,tokenAddress3, call })=> {
  tokens[chainId][token] = {
    tokenAddress: tokenAddress,
    tokenAddress2: tokenAddress2,
    tokenAddress3: tokenAddress3,
    tokenBalance: 0,
    call: call,

  }
  tokens[chainId] = {
    tokenBalance: 0
  }
  return '';
})

// console.log(tokens)

const initialState = {
  ezioupools,
  tokens,
};

export default initialState;