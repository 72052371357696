import React from "react";

import {
  Container,
  Row,
  Col,
} from "reactstrap";

export default function Intro() {

  return (
    <>
      
      <div className="whatis_sc">
        <Container>
            <Row className="row-grid justify-content-between prltv_s">
              <img
                  alt="..."
                  className="mgcsmbls_bg"
                  src={require("assets/img/magic_symbols.png")}
                />
              <Col lg="5" className="cstm_col_6 cstm_pdng">
                
                <h3 className="grrnttl">what is?</h3>
                <img
                  alt="..."
                  className="img-fluid mb-3"
                  src={require("assets/img/dfmgklg.png")}
                />
                <p className="def_p pr_20">	Magic is a unique cross chain <span className="greentxt">DeFi</span> ecosystem project built upon a modular open framework, whose codebase is future proof, easily upgradeable, and continuously evolving. As decentralized finance continues its technological growth and evolution, so too will <span className="greentxt">Defi Magic.</span></p>

              </Col>
              <Col lg="7" className="cstm_col_7 text-right">
                
                <div className="prltv_s dsp_inlnbk mb-375">
                  <img
                    alt="..."
                    className="img-fluid"
                    src={require("assets/img/drk_img_03_02.jpg")}
                  />
                  <div className="img_lines"></div>
                  <div className="wtis_lines01"></div>
                </div>
              
              </Col>
            </Row>
        </Container>
      </div>
    </>
  );
}
