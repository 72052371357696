import { erc20ABI, swap } from 'features/configure/abi';
import { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../common/redux/actions';
import { ethers } from 'ethers';
import { useConnectWallet } from '../../home/redux/hooks'; 
import { byDecimals } from 'features/helpers/bignumber'

export { useFetchPoolBalances } from './fetchPoolBalances';

export function useApprove(tokenAddress, poolAddress) {
    const { web3, address } = useConnectWallet();
    const [isPending, setIsPending] = useState(false);
    const dispatch = useDispatch();


    const handleApprove = useCallback(async () => {
        setIsPending(true);
        try {
            await new Promise((resolve, reject) => {
                const contract = new web3.eth.Contract(erc20ABI, tokenAddress);

                contract.methods.approve(poolAddress, ethers.constants.MaxUint256.toString(10)).send({ from: address, gas: 1500000 })
                .on('transactionHash', function(hash){
                    dispatch(enqueueSnackbar({
                        message: hash,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'success'
                        },
                        hash
                    }));
                })
                .on('receipt', function(receipt){
                    resolve()
                })
                .on('error', function(error) {
                    console.log(error)
                    reject(error)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
            });
        } finally {
            setIsPending(false);
        }
    }, [dispatch, setIsPending, web3, address, poolAddress, tokenAddress]);

    return { isPending, onApprove: handleApprove };
}

export function useAllowance(tokenAddress, spender) {
    const { web3, address } = useConnectWallet();
    const [allowance, setAllowance] = useState("0");

    const fetchAllowance = useCallback(async () => {
        if (tokenAddress === '') {
            setAllowance(ethers.constants.MaxUint256.toString(10))
            return;
        }
        const contract = new web3.eth.Contract(erc20ABI, tokenAddress);

        const allowance = await contract.methods.allowance(address, spender).call()
        setAllowance(allowance)
    }, [address, spender, setAllowance, tokenAddress, web3])

    useEffect(() => {
        if (web3 && address) {
            fetchAllowance()
        
        let refreshInterval = setInterval(fetchAllowance, 10000)
        return () => clearInterval(refreshInterval)  }
    }, [web3, address, fetchAllowance])

    return allowance
}

// swap
export function useDeposit(poolAddress, tokenAddress, call) {
    const { web3, address } = useConnectWallet();
    const [isPending, setIsPending] = useState(false);
    const dispatch = useDispatch();


    const handleDeposit = useCallback(async (amount) => {
        setIsPending(true);
        try {
            await new Promise(async (resolve, reject) => {
                const contract = new web3.eth.Contract(swap, poolAddress);
             contract.methods.depositTokens(amount).send({ from: address, gasLimit: 1500000 })
                .on('transactionHash', function(hash){
                    dispatch(enqueueSnackbar({
                        message: hash,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'success'
                        },
                        hash
                    }));
                }) 
                .on('receipt', function(receipt){
                    resolve()
                })
                .on('error', function(error) {
                    console.log(error)
                    reject(error)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
            });
            
        } finally {
            setIsPending(false);
        }
    }, [dispatch, setIsPending, web3, address, poolAddress, ]);

    return { isPending, onDeposit: handleDeposit };
}

export function useBalanceOf(tokenAddress) {
    const { web3, address } = useConnectWallet();
    const [balance, setBalance] = useState("0");

    const fetchBalance = useCallback(async () => {
        let balance;
        if (tokenAddress === '') {
            balance = await web3.eth.getBalance(address);
        } else {
            const contract = new web3.eth.Contract(erc20ABI, tokenAddress);

            balance = await contract.methods.balanceOf(address).call()
        }
        setBalance(balance)
    
    }, [address, setBalance, tokenAddress, web3])

    useEffect(() => {
        if (web3 && address) {
            fetchBalance()
        
        let refreshInterval = setInterval(fetchBalance, 10000)
        return () => clearInterval(refreshInterval) }
    }, [web3, address, fetchBalance])

    return balance
}

export function useRatio(poolAddress) {
    const { web3, address } = useConnectWallet();
    const [LIST, setLIST] = useState(['']);

    const fetchLIST = useCallback(async () => {
        const contract = new web3.eth.Contract(swap, poolAddress);

        const LIST = await contract.methods.getDepositRatio().call({ from: address })
        setLIST(LIST) 
    }, [address, setLIST, poolAddress, web3])
    
    useEffect(() => {
        if (web3 && address) {
            fetchLIST()
        
        let refreshInterval = setInterval(fetchLIST, 10000000)
        return () => clearInterval(refreshInterval) }
    }, [web3, address, fetchLIST])
    return LIST
}

export function useMAX(poolAddress) {
    const { web3, address } = useConnectWallet();
    const [LIST, setLIST] = useState(['']);

    const fetchLIST = useCallback(async () => {
        const contract = new web3.eth.Contract(swap, poolAddress);

        const LIST = await contract.methods.getMaximumDeposit().call({ from: address })
        setLIST(LIST) 
    }, [address, setLIST, poolAddress, web3])
    
    useEffect(() => {
        if (web3 && address) {
            fetchLIST()
        
        let refreshInterval = setInterval(fetchLIST, 10000000)
        return () => clearInterval(refreshInterval) }
    }, [web3, address, fetchLIST])
    return LIST
}
