import React, { useEffect, useState } from 'react';
import StakePools from './sections/StakePools'
import appStyle from "./jss/sections/appStyle"
import { makeStyles } from "@material-ui/core/styles";
import Lottie from 'react-lottie';
import animationData from "../../lotties/zapper_confirm4";


const useStyles = makeStyles(appStyle);


export default function StakePage() {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setTimeout(() => setLoading(false),1000)
  }, [])
  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  
  
    return (
      <>
      {loading === false ? (
        <div className={classes.container} >
        <div className="space-100" />
        <div className="space-100" />
        <div className="space-100" />

       
      <StakePools /> 
      <div className="space-100" />
     
      <div className="space-100" />
      </div>

) : (
  <Lottie
  options={defaultOptions2}
  height={900}
  width={900}
/>

)}

    </>
  );
}