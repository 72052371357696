/* eslint-disable */
import React, { useState, useEffect } from "react";
import BigNumber from "bignumber.js";
import { useConnectWallet } from "../../home/redux/hooks";
import { useFetchPoolBalances } from "../redux/hooks";
import appStyle from "./appStyle";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import {
  Col,
  Row,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { Box } from "@material-ui/core";
import classnames from "classnames";
import TabFourContent from "./TabFourContent";

const useStyles = makeStyles(appStyle);

export default function SectionPools(index) {
  const classes = useStyles();

  const { networkId } = useConnectWallet();
  let { thecauldronpools } = useFetchPoolBalances();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  ///tabs
  const [activeTab, setActiveTab] = useState("4");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <>
    <div className="cldrn_bg">
      <div className={classes.container} >
      <Grid container style={{ paddingTop: "4px" }} id="container">
    <div className="space-100 d-nn" />
      <Col xs={12} className="text-right mt-5">
        <label className="stk_lbl">
          {" "}
          Network Details:<span className="g_dot"></span>
          <span>Polygon</span> {' '}
         
          <a href="https://bsc.defimagic.io/#" >
          <span className="r_dot"></span>
          <span>BSC</span>
          </a>
        </label>
      </Col>
      </Grid>

          <Row className="mt-5">
            <Col md={12} className="text-center mt-2">
              <h3 className="cldrn_txt mb-0">
                <sup>The</sup> Cauldron:
                <span> </span>
              </h3>
            </Col>
            <Col md={12} className="text-center">
              <h2 className="cldnmddltxt txtitlc">
                {/*                 A workshop dedicated to advanced forms of Defi Alchemy. Your
                project, with our mastery of spellcraft: the possibilities are
                limitless. */}
              </h2>
            </Col>
          </Row>
        </div>
        <Box className="cldrn_bsc_sc">
          <Box className="cldrn_bsc_lft">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "4" })}
                  onClick={() => {
                    toggle("4");
                  }}
                >
                  <Box component="img" src="/static/img/Magic_Spellbinding_Cypto.png" alt="" />
                </NavLink>
              </NavItem>
            </Nav>
          </Box>
          <Box className="greenbrdr_bx cldrn_bsc_rgt">
            <TabContent activeTab={activeTab}>
                {console.log(networkId)}
              <TabPane tabId="4">
                {networkId !== 137 ? (
                  <h2 className="cldnmddltxt txtitlc">
                    Please change your Network to Polygon Chain!
                  </h2>
                ) : (
                  <h2 className="cldnmddltxt txtitlc"></h2>
                )}
                {networkId === 137 && thecauldronpools
                  .filter(
                    (cpool) => cpool.id === "pi"
                  )
                  .map((cpool, index) => (
                    <TabFourContent index={index} cpool={cpool} />
                  ))}
              </TabPane>
            </TabContent>
          </Box>
        </Box>
      </div>
    </>
  );
}
