import React, { useEffect } from 'react';
import appStyle from "./jss/sections/appStyle"
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(appStyle);

export default function VaultPage() {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);
  return (
    <>
          <div className="header header-3a">
          <div>
            <div className="header-video3a header-filter3a">
            <div />
              <video
                autoPlay="manually"
                loop="loop"
                muted="muted"
                playsInline="playsinline"
              >
                <source
                  src={require("assets/video/dragon.mp4")}
                  type="video/mp4"
                />
              </video>
        <div className={classes.container} >
        <div className="space-100" />
      </div>
      </div>
      <div className={classes.container} >
      </div>
      </div>
      </div>
    </>
  );
}