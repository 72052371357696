import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SnackbarProvider } from 'notistack';
import { Notifier } from "features/common"
import { makeStyles } from "@material-ui/core/styles";
import { useConnectWallet, useDisconnectWallet } from './redux/hooks';
import { createWeb3Modal } from '../web3';
import appStyle from "./jss/appStyle.js";
import NetworksProvider from 'components/NetworksProvider/NetworksProvider';
import { networkSetup } from 'common/networkSetup';
import ColorNavBar from 'components/Header/ColorNavBar';
import Lottie from 'react-lottie';
import animationData from "../../lotties/loader";

const useStyles = makeStyles(appStyle);

export default function App({ children }) {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoading(false), 3000)
  }, [])

  const classes = useStyles();
  const { t } = useTranslation();

  const {
    connectWallet,
    web3,
    address,
    networkId,
    connected,
    connectWalletPending,
  } = useConnectWallet();
  const { disconnectWallet } = useDisconnectWallet();
  const [web3Modal, setModal] = useState(null);

  useEffect(() => {
    setModal(createWeb3Modal(t));
  }, [setModal, t]);

  useEffect(() => {
    if (web3Modal && (web3Modal.cachedProvider || window.ethereum)) {
      connectWallet(web3Modal);
    }
  }, [web3Modal, connectWallet]);
  

  // Mozilla Bug by network request if you change the tab to another web3 Website
  useEffect(() => {
    if (
      web3 &&
      address &&
      !connectWalletPending &&
      networkId &&
      Boolean(networkId !== Number(process.env.REACT_APP_NETWORK_ID))
    ) {

        console.error();
        alert(t('Please change Network to Polygon'));

    }
  }, [web3, address, networkId, connectWalletPending, t])

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};



  return (

    <>
    {loading === false ? (
    <NetworksProvider className="space-100" >
    <SnackbarProvider>
      <div className={classes.page}>

            <ColorNavBar
              address={address}
              connected={connected}
              connectWallet={() => connectWallet(web3Modal)}
              disconnectWallet={() => disconnectWallet(web3, web3Modal)}
            />

  
             <div>
            <div className={classes.children}>
        
            {children}
              <Notifier />
            
            </div>
        </div>
        

      </div>
    </SnackbarProvider>
    </NetworksProvider>
 ) : (

  <Lottie
  options={defaultOptions}
  height={1000}
  width={1000}
/>
)}
</>
  );
}
