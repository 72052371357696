/* eslint-disable */
import React, { useState } from 'react';
import BigNumber from "bignumber.js";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { useConnectWallet } from '../../home/redux/hooks';
import { useFetchPoolBalances } from '../redux/hooks';
import PoolContent from './PoolContent';
import { Col, Row, Container, Card, CardTitle, CardBody } from 'reactstrap'
import appStyle from "../jss/sections/appStyle"
import logo12 from "assets/img/g_logo.png"
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(appStyle);

export default function SectionPools(index) {
    const { networkId } = useConnectWallet();
    let { nftpools } = useFetchPoolBalances();
    const classes = useStyles();


    return (
        <>
            <div className="nft_bg">
                <div className={classes.container} >
                <Grid
            container
            style={{ paddingTop: '4px', paddingRight: '60px' }}
            id="container"
          >
            <div className="space-100 d-nn" />
            <Col xs={12} className="text-right mt-5">
              <label className="stk_lbl">
                {' '}
                Network Details:<span className="g_dot"></span>
                <span>Polygon</span>{' '}
                <a href="https://bsc.defimagic.io/#">
                  <span className="r_dot"></span>
                  <span>BSC</span>
                </a>
              </label>
            </Col>
          </Grid>

                
                    
                    <Row className="mt-5">
                        <Col md={12}>
                            <Box className="nftttl_bx">
                                <Box className="img_g_bx">
                                    <Box component="img" src={logo12}/>
                                </Box>
                                <Typography component="h3">Dragon Nest - Liquidity Generator</Typography>
                                <Typography component="p">the home for Dark Magic NFTs</Typography>
                            </Box>
                            
                        </Col>
                        
                        <Col md={12} className="mb-1">
                            <div className="vd_box nft_vd_box asvdbx">
                                <Row>
                                    {nftpools.filter(npool => (npool.chainId === networkId)).map((npool, index) => (
                                        <PoolContent index={index} npool={npool} />
                                    ))}
                                </Row>
                                <div className="space-50" />
                            </div>
                        </Col>
                        <Col md={12}>
                        <Typography component="h3" className="nft_lst_txt"><span>Dark Magic</span>  NFTs:  for collecting, boosting, trading, and hatching</Typography>
                            <Box className="nftttl_bx mb-3"></Box>
                            <Typography component="p" className="nft_lst_txt_p">The <span>Liquidity Generation</span> system gives a unique advantage back to the community from every sale of a <span>NFT</span> hooked up to this system. When a user spends tokens to get the <span>NFT</span>, the smart contract will take the tokens, convert them to axMatic and then begin a series of events (trades) to increase the liquidity of <span>Dark Magic</span> in the <span>DeFi Magic</span> ecosystem.</Typography>
                        </Col>
                        
                    </Row>
                    <div className="space-100" />
                </div>
            </div>
        </>
    )
}
