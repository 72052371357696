import { erc20ABI, staking1 } from 'features/configure/abi';
import { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../common/redux/actions';
import { ethers  } from 'ethers';
import { useConnectWallet } from '../../home/redux/hooks'; 
export { useFetchPoolBalances } from './fetchPoolBalances';


export function useApprove(tokenAddress, poolAddress) {
    const { web3, address } = useConnectWallet();
    const [isPending, setIsPending] = useState(false);
    const dispatch = useDispatch();


    const handleApprove = useCallback(async () => {
        setIsPending(true);
        try {
            await new Promise((resolve, reject) => {
                const contract = new web3.eth.Contract(erc20ABI, tokenAddress);

                contract.methods.approve(poolAddress, ethers.constants.MaxUint256.toString(10)).send({ from: address })
                .on('transactionHash', function(hash){
                    dispatch(enqueueSnackbar({
                        message: hash,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'success'
                        },
                        hash
                    }));
                })
                .on('receipt', function(receipt){
                    resolve()
                })
                .on('error', function(error) {
                    console.log(error)
                    reject(error)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
            });
        } finally {
            setIsPending(false);
        }
    }, [dispatch, setIsPending, web3, address, poolAddress, tokenAddress]);

    return { isPending, onApprove: handleApprove };
  }

export function useAllowance(tokenAddress, spender) {
    const { web3, address } = useConnectWallet();
    const [allowance, setAllowance] = useState("0");

    const fetchAllowance = useCallback(async () => {
        if (tokenAddress === '') {
            setAllowance(ethers.constants.MaxUint256.toString(10))
            return;
        }
        const contract = new web3.eth.Contract(erc20ABI, tokenAddress);

        const allowance = await contract.methods.allowance(address, spender).call()
        setAllowance(allowance)
    }, [address, spender, setAllowance, tokenAddress, web3])

    useEffect(() => {
        if (web3 && address) {
            fetchAllowance()
        
        let refreshInterval = setInterval(fetchAllowance, 10000)
        return () => clearInterval(refreshInterval)  }
    }, [web3, address, fetchAllowance])

    return allowance
}

export function useBalanceOf(tokenAddress) {
    const { web3, address } = useConnectWallet();
    const [balance, setBalance] = useState("0");

    const fetchBalance = useCallback(async () => {
        let balance;
        if (tokenAddress === '') {
            balance = await web3.eth.getBalance(address);
        } else {
            const contract = new web3.eth.Contract(erc20ABI, tokenAddress);

            balance = await contract.methods.balanceOf(address).call()
        }
        setBalance(balance)
    
    }, [address, setBalance, tokenAddress, web3])

    useEffect(() => {
        if (web3 && address) {
            fetchBalance()
        
        let refreshInterval = setInterval(fetchBalance, 5000)
        return () => clearInterval(refreshInterval) }
    }, [web3, address, fetchBalance])

    return balance
}

// staking1
export function useEarned(poolAddress) {
    const { web3, address } = useConnectWallet();
    const [earned, setEarned] = useState("0");

    const fetchEarned = useCallback(async () => {
        const contract = new web3.eth.Contract(staking1, poolAddress);

        const earned = await contract.methods.totalEarnedDRAX( address ).call({ from: address })
        setEarned(earned) 
    }, [address, setEarned, poolAddress, web3])
    
    useEffect(() => {
        if (web3 && address) {
            fetchEarned()
        
        let refreshInterval = setInterval(fetchEarned, 1000000)
        return () => clearInterval(refreshInterval)  }
    }, [web3, address, fetchEarned])

    return earned
}

export function useEarned2(poolAddress) {
    const { web3, address } = useConnectWallet();
    const [earned2, setEarned2] = useState("0");

    const fetchEarned2 = useCallback(async () => {
        const contract = new web3.eth.Contract(staking1, poolAddress);

        const earned2 = await contract.methods.totalEarnedDmagic( address ).call({ from: address })
        setEarned2(earned2) 
    }, [address, setEarned2, poolAddress, web3])
    
    useEffect(() => {
        if (web3 && address) {
            fetchEarned2()
        
        let refreshInterval = setInterval(fetchEarned2, 1000000)
        return () => clearInterval(refreshInterval) }
    }, [web3, address, fetchEarned2]) 

    return earned2
}


export function useTime1(poolAddress) {
    const { web3, address } = useConnectWallet();
    const [time1, setTime1] = useState('');

    const fetchTime1 = useCallback(async () => {
        const contract = new web3.eth.Contract(staking1, poolAddress);

        const time1 = await contract.methods.lockingPeriodStaking( address ).call({ from: address })
        setTime1(time1) 
       
    }, [address, setTime1, poolAddress, web3])
    
    useEffect(() => {
        if (web3 && address) {
            fetchTime1()
  }
    }, [web3, address, fetchTime1])
       return time1
}

export function useTime2(poolAddress) {
    const { web3, address } = useConnectWallet();
    const [time2, setTime2] = useState('');

    const fetchTime2 = useCallback(async () => {
        const contract = new web3.eth.Contract(staking1, poolAddress);

        const time2 = await contract.methods.lockingPeriodMultiplier ( address ).call({ from: address })
        setTime2(time2) 
       
    }, [address, setTime2, poolAddress, web3])
    
    useEffect(() => {
        if (web3 && address) {
            fetchTime2()
 }
    }, [web3, address, fetchTime2])
       return time2
}



export function useDraxAPY(poolAddress) {
    const { web3, address } = useConnectWallet();
    const [APY, setAPY] = useState("0");

    const fetchAPY = useCallback(async () => {
        const contract = new web3.eth.Contract(staking1, poolAddress);

        const APY = await contract.methods.getDraxAPY().call({ from: address })
        setAPY(APY) 
    }, [address, setAPY, poolAddress, web3])
    
    useEffect(() => {
        if (web3 && address) {
            fetchAPY()
   }
    }, [web3, address, fetchAPY])

    return APY
}

export function useDmagicAPY(poolAddress) {
    const { web3, address } = useConnectWallet();
    const [APY2, setAPY2] = useState("0");

    const fetchAPY2 = useCallback(async () => {
        const contract = new web3.eth.Contract(staking1, poolAddress);

        const APY2 = await contract.methods.getDmagicAPY().call({ from: address })
        setAPY2(APY2) 
    }, [address, setAPY2, poolAddress, web3])
    
    useEffect(() => {
        if (web3 && address) {
            fetchAPY2()
}
    }, [web3, address, fetchAPY2])

    return APY2
}

export function useMultiplyingFactor(poolAddress) {
    const { web3, address } = useConnectWallet();
    const [Multi, setMulti] = useState("0");

    const fetchMulti = useCallback(async () => {
        const contract = new web3.eth.Contract(staking1, poolAddress);

        const Multi = await contract.methods.getMultiplyingFactor(address).call({ from: address })
        setMulti(Multi) 
    }, [address, setMulti, poolAddress, web3])
    
    useEffect(() => {
        if (web3 && address) {
            fetchMulti()
        
        let refreshInterval = setInterval(fetchMulti, 100000000)
        return () => clearInterval(refreshInterval)}
    }, [web3, address, fetchMulti])

    return Multi
}



// staking1
export function useDeposit(poolAddress, tokenAddress) {
    const { web3, address } = useConnectWallet();
    const [isPending, setIsPending] = useState(false);
    const dispatch = useDispatch();


    const handleDeposit = useCallback(async (amount) => {
        setIsPending(true);
        try {
            await new Promise(async (resolve, reject) => {
                const contract = new web3.eth.Contract(staking1, poolAddress);
                contract.methods.stake(amount).send({ from: address })
                .on('transactionHash', function(hash){
                    dispatch(enqueueSnackbar({
                        message: hash,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'success'
                        },
                        hash
                    }));
                })
                .on('receipt', function(receipt){
                    resolve()
                })
                .on('error', function(error) {
                    console.log(error)
                    reject(error)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
            });
        } finally {
            setIsPending(false);
        }
    }, [dispatch, setIsPending, web3, address, poolAddress ]);

    return { isPending, onDeposit: handleDeposit };
}

// staking1
export function useWithdraw(poolAddress, tokenAddress) {
    const { web3, address } = useConnectWallet();
    const [isPending, setIsPending] = useState(false);
    const dispatch = useDispatch();


    const handleWithdraw = useCallback(async (amount) => {
        setIsPending(true);
        try {
            await new Promise(async (resolve, reject) => {
                const contract = new web3.eth.Contract(staking1, poolAddress);
                contract.methods.withdraw(amount).send({ from: address })
                .on('transactionHash', function(hash){
                    dispatch(enqueueSnackbar({
                        message: hash,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'success'
                        },
                        hash
                    }));
                })
                .on('receipt', function(receipt){
                    resolve()
                })
                .on('error', function(error) {
                    console.log(error)
                    reject(error)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
            });
        } finally {
            setIsPending(false);
        }
    }, [dispatch, setIsPending, web3, address, poolAddress ]);

    return { isPending, onWithdraw: handleWithdraw };
}

// staking1
export function useFetchGetReward(poolAddress) {
    const { web3, address } = useConnectWallet();
    const [isPending, setIsPending] = useState(false);
    const dispatch = useDispatch();


    const handleGetReward = useCallback(async () => {
        setIsPending(true);
        try {
            await new Promise(async (resolve, reject) => {
                const contract = new web3.eth.Contract(staking1, poolAddress);

                contract.methods.getReward().send({ from: address })
                .on('transactionHash', function(hash){
                    dispatch(enqueueSnackbar({
                        message: hash,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'success'
                        },
                        hash
                    }));
                })
                .on('receipt', function(receipt){
                    resolve()
                })
                .on('error', function(error) {
                    console.log(error)
                    reject(error)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
            });
        } finally {
            setIsPending(false);
        }
    }, [dispatch, setIsPending, web3, address, poolAddress]);

    return { isPending, onGetReward: handleGetReward };
}