/* eslint-disable */
import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { inputLimitPass, inputFinalVal, isEmpty } from "features/helpers/utils";
import BigNumber from "bignumber.js";
import { byDecimals } from "features/helpers/bignumber";
import FormControl from "@material-ui/core/FormControl";
import {
  useAllowance,
  useApprove,
  useDeposit,
  useWithdraw,
  useFetchGetReward,
  useTime1,
  useTime2,
  useBalanceOf,
} from "../redux/hooks";
import Slider from "@material-ui/core/Slider";
import withdrawimg from "../../../assets/img/withdraw.svg";
import claimimg from "../../../assets/img/claim.svg";
import depositimg from "../../../assets/img/deposit.svg";
import Countdown from "react-countdown";
import Moment from "react-moment";
import "moment-timezone";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
Moment.globalLocal = true;

const forMat = (number) => {
  return new BigNumber(number)
    .multipliedBy(new BigNumber(10000))
    .dividedToIntegerBy(new BigNumber(1))
    .dividedBy(new BigNumber(10000))
    .toNumber();
};

export default function PoolContent({ index, pool }) {
  const { t } = useTranslation();

  const tokenBalance = useBalanceOf(pool.tokenAddress);
  let balanceSingle = byDecimals(tokenBalance, pool.tokenDecimals);
  const depositedBalance = useBalanceOf(pool.earnContractAddress);
  let singleDepositedBalance = byDecimals(
    depositedBalance,
    pool.itokenDecimals
  );
  const allowance = useAllowance(pool.tokenAddress, pool.earnContractAddress);
  const networkId = pool.chainId;

  const { isPending: isApprovePending, onApprove } = useApprove(
    pool.tokenAddress,
    pool.earnContractAddress
  );
  const { onGetReward, isPending: isGetRewardPending } = useFetchGetReward(
    pool.earnContractAddress
  );
  const { onDeposit, isPending: isDepositPending } = useDeposit(
    pool.earnContractAddress,
    pool.tokenAddress
  );
  const { onWithdraw, isPending: isWithdarwPending } = useWithdraw(
    pool.earnContractAddress,
    pool.tokenAddress
  );
  const time1 = useTime1(pool.earnContractAddress);
  const time2 = useTime2(pool.earnContractAddress);

  const [balanceToDeposit, setBalanceToDeposit] = useState("0");
  const [balanceToDepositSlider, setBalanceToDepositSlider] = useState(0);
  const [balanceToWithdraw, setBalanceToWithdraw] = useState("0");
  const [balanceToWithdrawSlider, setBalanceToWithdrawSlider] = useState(0);

  const changeDetailInputValue = (type, index, total, tokenDecimals, event) => {
    let value = event.target.value;
    if (!inputLimitPass(value, tokenDecimals)) {
      return;
    }
    let sliderNum = 0;
    let inputVal = Number(value.replace(",", ""));
    if (value) {
      sliderNum = byDecimals(inputVal / total, 0).toFormat(2) * 100;
    }
    switch (type) {
      case "depositedBalance":
        setBalanceToDeposit(inputFinalVal(value, total, tokenDecimals));
        setBalanceToDepositSlider(sliderNum);
        break;
      case "withdrawAmount":
        setBalanceToWithdraw(inputFinalVal(value, total, tokenDecimals));
        setBalanceToWithdrawSlider(sliderNum);
      default:
        break;
    }
  };

  const handleDepositedBalance = (
    index,
    total,
    tokenDecimals,
    event,
    sliderNum
  ) => {
    const val =
      sliderNum == 0
        ? "0"
        : new BigNumber(total).times(sliderNum).div(100).toFixed(0);
    setBalanceToDeposit(byDecimals(val, tokenDecimals));
    setBalanceToDepositSlider(sliderNum);
  };
  const handleWithdrawAmount = (
    index,
    total,
    tokenDecimals,
    event,
    sliderNum
  ) => {
    const val =
      sliderNum == 0
        ? "0"
        : new BigNumber(total).times(sliderNum).div(100).toFixed(0);
    setBalanceToWithdraw(byDecimals(val, tokenDecimals));
    setBalanceToWithdrawSlider(sliderNum);
  };

  const marks = [
    {
      value: 0,
      label: "0%",
    },
    {
      value: 25,
      label: "25%",
    },
    {
      value: 50,
      label: "50%",
    },
    {
      value: 75,
      label: "75%",
    },
    {
      value: 100,
      label: "100%",
    },
  ];
  function valuetext(value) {
    return `${value}%`;
  }
  ///withdraw slider
  const withdraw = [
    {
      value: 0,
      label: "0%",
    },
    {
      value: 25,
      label: "25%",
    },
    {
      value: 50,
      label: "50%",
    },
    {
      value: 75,
      label: "75%",
    },
    {
      value: 100,
      label: "100%",
    },
  ];
  function withdrawvaluetext(value) {
    return `${value}%`;
  }
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        className="head_prnt"
        spacing={5}
      >
        <Grid item lg={6}>
          <h6 className="dpst_ttl">
            Deposit{" "}
            <span>
              {t("Vault-Balance")}:<b>  {forMat(balanceSingle)}</b> {pool.token}
            </span>
          </h6>

          <Grid item lg={12} className="cstmaccrdn2">
            <div className="d-flex input_bx">
              <FormControl>
                <input
                  className="input_fld"
                  value={balanceToDeposit}
                  onChange={changeDetailInputValue.bind(
                    this,
                    "depositedBalance",
                    index,
                    balanceSingle,
                    pool.tokenDecimals
                  )}
                />
              </FormControl>
            </div>

            <div className="progress_bx">
              <Slider
                getAriaValueText={valuetext}
                aria-labelledby="discrete-slider-custom"
                step={1}
                valueLabelDisplay="auto"
                marks={marks}
                value={balanceToDepositSlider}
                onChange={handleDepositedBalance.bind(
                  this,
                  index,
                  tokenBalance,
                  pool.tokenDecimals
                )}
              />
            </div>
          </Grid>

          <div className="progress_action text-right mt-3">
            {/* <button className="btn btn-stk" onClick={handleClickOpen}>
              <img
                className="mr-2 fr_def"
                width="25"
                height="auto"
                background-color="#000000"
                src={depositimg}
                alt="logo"
              />
              Approve
            </button> */}
            {allowance === "0" ? (
              <div className="progress_action text-right mt-3">
                <button
                  className="btn btn-stk"
                  onClick={() => {
                    handleClose();
                    onApprove();
                  }}
                  disabled={isApprovePending[index]}
                > <span>
                  <img
                    className="mr-2 fr_def"
                    width="25"
                    height="auto"
                    background-color="#000000"
                    src={depositimg}
                    alt="logo"
                  />
                  {isApprovePending
                    ? `${t("Vault-ApproveING")}`
                    : `${t("Vault-ApproveButton")}`}
                    </span>
                </button>
              </div>
            ) : (
              <div className="progress_action text-right mt-3">
                <button
                  className="btn btn-stk"
                  onFocus={(event) => event.stopPropagation()}
                  disabled={isDepositPending}
                  onClick={(event) => {
                    handleClickOpen();
                  }}
                >
                  {" "}
                  <img
                    className="mr-2 fr_def"
                    width="25"
                    height="auto"
                    background-color="#000000"
                    src={depositimg}
                    alt="logo"
                  />
                  {t("Vault-DepositButton")}
                </button>
              </div>
            )}
          </div>
        </Grid>

        <Grid item lg={6}>
          <div className="othrinfo">
            <span>
              Pool Timelock:
              <br />5 Day
            </span>
            <span>
              Timelock Remaining:
              <br />
              {time1 && <Countdown date={time1 * 1000} />}
            </span>
            <span>
              Penalty Fee:
              <br />
              5%
            </span>
          </div>
          <h6 className="dpst_ttl">
            Withdraw{" "}
            <span>
              <b>{forMat(singleDepositedBalance)} </b> {pool.earnedToken}
            </span>
          </h6>
          <Grid item lg={12} className="cstmaccrdn2">
            <div className="d-flex input_bx">
              <FormControl fullWidth variant="outlined">
                <input
                  className="input_fld"
                  type="text"
                  value={balanceToWithdraw}
                  onChange={changeDetailInputValue.bind(
                    this,
                    "withdrawAmount",
                    index,
                    singleDepositedBalance,
                    pool.itokenDecimals
                  )}
                />
              </FormControl>
            </div>

            <div className="progress_bx progress_bx_rd">
              <Slider
                value={balanceToWithdrawSlider}
                onChange={handleWithdrawAmount.bind(
                  this,
                  index,
                  depositedBalance,
                  pool.tokenDecimals
                )}
                getAriaValueText={withdrawvaluetext}
                aria-labelledby="discrete-slider-custom"
                step={1}
                valueLabelDisplay="auto"
                marks={withdraw}
              />
            </div>
          </Grid>
          {networkId == "1" ? (
            <div className="progress_action text-right mt-3">
              <button
                className="btn btn-stk btn-wthdrw"
                disabled={isWithdarwPending}
                onClick={() =>
                  onWithdraw(
                    balanceToWithdrawSlider >= 100
                      ? depositedBalance
                      : new BigNumber(balanceToWithdraw)
                          .multipliedBy(
                            new BigNumber(10).exponentiatedBy(
                              pool.tokenDecimals
                            )
                          )
                          .toString(10)
                  )
                }
              >
                {isWithdarwPending
                  ? `${t("Vault-WithdrawING")}`
                  : `${t("Vault-WithdrawButton")}`}
              </button>{" "}
              <img
                className="mr-2 fr_def"
                width="25"
                height="auto"
                background-color="#000000"
                src={withdrawimg}
                alt="logo"
              />
            </div>
          ) : (
            <div className="progress_action text-right mt-3">
              <button
                className="btn btn-stk btn-wthdrw"
                disabled={isWithdarwPending}
                onClick={() =>
                  onWithdraw(
                    balanceToWithdrawSlider >= 100
                      ? depositedBalance
                      : new BigNumber(balanceToWithdraw)
                          .multipliedBy(
                            new BigNumber(10).exponentiatedBy(
                              pool.tokenDecimals
                            )
                          )
                          .toString(10)
                  )
                }
              >
                {" "}
                <img
                  className="mr-2 fr_def"
                  width="25"
                  height="auto"
                  background-color="#000000"
                  src={withdrawimg}
                  alt="logo"
                />
                {isWithdarwPending
                  ? `${t("Vault-WithdrawING")}`
                  : `${t("Vault-WithdrawButton")}`}
              </button>{" "}
              <button
                className="btn btn-stk btn-wthdrw"
                onClick={() => onGetReward()}
              >
                <img
                  className="mr-2 fr_def"
                  width="25"
                  height="auto"
                  background-color="#000000"
                  src={claimimg}
                  alt="logo"
                />

                {isGetRewardPending
                  ? `${t("Vault-ClaimING")}`
                  : `${t("Vault-ClaimButton")}`}
              </button>
            </div>
          )}
        </Grid>
      </Grid>
      {/* Aprove Modal */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="aprvmdl"
      >
        <DialogTitle id="alert-dialog-title">{"Note"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
              <li> Depositing will reset the Timelock Countdown. </li>
              <li>Withdrawing before Timelock expiration incurs a 5% fee.</li>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className="progress_action d-flex w-100 mt-3 justify-content-center">
            {allowance !== "0" && (
              <div className="progress_action text-right mt-3">
                <button
                  className="btn btn-stk"
                  onFocus={(event) => event.stopPropagation()}
                 disabled={isDepositPending}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleClose();
                    if (!isEmpty(pool.depostAlert)) {
                      setWaitDialogConfirmJson({
                        content: pool.depostAlert,
                        func: () => {
                          onDeposit(pool, index, false, balanceSingle, event);
                        },
                      });
                      setOpenDialog(true);
                    } else {
                      onDeposit(
                        balanceToDepositSlider >= 100
                          ? tokenBalance
                          : new BigNumber(balanceToDeposit)
                              .multipliedBy(
                                new BigNumber(10).exponentiatedBy(
                                  pool.tokenDecimals
                                )
                              )
                              .toString(10)
                      );
                    }
                  }}
                >
                  {" "}
                  <img
                    className="mr-2 fr_def"
                    width="25"
                    height="auto"
                    background-color="#000000"
                    src={depositimg}
                    alt="logo"
                  />
                  {t("Vault-DepositButton")}
                </button>
              </div>
            )}
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
