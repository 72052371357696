import React, { useEffect,  } from 'react';
import {
  Col, Row, NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane
} from 'reactstrap'
import classnames from "classnames";
import Lottie from 'react-lottie';
import animationData from "../../lotties/zapper_confirm4";

export default function ZapPage() {

  const [hTabs, sethTabs] = React.useState(1);

  const toggleTabs = (e, stateName, index) => {
    e.preventDefault();
    switch (stateName) {
      case "hTabs":
        sethTabs(index);
        break;
      default:
    }
  };

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };




  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  return (
    <>


      <div className="page-header content-center for_drk_bg2" id="container">

        <Lottie
          options={defaultOptions}
          height={1100}
          width={1100}
        />
     

{/*         <img style={{ filter: "blur(3px)" }}
          alt="..."
          width="auto"
          height="70%"
          blur
          className="path4 content-center "
          src={require("assets/img/dm4.svg")}
        /> */}



        {/*           <video
            autoPlay="onclick"
            loop="loop"
            muted="muted"
            playsInline="playsinline"
          >
            <source
              src={require("assets/video/zapper.mp4")}
              type="video/mp4"
            />
          </video> */}

        <div className="section section-pills text-center content-center">
   
          <div id="navigation-pills ">
            <div className="title">

              
              <h4>DMAGIC Zapper / Wrapper</h4>

              
            </div>
            <Row className="justify-content-center">
              <Col md="12">
                <br></br>
                <p className="category"></p>
                <Nav className="nav-pills-primary justify-content-center" pills role="tablist" >
                  <NavItem >
                    <NavLink
                      style={{
                        width: '96%',
                        height: '30px',
                        backgroundColor: '#232323',
                        boxShadow: '0 0px 0px 1px rgba(1,245,81,1)',
                        borderRadius: '5px',
                        fontWeight: "550",
                        fontFamily: 'Helvetica',
                        fontSize: '16px',
                        letterSpacing: '0',
                        textAlign: 'center',
                        lineHeight: '12px',
                        marginTop: '12px',
                        marginBottom: '12px',
                      }}



                      className={classnames({
                        active: hTabs === 1,
                      })}
                      onClick={(e) => toggleTabs(e, "hTabs", 1)}
                    >
                      ZAP
                    </NavLink>
                  </NavItem>
                  <div className="space-25"></div>
                    <NavItem>
                    <NavLink
                      style={{
                        width: '96px',
                        height: '30px',
                        backgroundColor: '#232323',
                        boxShadow: '0 0px 0px 1px rgba(1,245,81,1)',
                        borderRadius: '5px',
                        fontWeight: "550",
                        fontFamily: 'Helvetica',
                        fontSize: '16px',
                        letterSpacing: '0',
                        textAlign: 'top',
                        lineHeight: '12px',
                        marginTop: '12px',
                        marginBottom: '12px',
                      }}
                      className={classnames({
                        active: hTabs === 2,
                      })}
                      onClick={(e) => toggleTabs(e, "hTabs", 2)}
                    >
                      WRAP
                    </NavLink>
                  </NavItem>

                </Nav>
                <TabContent className="tab-space" activeTab={"hTabs" + hTabs}>


                  <TabPane tabId="hTabs1"  >
                    <Row>
                      <Col xs={12} md={12}>
                      </Col>
                    </Row>
                    <div className="space-100" />
                    <div className="space-50" />
                  </TabPane>
                  <TabPane tabId="hTabs2">
                    <Row>
                      <Col xs={12} md={12}>
                      </Col>
                    </Row>
                    <div className="space-100" />
                    <div className="space-50" />
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
            {/* end nav pills */}
          </div>


        </div>
      </div>

      <div className="space-100" />
      <div className="space-100" />
      <div className="space-100" />



    </>
  );
}