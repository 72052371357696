import React from 'react'
import { Grid, Box, Typography, Button } from '@material-ui/core';
import { useConnectWallet } from '../../home/redux/hooks';
import { useFetchPoolBalances } from '../redux/hooks';
import PoolContent from './PoolContent';

export default function EziouScOne() {

    const { networkId } = useConnectWallet();
    let { ezioupools } = useFetchPoolBalances();


    return (
        <>
            <div >
            <Grid container spacing={5}>
{/*             {ezioupools.filter(ezioupool => (ezioupool.id === '1')).map((ezioupool, index) => (
                                        <PoolContentone key={index} ezioupool={ezioupool} />
                                    ))} */}
                {ezioupools.filter(ezioupool => (ezioupool.chainId === networkId)).map((ezioupool, index, text) => (
                                        <PoolContent key={index} ezioupool={ezioupool} />
                                    ))}
            </Grid>
            </div>
        </>
    )
}

