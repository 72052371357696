import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  EZIOU_FETCH_POOL_BALANCES_BEGIN,
  EZIOU_FETCH_POOL_BALANCES_SUCCESS,
  EZIOU_FETCH_POOL_BALANCES_FAILURE,
} from './constants';

export function useFetchPoolBalances() {
  // args: false value or array
  // if array, means args passed to the action creator

  const { ezioupools, fetchPoolBalancesPending } = useSelector(
    state => ({
      ezioupools: state.mts.ezioupools,
      fetchPoolBalancesPending: state.mts.fetchPoolBalancesPending,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (data) => {
    },
    [],
  );

  return {
    ezioupools,
    fetchPoolBalances: boundAction,
    fetchPoolBalancesPending
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case EZIOU_FETCH_POOL_BALANCES_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchPoolBalancesPending: true,
      };

    case EZIOU_FETCH_POOL_BALANCES_SUCCESS:
      // The request is success
      return {
        ...state,
        ezioupools: action.data,
        fetchPoolBalancesPending: false,
      };

    case EZIOU_FETCH_POOL_BALANCES_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchPoolBalancesPending: false,
      };

    default:
      return state;
  }
}