import { stakingpoolsv2 } from "../../configure";

const tokens = {
  137: {},
};

stakingpoolsv2.map(({chainId, token, tokenAddress, earnedToken, claimedTokenAddress})=> {
  tokens[chainId][token] = {
    tokenAddress: tokenAddress,
    tokenBalance: 0
  }
  tokens[chainId][earnedToken] = {
    tokenAddress: claimedTokenAddress,
    tokenBalance: 0
  }
  return '';
})

const initialState = {
  stakingpoolsv2,
  tokens,
  fetchPoolBalancesPending: false,

};

export default initialState;