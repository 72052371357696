import { AxMatic, axMaticDirectABI, axMaticLiq, dragonDirect, sushirouter  } from "../../configure";

const AxMaticAddress =       '0x1221591c1d77A9c334aBb0fe530ae6EE3aF51Af9'  // Matic <-> axMatic           checked 2
const axMaticDirectAddress = '0x7f8D518e8204702255e539236c90E9104292e8d7'  //  Matic <-> dMagic           checked 2
const dragonDirectAddress =  '0x5a86C816dB23D5C6bcC7d051A8877Bc63CFC650C'  // Matic -> Dragon'            checked 2
const DRAXliqContract =      '0xEEf10C9Bf17c9d2C9619fd29447B231EA0Fde548'  //                             checked 2 +const routerAddress =        '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff'  // sushirouter
const routerAddress =        '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff'  // sushirouter

export const allowance = [
  {   // Confirmed Working 
    name: 'MATIC',
    address: '0x0000000000000000000000000000000000001010', //  checked
    pools: [{
      name: 'AxMaticAddress' ,
      address: AxMaticAddress, 
      allowance: 1
    },
    {
      name: 'axMaticDirectAddress' ,
      address: axMaticDirectAddress, 
      allowance: 1
    },
    {
      name: 'dragonDirectAddress' ,
      address: dragonDirectAddress, 
      allowance: 1
    }]
  },
  {  
    name: 'DMAGIC',
    address: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',  //  checked
    pools: [{
      name: 'axMaticDirectAddress',
      address: axMaticDirectAddress, 
      allowance: 0
    },
    {
      name: 'dragonDirectAddress',
       address: dragonDirectAddress, 
       allowance: 0
    },
    { 
      name: 'routerAddress' ,
      address: routerAddress, 
      allowance: 0,
  },
  ]
  },
  {   
    name: 'AXMATIC',
    address: '0x1221591c1d77A9c334aBb0fe530ae6EE3aF51Af9',  //   checked
    pools: [
      {
        name: 'dragonDirectAddress',
        address: dragonDirectAddress, 
        allowance: 0
      },
      {
      name: 'AxMaticAddress',
      address: AxMaticAddress, 
      allowance: 0
    },
      {
      name: 'axMaticDirectAddress',
      address: axMaticDirectAddress, 
      allowance: 0
    }
  ]
  },

  {   
    name: 'AXMATIC-DMAGIC-LP',
    address: '0xfd2e00312d07207b8f1b65a5bf6df5c9f196a132',   // checked
    pools: [{
      name: 'DRAXliqContract' ,
      address: DRAXliqContract, 
      allowance: 0
    }
    ]
  },

  {   
    name: 'NIGHTBANE',
    address: '0xEEf10C9Bf17c9d2C9619fd29447B231EA0Fde548',   // checked   
    pools: [{
      name: 'dragonDirectAddress' ,
      address: dragonDirectAddress, 
      allowance: 0
    }]
  },  
  {   
    name: 'AXIOMS',
    address: '0x01fa5b3a5d77bcf705dd505bbcbb34bce310e7fe',   // checked   
    pools: [{
      name: 'routerAddress' ,
      address: routerAddress, 
      allowance: 0,
    }]
  },



]

export const tokens = [

  {  // Working !! 
    name: 'MATIC',
    description: 'MATIC',
    address: '0x0000000000000000000000000000000000001010', //  checked
    decimals: 18,
    balance: 0,
    pricePerFullShare: '0',
    receivableList:[
      {
        name: 'DMAGIC',
        type: 'zap',
        contract: {
          name: 'axMaticDirectAddress',
          address: axMaticDirectAddress,
          abi: axMaticDirectABI,
          call: 'easyBuy',
          call2: 'easyBuy2'
       }
      },
        {
          name: 'AXMATIC',
          type: 'zap',
          contract: {
           name: 'AxMaticAddress',
           address: AxMaticAddress,
           abi: AxMatic,
          call: 'deposit',
          call2: 'deposit'
          }
      },
      {
        name: 'NIGHTBANE',
        type: 'zap',
        contract: {
          name: 'dragonDirectAddress',
          address: dragonDirectAddress,
          abi: dragonDirect,
          call: 'easyBuy',
          call2: 'easyBuy2'
        },
    }
    ]
  },

  {  
    name: 'DMAGIC',
    description: 'DMAGIC',
    address: '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',  //  checked
    decimals: 18,
    balance: 0,
    pricePerFullShare: '0',
    receivableList:[
      {
        name: 'MATIC',
        type: 'zap',
        contract: {
          name: 'axMaticDirectAddress',
          address: axMaticDirectAddress,
          abi: axMaticDirectABI,
          call: 'easySell',
          call2: 'easySell3'
        }
      },
      {
        name: 'NIGHTBANE ',
        type: 'zap',
        contract: {
          address2: '',  // 
          name: 'dragonDirectAddress',
          address: dragonDirectAddress,
          abi: dragonDirect,
          call: 'easyBuyFromDmagic',
          call2: 'easySell3'
        },
      },
      {
        name: 'AXMATIC ',
        type: 'zap',
        contract: {
          address2: '',  // 
          name: 'axMaticDirectAddress',
          address: axMaticDirectAddress,
          abi: axMaticDirectABI,
          call: 'easySellToAxMatic',
          call2: 'easySell3'
        },
      },

      {
        name: 'AXIOMS',
        type: 'zap',
        contract: {
          address2: '0x01fa5b3a5d77bcf705dd505bbcbb34bce310e7fe',  // 
          name: 'routerAddress',
          address: routerAddress,
          abi: sushirouter,
          call: 'swapExactTokensForTokensSupportingFeeOnTransferTokens',
          call2: 'std',
          router: routerAddress,
        },
      },
    ]
  },
  {   
    name: 'AXMATIC',
    description: 'AXMATIC',
    address: '0x1221591c1d77A9c334aBb0fe530ae6EE3aF51Af9', //   checked
    decimals: 18,
    balance: 0,
    pricePerFullShare: '0',
    receivableList:[
      {
        name: 'NIGHTBANE  ',
        type: 'zap',
        contract: {
          address2: '',  // 
          name: 'dragonDirectAddress',
          address: dragonDirectAddress,
          abi: dragonDirect,
          call: 'easyBuyFromAxMatic',
          call2: 'easySell3'
        }
      },
      {
        name: 'MATIC ',
        type: 'zap',
        contract: {
          name: 'AxMaticAddress',
          address: AxMaticAddress,
          abi: AxMatic,
          call: 'withdraw',
          call2: 'easySell3'
        }
      },
      {
        name: 'DMAGIC ',
        type: 'zap',
        contract: {
          name: 'axMaticDirectAddress',
          address: axMaticDirectAddress,
          abi: axMaticDirectABI,
          call: 'easyBuyFromAxMatic',
          call2: 'easySell3'
        }
      },

    ]
  },
  {   
    name: 'AXMATIC-DMAGIC-LP',
    description: 'AXMATIC-DMAGIC-LP',
    address: '0xfd2e00312d07207b8f1b65a5bf6df5c9f196a132',  //  checked 2
    address2: '0xfd2e00312d07207b8f1b65a5bf6df5c9f196a132',  //  checked 2
    decimals: 18,
    balance: 0,
    pricePerFullShare: '0',
    receivableList:[
      {
        name: 'NIGHTBANE   ',
        type: 'zap',
        contract: {
          address2: '0xfd2e00312d07207b8f1b65a5bf6df5c9f196a132',  // checked 2
          name: 'DRAXliqContract',
          address: DRAXliqContract,
          abi: axMaticLiq,
          call: 'depositTokens',
          call2: 'depositTokens'
        },
      }
    ]
  },

  {   
    name: 'NIGHTBANE',
    description: 'NIGHTBANE',
    address: '0xEEf10C9Bf17c9d2C9619fd29447B231EA0Fde548', //  checked 2
    decimals: 18,
    balance: 0,
    pricePerFullShare: '0',
    receivableList:[
      {
        name: 'MATIC   ',
        type: 'zap',
        contract: {
          name: 'dragonDirectAddress',
          address: dragonDirectAddress,
          abi: dragonDirect,
          call: 'easySellToMatic',
          call2: 'easySell3'
        }
      },
      {
        name: 'AXMATIC  ',
        type: 'zap',
        contract: {
         name: 'dragonDirectAddress',
         address: dragonDirectAddress,
         abi: dragonDirect,
        call: 'easySellToAxMatic',
        call2: 'easySell3'
        }
    },
    {
      name: 'DMAGIC  ',
      type: 'zap',
      contract: {
       name: 'dragonDirectAddress',
       address: dragonDirectAddress,
       abi: dragonDirect,
      call: 'easySellTodMagic',
      call2: 'easySell3'
      }
  }
]
},

{   
  name: 'AXIOMS',
  description: 'AXIOMS',
  address: '0x01fa5b3a5d77bcf705dd505bbcbb34bce310e7fe', //  checked 2
  decimals: 18,
  balance: 0,
  pricePerFullShare: '0',
  receivableList:[
    {
      name: 'DMAGIC   ',
      type: 'zap',
      contract: {
        address2: '0x01fa5b3a5d77bcf705dd505bbcbb34bce310e7fe',  // 
        name: 'routerAddress',
        address: routerAddress,
        abi: sushirouter,
        call: 'swapExactTokensForTokensSupportingFeeOnTransferTokens',
        call2: 'std2',
        router: routerAddress,
      }
    },


    ]
  },


]

const initialState = {
  tokens,
  allowance,
  fetchBalancesPending: false,
  checkApprovalPending: false,
  fetchApprovalPending: false,
  fetchZapOrSwapPending: false
};

export default initialState;