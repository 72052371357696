import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import history from './history';
import homeReducer from 'features/home/redux/reducer';
//import vaultReducer from 'features/vault/redux/reducer';
import stakeReducer from 'features/stake/redux/reducer';
import stakingReducer from 'features/staking/redux/reducer';
import stakingv2Reducer from 'features/stakingv2/redux/reducer';
import swapReducer from 'features/swap/redux/reducer';
import nftReducer from 'features/nft/redux/reducer';
import swapsuReducer from 'features/swapsu/redux/reducer';
import thecauldronReducer from 'features/thecauldron/redux/reducer';
import mtsReducer from 'features/mts/redux/reducer';
import commonReducer from 'features/common/redux/reducer';

const reducerMap = {
  router: connectRouter(history),
  home: homeReducer,
  //vault: vaultReducer,
  stake: stakeReducer,
  staking: stakingReducer,
  stakingv2: stakingv2Reducer,
  swap: swapReducer,
  swapsu: swapsuReducer,
  nft: nftReducer,
  mts: mtsReducer,
  thecauldron: thecauldronReducer,

  common: commonReducer,
  
};

export default combineReducers(reducerMap);
