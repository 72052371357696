/* eslint-disable */
import React, { useState } from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { inputLimitPass, inputFinalVal, isEmpty } from "features/helpers/utils";
import BigNumber from "bignumber.js";
import { byDecimals } from "features/helpers/bignumber";
import FormControl from "@material-ui/core/FormControl";
import {
  Button,
} from "reactstrap";
import { Link } from 'react-router-dom';

import {
  useAllowance,
  useApprove,
  useApprove1,
  useApprove2,
  useApprove3,
  useApprove4,
  useDeposit,
  useWithdraw,
  useFetchGetReward,
  useTime1,
  useBalanceOf,
  useBoost1,
  useBoost2,
  useBoost3,
  useBoost4,
  useOwner,
  useOwner2,
  useOwner3

} from "../redux/hooks";
import Slider from "@material-ui/core/Slider";
import withdrawimg from "../../../assets/img/withdraw.svg";
import claimimg from "../../../assets/img/claim.svg";
import depositimg from "../../../assets/img/deposit.svg";
import Countdown from "react-countdown";

import Moment from "react-moment";
import "moment-timezone";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import eggblueimg from "../../../assets/img/Dragon_Egg_Blue_Cells.png";
import eggblueimg02 from "../../../assets/img/Dragon_Egg_Blue_Cells02.png";
import eggblueimg03 from "../../../assets/img/Dragon_Egg_Blue_Cells03.png";
import eggblueimg04 from "../../../assets/img/polygon_ic.png";

Moment.globalLocal = true;

const forMat = (number) => {
  return new BigNumber(number)
    .multipliedBy(new BigNumber(10000))
    .dividedToIntegerBy(new BigNumber(1))
    .dividedBy(new BigNumber(10000))
    .toNumber();
};

export default function PoolContent({ index, pool}) {
  const { t, i18n } = useTranslation();

  const tokenBalance = useBalanceOf(pool.tokenAddress);
  let balanceSingle = byDecimals(tokenBalance, pool.tokenDecimals);
  const depositedBalance = useBalanceOf(pool.earnContractAddress);
  let singleDepositedBalance = byDecimals(
    depositedBalance,
    pool.itokenDecimals
  );
  const allowance = useAllowance(pool.tokenAddress, pool.earnContractAddress);
  const networkId = pool.chainId;

  const owner11 = useOwner(pool.earnContractAddress);
  const owner22 = useOwner2(pool.earnContractAddress);
  const owner33 = useOwner3(pool.earnContractAddress);


  const { isPending: isApprovePending1, onApprove1 } = useApprove1(
    pool.tokenAddress1,
    pool.earnContractAddress
  );

  const { isPending: isApprovePending2, onApprove2 } = useApprove2(
    pool.tokenAddress2,
    pool.earnContractAddress
  );

  const { isPending: isApprovePending3, onApprove3 } = useApprove3(
    pool.tokenAddress3,
    pool.earnContractAddress
  );
  const { isPending: isApprovePending4, onApprove4 } = useApprove4(
    pool.tokenAddress4,
    pool.earnContractAddress
  );

  const { isPending: isApprovePending, onApprove } = useApprove(
    pool.tokenAddress,
    pool.earnContractAddress
  );
  const { onGetReward, isPending: isGetRewardPending } = useFetchGetReward(
    pool.earnContractAddress
  );
  const { onDeposit, isPending: isDepositPending } = useDeposit(
    pool.earnContractAddress,
    pool.tokenAddress
  );
  const { onWithdraw, isPending: isWithdarwPending } = useWithdraw(
    pool.earnContractAddress,
    pool.tokenAddress
  );
  const time1 = useTime1(pool.earnContractAddress);

  const [balanceToDeposit, setBalanceToDeposit] = useState("0");
  const [balanceToDepositSlider, setBalanceToDepositSlider] = useState(0);
  const [balanceToWithdraw, setBalanceToWithdraw] = useState("0");
  const [balanceToWithdrawSlider, setBalanceToWithdrawSlider] = useState(0);

  const changeDetailInputValue = (type, index, total, tokenDecimals, event) => {
    let value = event.target.value;
    if (!inputLimitPass(value, tokenDecimals)) {
      return;
    }
    let sliderNum = 0;
    let inputVal = Number(value.replace(",", ""));
    if (value) {
      sliderNum = byDecimals(inputVal / total, 0).toFormat(2) * 100;
    }
    switch (type) {
      case "depositedBalance":
        setBalanceToDeposit(inputFinalVal(value, total, tokenDecimals));
        setBalanceToDepositSlider(sliderNum);
        break;
      case "withdrawAmount":
        setBalanceToWithdraw(inputFinalVal(value, total, tokenDecimals));
        setBalanceToWithdrawSlider(sliderNum);
      default:
        break;
    }
  };

  const handleDepositedBalance = (
    index,
    total,
    tokenDecimals,
    event,
    sliderNum
  ) => {
    const val =
      sliderNum == 0
        ? "0"
        : new BigNumber(total).times(sliderNum).div(100).toFixed(0);
    setBalanceToDeposit(byDecimals(val, tokenDecimals));
    setBalanceToDepositSlider(sliderNum);
  };
  const handleWithdrawAmount = (
    index,
    total,
    tokenDecimals,
    event,
    sliderNum
  ) => {
    const val =
      sliderNum == 0
        ? "0"
        : new BigNumber(total).times(sliderNum).div(100).toFixed(0);
    setBalanceToWithdraw(byDecimals(val, tokenDecimals));
    setBalanceToWithdrawSlider(sliderNum);
  };

  const marks = [
    {
      value: 0,
      label: "0%",
    },
    {
      value: 25,
      label: "25%",
    },
    {
      value: 50,
      label: "50%",
    },
    {
      value: 75,
      label: "75%",
    },
    {
      value: 100,
      label: "100%",
    },
  ];
  function valuetext(value) {
    return `${value}%`;
  }
  ///withdraw slider
  const withdraw = [
    {
      value: 0,
      label: "0%",
    },
    {
      value: 25,
      label: "25%",
    },
    {
      value: 50,
      label: "50%",
    },
    {
      value: 75,
      label: "75%",
    },
    {
      value: 100,
      label: "100%",
    },
  ];
  function withdrawvaluetext(value) {
    return `${value}%`;
  }
  ///Aprove Modal
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [open2, setOpen2] = useState(false);

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };



  const { onBoost1, isPending: isBoost1Pending } = useBoost1(
    pool.earnContractAddress,
    pool.tokenAddress1
  );

  const { onBoost2, isPending: isBoost2Pending } = useBoost2(
    pool.earnContractAddress,
    pool.tokenAddress2
  );

  const { onBoost3, isPending: isBoost3Pending } = useBoost3(
    pool.earnContractAddress,
    pool.tokenAddress3
  );
  const { onBoost4, isPending: isBoost4Pending } = useBoost4(
    pool.earnContractAddress,
    pool.tokenAddress4
  );

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        className="head_prnt"
        spacing={5}
      >
        <Grid item lg={6}>
          <h6 className="dpst_ttl">
            Deposit{" "}
            <span>
              {t("Vault-Balance")}: <b> {forMat(balanceSingle)} </b> {pool.token}
            </span>
          </h6>

          <Grid item lg={12} className="cstmaccrdn2">
            <div className="d-flex input_bx">
              <FormControl>
                <input
                  className="input_fld"
                  value={balanceToDeposit}
                  onChange={changeDetailInputValue.bind(
                    this,
                    "depositedBalance",
                    index,
                    balanceSingle,
                    pool.tokenDecimals
                  )}
                />
              </FormControl>
            </div>

            <div className="progress_bx">
              <Slider
                getAriaValueText={valuetext}
                aria-labelledby="discrete-slider-custom"
                step={1}
                valueLabelDisplay="auto"
                marks={marks}
                value={balanceToDepositSlider}
                onChange={handleDepositedBalance.bind(
                  this,
                  index,
                  tokenBalance,
                  pool.tokenDecimals
                )}
              />
            </div>
          </Grid>



          <div className="progress_action text-right mt-3">
   
          <Button
                  className="btn btn-stk btn_stk_wtgrdnt2">
                  <Link className="nav-link" to="/zap">
                   <span>
                  get {pool.token}
                    </span></Link>
                </Button>{" "}
           
              {allowance === "0" ? (
                <button
                  className="btn btn-stk btn_stk_wtgrdnt"
                  onClick={() => {
                    handleClose();
                    onApprove();
                  }}
                  disabled={isApprovePending[index]}
                >
                   <span>
                  <img
                    className="mr-2 fr_def"
                    width="25"
                    height="auto"
                    background-color="#000000"
                    src={depositimg}
                    alt="logo"
                  />
                  {isApprovePending
                    ? `${t("Vault-ApproveING")}`
                    : `${t("Vault-ApproveButton")}`}
                    </span>
                </button>
           
            ) : (
       
                <button
                  className="btn btn-stk"
                  onFocus={(event) => event.stopPropagation()}
                  disabled={isDepositPending}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleClose();
                    if (!isEmpty(pool.depostAlert)) {
                      setWaitDialogConfirmJson({
                        content: pool.depostAlert,
                        func: () => {
                          onDeposit(pool, index, false, balanceSingle, event);
                        },
                      });
                      setOpenDialog(true);
                    } else {
                      onDeposit(
                        balanceToDepositSlider >= 100
                          ? tokenBalance
                          : new BigNumber(balanceToDeposit)
                            .multipliedBy(
                              new BigNumber(10).exponentiatedBy(
                                pool.tokenDecimals
                              )
                            )
                            .toString(10)
                      );
                    }
                  }}
                >
                  {" "}
                  <img
                    className="mr-2 fr_def"
                    width="25"
                    height="auto"
                    background-color="#000000"
                    src={depositimg}
                    alt="logo"
                  />
                  {t("Vault-DepositButton")}
                </button>
                    )}
          
        
          </div>
        </Grid>
        <Grid item lg={6}>
{/*           <div className="othrinfo">
            <span>
              Pool Timelock:
              <br />{pool.timelock} Day
            </span>
            <span>
              Timelock Remaining:
              <br />
              {time1 && <Countdown date={time1 * 1000} />}
            </span>
            <span>
              Penalty Fee:
              <br />
              {pool.penalty}
            </span>
          </div> */}
          <h6 className="dpst_ttl">
            Withdraw{" "}
            <span>
              <b>{forMat(singleDepositedBalance)} </b> {pool.token}
            </span>
          </h6>
          <Grid item lg={12} className="cstmaccrdn2">
            <div className="d-flex input_bx">
              <FormControl fullWidth variant="outlined">
                <input
                  className="input_fld"
                  type="text"
                  value={balanceToWithdraw}
                  onChange={changeDetailInputValue.bind(
                    this,
                    "withdrawAmount",
                    index,
                    singleDepositedBalance,
                    pool.itokenDecimals
                  )}
                />
              </FormControl>
            </div>
            <div className="progress_bx progress_bx_rd">
              <Slider
                value={balanceToWithdrawSlider}
                onChange={handleWithdrawAmount.bind(
                  this,
                  index,
                  depositedBalance,
                  pool.tokenDecimals
                )}
                getAriaValueText={withdrawvaluetext}
                aria-labelledby="discrete-slider-custom"
                step={1}
                valueLabelDisplay="auto"
                marks={withdraw}
              />
            </div>
          </Grid>
            <div className="progress_action text-right mt-3">
              <button
                className="btn btn-stk btn-wthdrw"
                disabled={isWithdarwPending}
                onClick={() =>
                  onWithdraw(
                    balanceToWithdrawSlider >= 100
                      ? depositedBalance
                      : new BigNumber(balanceToWithdraw)
                        .multipliedBy(
                          new BigNumber(10).exponentiatedBy(
                            pool.tokenDecimals
                          )
                        )
                        .toString(10)
                  )
                }
              >
                {" "}
                <img
                  className="mr-2 fr_def"
                  width="25"
                  height="auto"
                  background-color="#000000"
                  src={withdrawimg}
                  alt="logo"
                />
                {isWithdarwPending
                  ? `${t("Vault-WithdrawING")}`
                  : `${t("Vault-WithdrawButton")}`}
              </button>{" "}
              <button
                className="btn btn-stk btn-wthdrw"
                onClick={() => onGetReward()}
              >
                <img
                  className="mr-2 fr_def"
                  width="25"
                  height="auto"
                  background-color="#000000"
                  src={claimimg}
                  alt="logo"
                />
                {isGetRewardPending
                  ? `${t("Vault-ClaimING")}`
                  : `${t("Vault-ClaimButton")}`}
              </button>
            </div>
        </Grid>
        <Grid item lg={3} className="rsp_align_right">
        <Box className="checkcircle"         
        onClick={() => {
              onApprove1(owner11);
            }}>
            <label className="lblgdnt">
              Approve
            </label>
          </Box>
          <Box className="checkcircle checkcircle_v2"  onClick={() => {
              onBoost1(owner11);
            }}>
            <label>
              <img
                width="20"
                height="auto"
                alt=""
                src={depositimg}
                className="hvrdwt_img mt-0 ml-1"
              />
              <span>{pool.boost1} Boost with BOAM</span>
              <img
                width="28"
                height="auto"
                src={eggblueimg}
                alt=""
                className="mt-0 mx-1"
              />

            </label>
          </Box>
          </Grid>
        <Grid item lg={3} className="text-center">
          <Box className="checkcircle"  
           onClick={() => {
            onApprove2(owner22);
          }}>
            <label className="lblgdnt">
              Approve
            </label>
          </Box>
          <Box className="checkcircle checkcircle_v2"
          onClick={(event) => {
            onBoost2(owner22)
          }}>
            <input type="checkbox" />
            <label>
              <img
                width="20"
                height="auto"
                src={depositimg}
                alt=""
                className="hvrdwt_img mt-0 ml-1"
              />
              <span>{pool.boost2} Boost with BOSA</span>
              <img
                width="28"
                height="auto"
                src={eggblueimg02}
                alt=""
                className="mt-0 mx-1"
              />
            </label>
          </Box>
        </Grid>
        <Grid item lg={3} className="text-center">
          <Box className="checkcircle" 
          onClick={() => {
              onApprove3(owner33);
            }}>
            <label className="lblgdnt">
              Approve
            </label>
          </Box>
          <Box className="checkcircle checkcircle_v2" 
          onClick={(event) => {
            onBoost3(owner33)
          }}>
            <label>
              <img
                width="20"
                height="auto"
                src={depositimg}
                alt=""
                className="hvrdwt_img mt-0 ml-1"
              />
              <span>{pool.boost3} Boost with BOEM</span>
              <img
                width="28"
                height="auto"
                src={eggblueimg03}
                alt=""
                className="mt-0 mx-1"
              />
            </label>
          </Box>
        </Grid>
        { pool.classV === "1" ? (
        <Grid item lg={3} className="text-right rsp_align_right">
      
          <Box className="checkcircle" 
           onClick={() => {
            onApprove4();
          }}>
            <label className="lblgdnt">
              Approve
            </label>
          </Box>
          <Box className="checkcircle checkcircle_v2"
           onClick={(event) => {
            onBoost4('800000000000000000000');
          }}
          >
            <label>
              <img
                width="20"
                height="auto"
                src={depositimg}
                alt=""
                className="hvrdwt_img mt-0 ml-1"
              />
              <span>30% Boost 800 dMAGIC</span>
              <img
                width="28"
                height="auto"
                src={eggblueimg04}
                alt=""
                className="mt-0 mx-1"
              />

            </label>
          
          </Box>
       
        </Grid>



) : (
         
  <Grid item lg={3} className="text-right rsp_align_right">
      
  <Box className="checkcircle" 
   onClick={() => {
    onApprove4();
  }}>
    <label className="lblgdnt">
      Approve
    </label>
  </Box>
  <Box className="checkcircle checkcircle_v2"
   onClick={(event) => {
    onBoost4('300000000000000000000');
  }}
  >
    <label>
      <img
        width="20"
        height="auto"
        src={depositimg}
        alt=""
        className="hvrdwt_img mt-0 ml-1"
      />
      <span>30% Boost 300 dMAGIC</span>
      <img
        width="28"
        height="auto"
        src={eggblueimg04}
        alt=""
        className="mt-0 mx-1"
      />

    </label>
  
  </Box>

</Grid>)}


   
        <Grid item lg={12} className="cstm_txt_lft oth_sc text-center pb-0">
          <Typography component="p">Pool Startdate: {pool.startdate} - Duration: {pool.duration} - Pool Fee: { pool.penalty }</Typography>
        </Grid>

        <Grid item lg={12} className="cstm_txt_lft oth_sc text-center pb-0">
          <Typography component="p">10% equals 0.1x on Multiplier. Maxium multiplier: {pool.multip}x</Typography>
        </Grid>

        <Grid item lg={12} className="cstm_txt_lft oth_sc text-center pt-1 pb-0">
         <span className="greentxt">Boosts are spent into the DeFi Magic ecosystem and are not refundable.</span>
        </Grid>
      </Grid>


      {/* Boost Modal*/}
    {/*   <Dialog
        open={open2}
        onClose={handleClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="aprvmdl"
      >
        <DialogTitle id="alert-dialog-title">{"dMagic Boost"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">

          <Grid item lg={12} className="text-center">

          <Box className="checkcircle checkcircle_v2" 
            onFocus={(event) => event.stopPropagation()}
            onClick={(event) => {
              onBoost4('100000000000000000000');
            }}
            >
            <input type="checkbox" />
            <label>
              <span>10% Boost 100 dMAGIC</span>
              <img
                width="28"
                height="auto"
                src={eggblueimg04}
                alt=""
                className="mt-0 mx-1"
              />

            </label>
          </Box>

          <Box className="checkcircle checkcircle_v2" 
            onFocus={(event) => event.stopPropagation()}
            onClick={(event) => {
              onBoost4('225000000000000000000');
            }}
            >
            <input type="checkbox" />
            <label>
              <span>20% Boost 225 dMAGIC</span>
              <img
                width="28"
                height="auto"
                src={eggblueimg04}
                alt=""
                className="mt-0 mx-1"
              />

            </label>
          </Box>

          <Box className="checkcircle checkcircle_v2"
           onFocus={(event) => event.stopPropagation()}
           onClick={(event) => {
             onBoost4('300000000000000000000');
           }}
           >
            <input type="checkbox" />
            <label>
              <span>30% Boost 300 dMAGIC</span>
              <img
                width="28"
                height="auto"
                src={eggblueimg04}
                alt=""
                className="mt-0 mx-1"
              />

            </label>
          </Box>

          <br></br>

      </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog> */}

      {/* Aprove Modal */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="aprvmdl"
      >
        <DialogTitle id="alert-dialog-title">{"Note"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <li>Depositing will reset the Timelock Countdown.</li>
            <li>Withdrawing before Timelock expiration incurs a {pool.penalty} {' '} fee.</li>
                    <br></br>
                <a className="nav-link" tag="a" rel="noopener noreferrer" target="_blank" href="https://t.me/PIProtocol">
                  <img
                    width="45"
                    height="auto"
                    background-color="#000000"
                    alt="..."
                    className="fr_def"
                    src={require("assets/img/PIP.png")}
                  />
                </a>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className="progress_action d-flex w-100 mt-3 justify-content-center">
            {allowance !== "0" && (
              <div className="progress_action text-right mt-3">
                <button
                  className="btn btn-stk"
                  onFocus={(event) => event.stopPropagation()}
                  disabled={isDepositPending}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleClose();
                    if (!isEmpty(pool.depostAlert)) {
                      setWaitDialogConfirmJson({
                        content: pool.depostAlert,
                        func: () => {
                          onDeposit(pool, index, false, balanceSingle, event);
                        },
                      });
                      setOpenDialog(true);
                    } else {
                      onDeposit(
                        balanceToDepositSlider >= 100
                          ? tokenBalance
                          : new BigNumber(balanceToDeposit)
                            .multipliedBy(
                              new BigNumber(10).exponentiatedBy(
                                pool.tokenDecimals
                              )
                            )
                            .toString(10)
                      );
                    }
                  }}
                >
                  {" "}
                  <img
                    className="mr-2 fr_def"
                    width="25"
                    height="auto"
                    background-color="#000000"
                    src={depositimg}
                    alt="logo"
                  />
                  {t("Vault-DepositButton")}
                </button>
              </div>
            )}
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}