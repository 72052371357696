import React from "react";
import { Link } from 'react-router-dom';
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardHeader,
  CardFooter,
  ListGroupItem,
  ListGroup,
} from "reactstrap";

export default function Tokenomics() {

  return (
    <>
        <section className="section_lst section section-lg section-coins mt-0 pt-0 pb-0">
          <Container className="large_c">
            <div className="ltsgrdnt">
              <div className="ltsgrdnt_inn">
                <Row className="align-items-end">
                  <Col md="6" className="lstpdng_lft">
                    <h1>
                      Magic :
                      <span className="text-info"> Tokenomics</span>
                    </h1>
                    <p className="def_p"><span className="greentxt txtitlc">Defi Magic</span> is a burning utility token ecosystem. An ever-expanding product-based platform with value based on use-case and functionality.  </p>
                  </Col>
                  <Col md="6" className="text-center pd-90 lstpdng_rgt">
                    <img
                        alt="..."
                        className="img-center img-fluid img_mns_mrgn"
                        src={require("assets/img/mgk_ls_img.png")}
                      />
                  </Col>
                </Row>
                <Row className="pd_30">
                <Col md="6" lg="3">
                    <Card className="card-coin card-plain crd_c_bl">
                      <CardHeader>
                        <img
                          alt="..."
                          className="img-center img-fluid"
                          src={require("assets/img/lst_img_01.png")}
                        />
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col className="text-center" md="12">
                            <h4 className="text-uppercase">Dark Magic</h4>
                            <hr className="line-success" />
                            <label><span>dMagic</span>0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7</label>
                          </Col>
                        </Row>
                        <Row>
                          <ListGroup>
                            <ListGroupItem>
                              <span className="clrgr">Total Supply:</span> 
                              <br />
                              4,000,000
                            </ListGroupItem>
                            <ListGroupItem>
                              <span className="clrgr">Tx Fee</span> 1.5%
                            </ListGroupItem>
                            <ListGroupItem>
                              <span className="clrgr">
                                <b>1% feeds staking contracts</b>
                                <b>0.2% treasury fund</b>
                                <b>0.3% burns</b>
                              </span>
                            </ListGroupItem>
                          </ListGroup>
                        </Row>
                      </CardBody>
                      <CardFooter className="text-center">
                        <Button className="btn-simple" color="success" tag="a" >
                        <Link to="/zap">
                         Get dMagic
                          </Link>
                        </Button>
                      </CardFooter>
                    </Card>
                  </Col>
                  <Col md="6" lg="3">
                    <Card className="card-coin card-plain crd_c_bl">
                      <CardHeader>
                        <img
                          alt="..."
                          className="img-center img-fluid"
                          src={require("assets/img/lst_img_02.png")}
                        />
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col className="text-center" md="12">
                            <h4 className="text-uppercase">Drax</h4>
                            <hr className="line-success" />
                            <label><span>Drax</span>0x1Ba3510A9ceEb72E5CdBa8bcdDe9647E1f20fB4b</label>
                          </Col>
                        </Row>
                        <Row>
                          <ListGroup>
                            <ListGroupItem>
                              <span className="clrgr">Total Supply:</span> 
                              <br />
                              SLP WRAPPER
                            </ListGroupItem>
                            <ListGroupItem>
                              <span className="clrgr">Tx Fee</span> 0.5%
                            </ListGroupItem>
                            <ListGroupItem>
                              <span className="clrgr">
                                <b>0.4% feeds staking contracts</b>
                                <b>0.1% treasury fund</b>
                              </span>
                            </ListGroupItem>
                          </ListGroup>
                        </Row>
                      </CardBody>
                      <CardFooter className="text-center">
                        <Button className="btn-simple" color="success" tag="a" >
                        <Link to="/zap">
                         Get Drax
                          </Link>
                        </Button>
                      </CardFooter>
                    </Card>
                  </Col>
                  <Col md="6" lg="3">
                    <Card className="card-coin card-plain crd_c_bl">
                      <CardHeader>
                        <img
                          alt="..."
                          className="img-center img-fluid"
                          src={require("assets/img/lst_img_03.png")}
                        />
                      </CardHeader>
                      <CardBody>
                      <Row>
                          <Col className="text-center" md="12">
                            <h4 className="text-uppercase">Nightbane</h4>
                            <hr className="line-success" />
                            <label><span>Night</span>0xEEf10C9Bf17c9d2C9619fd29447B231EA0Fde548</label>
                          </Col>
                        </Row>
                        <Row>
                          <ListGroup>
                            <ListGroupItem>
                              <span className="clrgr">Total Supply:</span> 
                              <br />
                              SLP WRAPPER
                            </ListGroupItem>
                            <ListGroupItem>
                              <span className="clrgr">Tx Fee</span> 1%
                            </ListGroupItem>
                            <ListGroupItem>
                              <span className="clrgr">
                                <b>0.4% feeds staking contracts</b>
                                <b>0.1% treasury fund</b>
                              </span>
                            </ListGroupItem>
                          </ListGroup>
                        </Row>
                      </CardBody>
                      <CardFooter className="text-center">
                        <Button className="btn-simple" color="success" tag="a" >
                        <Link to="/zap">
                         Get Night
                          </Link>
                        </Button>
                      </CardFooter>
                    </Card>
                  </Col>
                  <Col md="6" lg="3">
                    <Card className="card-coin card-plain crd_c_bl">
                      <CardHeader>
                        <img
                          alt="..."
                          className="img-center img-fluid"
                          src={require("assets/img/lst_img_04.png")}
                        />
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col className="text-center" md="12">
                            <h4 className="text-uppercase">AxMatic</h4>
                            <hr className="line-success" />
                            <label><span>AxMatic</span>  0x1221591c1d77A9c334aBb0fe530ae6EE3aF51Af9</label>
                          </Col>
                        </Row>
                        <Row>
                          <ListGroup>
                            <ListGroupItem>
                              <span className="clrgr">Total Supply:</span> 
                              <br />
                              Collateral to Matic
                            </ListGroupItem>
                            <ListGroupItem>
                              <span className="clrgr">Tx Fee</span> 0%
                            </ListGroupItem>
                            <ListGroupItem>
                              <span className="clrgr">
                                <b>0.4% feeds staking contracts</b>
                                <b>0.1% treasury fund</b>
                              </span>
                            </ListGroupItem>
                          </ListGroup>
                        </Row>
                      </CardBody>
                      <CardFooter className="text-center">
                        <Button className="btn-simple" color="success" tag="a">
                        <Link to="/zap">
                         Get AxMatic
                          </Link> 
                        </Button>
                      </CardFooter>
                    </Card>
                  </Col>
                </Row>
                <div className="space-100"></div>
                <Row className="pd_30">
                  <Col md="6" lg="3">
                    <Card className="card-coin card-plain crd_c_yl">
                      <CardHeader>
                        <img
                          alt="..."
                          className="img-center img-fluid"
                          src={require("assets/img/lst_img_05.png")}
                        />
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col className="text-center" md="12">
                            <h4 className="text-uppercase">Magic</h4>
                            <hr className="line-danger" />
                            <label><span>Magic</span>0x7538588F72ddAdA8583409a4a72915d550371b8F</label>
                          </Col>
                        </Row>
                        <Row>
                          <ListGroup>
                            <ListGroupItem>
                              <span className="clrgr">Total Supply:</span> 
                              <br />
                              4,000,000
                            </ListGroupItem>
                            <ListGroupItem>
                              <span className="clrgr">Tx Fee</span> 1.5%
                            </ListGroupItem>
                            <ListGroupItem>
                              <span className="clrgr">
                                <b>1% feeds staking contracts</b>
                                <b>0.2% treasury fund</b>
                                <b>0.3% burns</b>
                              </span>
                            </ListGroupItem>
                          </ListGroup>
                        </Row>
                      </CardBody>
                      <CardFooter className="text-center">
                        <Button className="btn-simple" color="success" tag="a">
                        <Link to="/zap">
                         Get Magic
                          </Link> 
                        </Button>
                      </CardFooter>
                    </Card>
                  </Col>
                  <Col md="6" lg="3">
                    <Card className="card-coin card-plain crd_c_yl">
                      <CardHeader>
                        <img
                          alt="..."
                          className="img-center img-fluid"
                          src={require("assets/img/lst_img_06.png")}
                        />
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col className="text-center" md="12">
                            <h4 className="text-uppercase">Vein</h4>
                            <hr className="line-danger" />
                            <label><span> Vein </span> 0x8cf700B5b582faea1005d6cc5bdd7b88b95cc6C1 </label>
                          </Col>
                        </Row>
                        <Row>
                          <ListGroup>
                            <ListGroupItem>
                              <span className="clrgr">Total Supply:</span> 
                              <br />
                              LP WRAPPER
                            </ListGroupItem>
                            <ListGroupItem>
                              <span className="clrgr">Tx Fee</span> 0.5%
                            </ListGroupItem>
                            <ListGroupItem>
                              <span className="clrgr">
                                <b>0.4% feeds staking contracts</b>
                                <b>0.1% treasury fund</b>
                              </span>
                            </ListGroupItem>
                          </ListGroup>
                        </Row>
                      </CardBody>
                      <CardFooter className="text-center">
                        <Button className="btn-simple" color="success" tag="a">
                        <Link to="/zap">
                         Get Vein
                          </Link> 
                        </Button>
                      </CardFooter>
                    </Card>
                  </Col>
                  <Col md="6" lg="3">
                    <Card className="card-coin card-plain crd_c_yl">
                      <CardHeader>
                        <img
                          alt="..."
                          className="img-center img-fluid"
                          src={require("assets/img/lst_img_07.png")}
                        />
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col className="text-center" md="12">
                            <h4 className="text-uppercase">AxBNB</h4>
                            <hr className="line-danger" />
                            <label><span>AxBNB</span>0x81A10d7eEfD501974e84059293A890b15eF00cc0</label>
                          </Col>
                        </Row>
                        <Row>
                          <ListGroup>
                            <ListGroupItem>
                              <span className="clrgr">Total Supply:</span> 
                              <br />
                              LP WRAPPER
                            </ListGroupItem>
                            <ListGroupItem>
                              <span className="clrgr">Tx Fee</span> 0.5%
                            </ListGroupItem>
                            <ListGroupItem>
                              <span className="clrgr">
                                <b>0.4% feeds staking contracts</b>
                                <b>0.1% treasury fund</b>
                              </span>
                            </ListGroupItem>
                          </ListGroup>
                          
                        </Row>
                      </CardBody>
                      <CardFooter className="text-center">
                        <Button className="btn-simple" color="success" tag="a">
                        <Link to="/zap">
                         Get AxBNB
                          </Link> 
                        </Button>
                      </CardFooter>
                    </Card>
                  </Col>
                  <Col md="6" lg="3" className="drk_i_img_prnt">
                   <img
                      alt="..."
                      className="drk_i_img"
                      src={require("assets/img/drk_i_img.png")}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </Container>
        </section>
    </>
  );
}
