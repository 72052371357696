import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Row, Col } from "reactstrap";
import sectionTitleStyle from "../jss/sections/sectionTitleStyle";
import "moment-timezone";

const useStyles = makeStyles(sectionTitleStyle);

export default function SectionTitleV2() {

  return (
    <Grid container style={{ paddingTop: "4px" }} id="container">
      <Col xs={12} className="text-right mt-5">
      <label className="stk_lbl">
          {" "}
          Network Details:<span className="g_dot"></span>
          <span>Polygon</span> {' '}
         
          <a href="https://bsc.defimagic.io/#" >
          <span className="r_dot"></span>
          <span>BSC</span>
          </a>
        </label>
      </Col>
      <div className="space-100 d-nn" />
      <Row>
        <Col ></Col>
        <Col >
          <img src={require("assets/img/Drax.svg")} width="1200px" />
        </Col>
      </Row>
      <div className="space-100 d-nn" />
      <Col    xs={12} className="mt-3">

        <div className="tbl_header">
        <Grid    sm={2}>
      

          </Grid>
          <Grid    sm={2}>
            <span> Vault</span>
            <div className="tbl_h_action">
            </div>
          </Grid>
          <Grid    sm={3}>
            Tokens in Wallet
            <div className="tbl_h_action">
            </div>
          </Grid>
          <Grid    sm={3}>
            Tokens Staked
            <div className="tbl_h_action">
            </div>
          </Grid>
          <Grid    sm={3}>
            Rewards
            <div className="tbl_h_action">
            </div>
          </Grid>
          <Grid    sm={3}>
            Multiplier
            <div className="tbl_h_action">
            </div>
          </Grid>
        </div>
      </Col>
    </Grid>
  );
}
