import React, { useEffect } from 'react';
import SectionTitle from './sections/SectionTitle';
import SectionPools from './sections/SectionPools';
import appStyle from "./jss/sections/appStyle"
import { makeStyles } from "@material-ui/core/styles";
import { useConnectWallet } from '../home/redux/hooks';
import { useFetchPoolBalances} from './redux/hooks';
import SectionPoolsV2 from 'features/stakingv2/sections/SectionPoolsV2';
import SectionTitleV2 from 'features/stakingv2/sections/SectionTitlev2';
import SectionPoolsV2ends from 'features/stakingv2/sections/SectionPoolsV2ends';

const useStyles = makeStyles(appStyle);

export default function VaultPage() {
  const classes = useStyles();
  const { networkId } = useConnectWallet();
  let { stakingpools } = useFetchPoolBalances();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);
  return (
    <>
          <div className="header header-3a">
          <div>
            <div className="header-video3a header-filter3a">
            <div />
              <video
                autoPlay="manually"
                loop="loop"
                muted="muted"
                playsInline="playsinline"
              >
                <source
                  src={require("assets/video/dragon.mp4")}
                  type="video/mp4"
                />
              </video>
        <div className={classes.container} >
        <div className="space-100" />
      <SectionTitleV2 />
      </div>
 
      </div>
      <div className={classes.container} >
      <SectionPoolsV2 />
      </div>



      <div className={classes.container} >
        {stakingpools.map((pool) => (
      <SectionTitle key="titlebutton" pool={pool}/>
        ))}
       </div>
{/*        <div className={classes.container} >
     <SectionPools />
     </div> */}
     
     <div className={classes.container} >
      <SectionPoolsV2ends />
      </div>
      </div>
      </div>
      <div className="space-100" />
      <div className="space-100" />

      <div className="space-100" />

    </>
  );
}