import React from "react";
import { Col, Row } from "reactstrap";
import { Box, Typography } from "@material-ui/core";
import BigNumber from "bignumber.js";
import {
  useAllowance,
  useAllowance2,
  useApprove,
  useApprove2,
  useDeposit,
  useDeposit2,
  useTokenLeft,
  useBNBPrice,
  useMagicPrice,
  useTotal,
} from "../redux/hooks4";

export default function TabFourContent({ index, cpool }) {
  const allowance = useAllowance(cpool.tokenAddress, cpool.earnContractAddress);
  const allowance2 = useAllowance2(
    cpool.tokenAddress2,
    cpool.earnContractAddress
  );

  const { isPending: isApprovePending, onApprove } = useApprove(
    cpool.tokenAddress,
    cpool.earnContractAddress
  );

  const { isPending: isApprovePending2, onApprove2 } = useApprove2(
    cpool.tokenAddress2,
    cpool.earnContractAddress
  );

  const { onDeposit, isPending: isDepositPending } = useDeposit(
    cpool.earnContractAddress,
    cpool.tokenAddress
  );

  const { onDeposit2, isPending: isDepositPending2 } = useDeposit2(
    cpool.earnContractAddress,
    cpool.tokenAddress2
  );

  const MP = useBNBPrice(cpool.earnContractAddress);
  const TS = useTotal(cpool.earnContractAddress);

  const MGP = useMagicPrice(cpool.earnContractAddress);
  const TL = useTokenLeft(cpool.earnContractAddress);

  const forMat = (number) => {
    return new BigNumber(number)
      .multipliedBy(new BigNumber(10000))
      .dividedToIntegerBy(new BigNumber(1))
      .dividedBy(new BigNumber(10000))
      .toNumber();
  };

  return (
    <>
      <Box className="tabrow">
        <Box className="tabcol_3">
          {/* <Box component="img" src="/static/img/strg_mgc_img04.jpg" alt="" className="img-fluid tbbsc_img tbbsc_img03" /> */}
          <video
            autoplay="true"
            className="img-fluid tbbsc_img tbbsc_img03"
            loop
          >
            <source src="/static/img/cb.mp4" type="video/mp4" />
          </video>
          <Typography component="h6">Crystal Ball</Typography>
          <Typography component="h5">
            {/* {forMat(byDecimals(TL, 0).toNumber())} /{" "}
            {forMat(byDecimals(TS, 0).toNumber())}{" "} */}
          </Typography>
          <Box className="tabcol_3_bx">
            <Box component="img" src="/static/img/magic_icon.svg" alt="" />
            <Typography component="h4">
              <span>{/*  {forMat(byDecimals(MGP, 18).toNumber())} */}coming soon</span>{" "}
              
            </Typography>
           {/*  {allowance === "0" ? (
              <Button
                className="btn btn_green btnzap c_black btn_wtgdnt_v2"
                disabled
                onClick={() => {
                  onApprove();
                }}
                //disabled={isApprovePending[index]}
              >
                {isApprovePending ? `${"Approving..."}` : `${"Approve"}`}
              </Button>
            ) : (
              <Button
                className="btn btn_green btnzap c_black btn_wtgdnt_v2"
                onFocus={(event) => event.stopPropagation()}
                disabled
                // disabled={isDepositPending}
                onClick={(event) => {
                  onDeposit("12");
                }}
              >
                {" "}
                {"Buy"}
              </Button>
            )}{" "} */}
          </Box>
          {/*           <Box className="tabcol_3_bx">
            <Box component="img" src="/static/img/bnb_icon.svg" alt="" />
            <Typography component="h4">
              <span> {forMat(byDecimals(MP, 18).toNumber())}</span> BNB
            </Typography>
            {allowance2 === "0" ? (
              <Button
                className="btn btn_green btnzap c_black btn_wtgdnt_v2"
                onClick={() => {
                  onApprove2();
                }}
                disabled={isApprovePending2[index]}
              >
                {isApprovePending2 ? `${"Approving..."}` : `${"Approve"}`}
              </Button>
            ) : (
              <Button
                className="btn btn_green btnzap c_black btn_wtgdnt_v2"
                onFocus={(event) => event.stopPropagation()}
                disabled={isDepositPending2}
                onClick={(event) => {
                  onDeposit2("8", MP);
                }}
              >
                {" "}
                {"Buy"}
              </Button>
            )}
          </Box> */}
        </Box>
        <Box className="tabcol_9">
          <Row>
            <Col sm={12} lg={12} className="tabcol_9_tl min_100">
              <Box className="pilogo_sc pilogo_sc_v2">
                {/*                 <Box
                  component="img"
                  src="/static/img/picrcl_lg.png"
                  className="pilogoicon"
                  alt=""
                /> */}
                {/* <Box component="img" src="/static/img/pitext.png" alt="" /> */}
              </Box>
              {/*               <Button
                className="btn-simple"
                color="success"
                tag="a"
                target="_blank"
                href="https://www.pi-protocol.io"
              >
                Website
              </Button>
              <Button
                className="btn-simple"
                color="success"
                tag="a"
                target="_blank"
                href="https://t.me/PIProtocol"
              >
                Telegram
              </Button> */}
              <Typography component="p" className="mb-3 fnsz_22">
                The Saga continues! The Evil Wizards are winning. The Undead are
                invading. The Game of WAR draws near. Yet what shall unfold?{" "}
                <br></br>
                <br></br>
                The bearer of this crystal ball shall be granted insight to the
                secrets to come. Perhaps more. <br></br>
                <br></br>Beware! The witch, Clara, is the maker of this Green
                Crystal Ball. Energies infused may have glorious effects that
                may lead to life changing moments. Riches are sure to follow.
                But will the deeds to follow the effects be for Good or Evil?<br></br><br></br>
                The future comes with mysteries of what may likely unfold. And
                yet, with the knowledge of the future, will you try to change
                it? <br></br><br></br>The butterfly effect is real. Do you dare to wield this
                power?
              </Typography>
            </Col>
            {/* 
            <Col sm={12}>
              <Box
                component="img"
                src="/static/img/pi_tb_img.png"
                className="img-fluid pi_tb_img"
                alt=""
              />
            </Col> */}
          </Row>
        </Box>
      </Box>
    </>
  );
}
