
export const stakingpools = [
  { 
    chainId: 137,
    id: 'drax',
    name: 'DRAX',
    token: 'DRAX',
    tokenDescription: 'DRAX Vault',
    tokenDescription2: '- Ended -',
    tokenAddress: '0x1Ba3510A9ceEb72E5CdBa8bcdDe9647E1f20fB4b',
    tokenDecimals: 18,
    itokenDecimals: 18,
    depostLimit:0,
    depostAlert:'',
    tokenDescriptionUrl: '',
    tokenDescriptionUrl2: '',
    earnedToken: 'DRAX',
    earnedToken2: 'DMAGIC',
    claimedTokenAddress: '0x1Ba3510A9ceEb72E5CdBa8bcdDe9647E1f20fB4b',
    earnedTokenAddress: '0x1Ba3510A9ceEb72E5CdBa8bcdDe9647E1f20fB4b',
    earnContractAddress: '0x8EeDBb427Dc81e6fF8884C4c31Fc0cf3875d202a',
    defaultApy: "42.63",
    pricePerFullShare: 1,
    pastPricePerFullShare: 1,
  },
 
] 
