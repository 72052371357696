import React from "react";

import {
  Row,
  Col,
Button
} from "reactstrap";
import gdm from 'assets/img/getdm.png';
import gdm2 from 'assets/img/getdm2.png';

import { 
  FaTwitter, 
  FaTelegramPlane, 
  FaMediumM,
} from 'react-icons/fa'

export const Headers = () => {
  return (
    <>
      <div className="cd-section upelmnts" id="headers">
        <div className="header header-4">
          <div className="header-wrapper">

            <div className="page-header header-video header-filter">
              <div />
              <video
                autoPlay="manually"
                loop="loop"
                muted="muted"
                playsInline="playsinline"
              >
                <source
                  src={require("assets/video/magic_teaser.mp4")}
                  type="video/mp4"
                />
              </video>
              <Row>
                <Col className="mt-5 text-center d-nn" lg="6" xl="4"></Col>
                <Col className="mt-5 text-center mt-5-991" lg="6" xl="8">
                  <div className="space-100 d-nn space-0-1030" />
                  <img
                    alt="..."
                    src={require("assets/img/bnnr_img.png")}
                    width="auto"
                  />

                  <div className="getmagic">


                  <Button
                      className="btn gdm" tag="a" href="https://polygon.defimagic.io/#/zap"
                  >
                    {" "}
                    <img
                      width="45px"
                      height="45px"
                      background-color="#000000"
                      src={gdm}
                      alt="logo"
                    />
                    <img
                      width="150px"
                      height="auto"
                      background-color="#000000"
                      src={gdm2}
                      alt="logo"
                    />
                  </Button>
                   </div>

                  <div className="social_links">
          

                    <a target="_blank" rel="noopener noreferrer" href="https://t.me/defimagic"><FaTelegramPlane /></a> 
                    <a target="_blank"rel="noopener noreferrer"  href="https://twitter.com/defi_magic"> <FaTwitter /></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://defimagic.medium.com/"><FaMediumM /></a>
                  </div>

                </Col>
              </Row>
              <div className="ovlvd"></div>
            </div>
          </div>
        </div>
      </div>{" "}
    </>
  );
}


