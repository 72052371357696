import React from "react";
import BigNumber from "bignumber.js";
import { byDecimals } from "features/helpers/bignumber";

import {
  useAllowance,
  useAllowance2,
  useAllowance3,
  useApprove,
  useApprove2,
  useApprove3,
  useDeposit,
  useDeposit2,
  useDeposit3,
  useDmagicPrice,
  useMaticPrice,
  useTokenLeft,
  useTotalSupply,
  useTokenTotal,
  useYouOwn,
} from "../redux/hooks";

import Moment from "react-moment";
import "moment-timezone";
import { Col, Card, CardTitle, CardBody, Button } from 'reactstrap'
import { Grid, Box, Typography } from "@material-ui/core";


import depositimg from "../../../assets/img/deposit.svg";

Moment.globalLocal = true;


export default function PoolContent({ index, npool }) {

  const allowance = useAllowance(npool.tokenAddress, npool.earnContractAddress);
  const allowance2 = useAllowance2(npool.tokenAddress2, npool.earnContractAddress);
  const allowance3 = useAllowance3(npool.tokenAddress3, npool.earnContractAddress);

  const { isPending: isApprovePending, onApprove } = useApprove(
    npool.tokenAddress,
    npool.earnContractAddress
  );

  const { isPending: isApprovePending2, onApprove2 } = useApprove2(
    npool.tokenAddress2,
    npool.earnContractAddress
  );

  const { isPending: isApprovePending3, onApprove3 } = useApprove3(
    npool.tokenAddress3,
    npool.earnContractAddress
  );


  const { onDeposit, isPending: isDepositPending } = useDeposit(
    npool.earnContractAddress,
    npool.tokenAddress
  );

  const { onDeposit2, isPending: isDepositPending2 } = useDeposit2(
    npool.earnContractAddress,
    npool.tokenAddress2
  );
  const { onDeposit3, isPending: isDepositPending3 } = useDeposit3(
    npool.earnContractAddress,
    npool.tokenAddress3
  );

  const MP = useMaticPrice(npool.earnContractAddress);
  const DMP = useDmagicPrice(npool.earnContractAddress);
  const TL = useTokenLeft(npool.earnContractAddress);
  //const TN = useTokenName(npool.earnContractAddress);
  const TS = useTotalSupply(npool.earnContractAddress);
  const TTS = useTokenTotal(npool.earnContractAddress);
  const YO = useYouOwn(npool.earnContractAddress);
  //const TOS = useTokenSymbol(npool.earnContractAddress);


  const forMat = (number) => {
    return new BigNumber(number)
      .multipliedBy(new BigNumber(10000))
      .dividedToIntegerBy(new BigNumber(1))
      .dividedBy(new BigNumber(10000))
      .toNumber();
  };

  return (
    <>
      <Col lg="4" md="6">
      <Card className="card-plain card-blog2 card-plainas">
        <Box className="card_plainas_inn">

          <div className="card-image2">
          <Typography component="h4">{npool.name}</Typography>

            <video
              autoPlay="manually"
              loop="loop"
              muted="muted"
              playsInline="playsinline"
            >
              <source
                src={require(`../../../images/${npool.id}-nft.mp4`)}
                type="video/mp4"
              />
            </video>
          </div>
          <CardBody>
          <CardTitle tag="h4" className="text-center">
          <Button className="btn btn-stk3 btn_wtgdnt_v2 btn_wtgdnt_v3" target="_blank"
          href={npool.link}
          > Buy/Sell on OpenSea.io </Button>
          </CardTitle>
            <CardTitle tag="h4" className="text-center">
            { npool.status === "sold" ? ( 
               <Typography component="p" className="btn_btm_p">
              <span> SOLD OUT</span>
               </Typography>
              ) :(  
              <Typography component="p" className="btn_btm_p">
                   <span> {' Available '} </span>
                </Typography>
              ) }
              


              {allowance === "0" ? (
                <div className="progress_action mt-3">
                  <button
                      className="btn btn-stk2 btn_wtgdnt_v2 btn_wtgdnt_v3"
                      onClick={() => {
                      onApprove();
                    }}

                    disabled={isApprovePending[index] || npool.status === "sold"}
                  >
                    <img
                      className="mr-2 fr_def"
                      width="25"
                      height="auto"
                      background-color="#000000"
                      src={depositimg}
                      alt="logo"
                    />
                    {isApprovePending
                      ? `${("Approving...")}`
                      : `${("Approve for dMagic")}`}
                  </button>
                </div>
              ) : (
                <div className="progress_action  mt-3">
                  <button
                      className="btn btn-stk2 btn_wtgdnt_v2 btn_wtgdnt_v3"
                      onFocus={(event) => event.stopPropagation()}
                    disabled={isDepositPending || npool.status === "sold" }
                    onClick={(event) => {
                      onDeposit(DMP)
                    }}
 
                  >
                    {" "}
                    <img
                      className="mr-2 fr_def"
                      width="25"
                      height="auto"
                      background-color="#000000"
                      src={depositimg}
                      alt="logo"
                    />
                    {("Buy with dMagic")}

                  </button>
                </div>
              )}

              { npool.status === "sold" ? ( 
               <Typography component="p" className="btn_btm_p">
              NFT price in dMagic:  <span> SOLD OUT</span>
               </Typography>
              ) :(  
               <Typography component="p" className="btn_btm_p">
              NFT price in dMagic:  <span>{forMat(byDecimals(DMP, 18).toNumber())}</span>
              </Typography>
              )}

              {allowance2 === "0" ? (
                <div className="progress_action  mt-3">
                  <button
                      className="btn btn-stk2 btn_wtgdnt_v2 btn_wtgdnt_v3"
                      onClick={() => {
                      onApprove2();
                    }}

                    disabled={isApprovePending2[index] || npool.status === "sold"}
                  >
                    <img
                      className="mr-2 fr_def"
                      width="25"
                      height="auto"
                      background-color="#000000"
                      src={depositimg}
                      alt="logo"
                    />
                    {isApprovePending2
                      ? `${("Approving...")}`
                      : `${("Approve for AxMatic")}`}
                  </button>
                </div>
              ) : (
                <div className="progress_action  mt-3">
                  <button
                      className="btn btn-stk2 btn_wtgdnt_v2 btn_wtgdnt_v3"
                      onFocus={(event) => event.stopPropagation()}
                    disabled={isDepositPending2 || npool.status === "sold"}
                    onClick={(event) => {
                      onDeposit2(MP)
                    }}

                  >
                    {" "}
                    <img
                      className="mr-2 fr_def"
                      width="25"
                      height="auto"
                      background-color="#000000"
                      src={depositimg}
                      alt="logo"
                    />
                    {("Buy with AxMatic")}
                  </button>
                </div>
              )}


{ npool.status === "sold" ? ( 
               <Typography component="p" className="btn_btm_p">
              NFT price in dMagic:  <span> SOLD OUT</span>
               </Typography>
              ) :(  

                <Typography component="p" className="btn_btm_p">
                  NFT price in AxMatic: <span>{forMat(byDecimals(MP, 18).toNumber())}</span>
                </Typography>

              )}


              {allowance3 === "0" ? (
                <div className="progress_action  mt-3">
                  <button
                      className="btn btn-stk2 btn_wtgdnt_v2 btn_wtgdnt_v3"
                      onClick={() => {
                      onApprove3();
                    }}

                    disabled={isApprovePending3[index] || npool.status === "sold"}
                  >
                    <img
                      className="mr-2 fr_def"
                      width="25"
                      height="auto"
                      background-color="#000000"
                      src={depositimg}
                      alt="logo"
                    />
                    {isApprovePending3
                      ? `${("Approving...")}`
                      : `${("Approve")}`}
                  </button>
                </div>
              ) : (
                <div className="progress_action mt-3">
                  <button
                      className="btn btn-stk2 btn_wtgdnt_v2 btn_wtgdnt_v3"
                      onFocus={(event) => event.stopPropagation()}
                    disabled={isDepositPending3 || npool.status === "sold"}
                    onClick={(event) => {
                      onDeposit3(MP)
                    }}

                  >
                    {" "}
                    <img
                      className="mr-2 fr_def"
                      width="25"
                      height="auto"
                      background-color="#000000"
                      src={depositimg}
                      alt="logo"
                    />
                    {("Buy with Matic")}
                  </button>
                </div>
              )}


            { npool.status === "sold"  ? ( 
               <Typography component="p" className="btn_btm_p">
              NFT price in dMagic:  <span> SOLD OUT</span>
               </Typography>
              ) :(  
              <Typography component="p" className="btn_btm_p">
                  NFT price in Matic: <span>{forMat(byDecimals(MP, 18).toNumber())}</span>
                </Typography>
              ) }

                <div className="space-50"></div>
                <Box className="itm_btminfo">
                  <Typography component="h4">{npool.name2}</Typography>
                  <Typography component="p">The {npool.name} is {npool.class1} and {npool.class2} egg.</Typography>
                </Box>
                <Box className="itm_btminfo_v2">
                  <Grid container spacing={0}>
                    <Grid item xs={12} lg={4}>
                      <Typography component="p">NFTs Sold: <span>{forMat(byDecimals(TS, 0).toNumber())}</span></Typography>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Typography component="p">NFTs Available: <span>{forMat(byDecimals(TL, 0).toNumber())}</span></Typography>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Typography component="p">Total NFTs: <span>{forMat(byDecimals(TTS, 0).toNumber())}</span></Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box className="itm_btminfo_v3">
                  <Typography component="p">you have <span>{YO}</span> {npool.name2} in your inventory</Typography>
                </Box>


            </CardTitle>
          </CardBody>
          </Box>

        </Card>
      </Col>
    </>
  );
}
