import React, { useState, useEffect, useRef } from 'react'
//import logo from 'assets/img/logo2.png';
//import vault from 'assets/img/vault2.svg';
//import vault_g from 'assets/img/vault2_g.svg';
//import wrapper from 'assets/img/wrapper.svg';
//import wrapper_g from 'assets/img/wrapper_g.svg';
import zapper from 'assets/img/zapper.svg';
import zapper_b from 'assets/img/zapper_b.svg';
//import home from 'assets/img/home.svg';
//import home_g from 'assets/img/home_g.svg';
import stk_img from 'assets/img/stake_ic.svg';
import stk_img_h from 'assets/img/stake_ic_g.svg';
import cldrn_ic from 'assets/img/the_cauldron_ic.svg';
import cldrn_ic_gr from 'assets/img/the_cauldron_ic_gr.svg';
//import check_img from 'assets/img/check_ic.svg';
//import check_img_h from 'assets/img/check_ic_gr.svg';
import nft_icon from 'assets/img/nft_icon.svg';
import nft_icon_gr from 'assets/img/nft_icon_gr.svg';
import nft_icon_gr_pg from 'assets/img/the_cauldron_ic_pg.svg';

import { Button, Collapse,Navbar, NavItem, Nav, Container, Row, Col, } from "reactstrap";
import { Link } from 'react-router-dom';
import { renderIcon } from '@download/blockies'
import { useLocation } from "react-router-dom";

export default function ColorNavBar(props) {
  const { connected, address, connectWallet, disconnectWallet } = props;

  const [isCollapsed, setCollapsed] = useState(true);
  const canvasRef = useRef(null)
  const [shortAddress, setShortAddress] = useState('');
  const [dataUrl, setDataUrl] = useState(null);

  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  React.useEffect(() => {
    window.addEventListener("scroll", changeNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", changeNavbarColor);
    };
  }, []);
  const changeNavbarColor = () => {
    if (
      document.documentElement.scrollTop > 299 ||
      document.body.scrollTop > 299
    ) {
      setNavbarColor("bg-info");
    } else if (
      document.documentElement.scrollTop < 300 ||
      document.body.scrollTop < 300
    ) {
      setNavbarColor("navbar-transparent");
    }
  };

  useEffect(() => {
    if (!connected) return;
    const canvas = canvasRef.current
    renderIcon({ seed: address.toLowerCase() }, canvas)
    const updatedDataUrl = canvas.toDataURL()
    if (updatedDataUrl !== dataUrl) {
      setDataUrl(updatedDataUrl)
    }
    if (address.length < 11) {
      setShortAddress(address)
    } else {
      setShortAddress(`${address.slice(0, 6)}...${address.slice(-4)}`)
    }
  }, [dataUrl, address, connected]);

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  return (
    <Navbar className={"as_nvbr fixed-top " + navbarColor} expand="lg">

      <Container>
        <div className="navbar-translate">


          <div className="navbar-logo ">
          <Link to="/">
            <img
              alt="..."
              className="navbar-logo"
              src={require("assets/img/spell2.png")}
            />
          </Link>
            
          </div>
          <button
            aria-expanded={!isCollapsed}
            className="navbar-toggler navbar-toggler"
            onClick={() => setCollapsed(!isCollapsed)}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className={"justify-content-center "}
          isOpen={!isCollapsed}
          navbar
        >
          <div className="navbar-collapse-header">
            <Row>
              <Col className="collapse-brand" xs="6">
                Dark Magic
              </Col>
              <Col className="collapse-close text-right" xs="6">
                <button
                  aria-expanded={!isCollapsed}
                  className="navbar-toggler"
                  onClick={() => setCollapsed(!isCollapsed)}
                >
                  <span className="navbar-toggler-bar bar4" />
                  <span className="navbar-toggler-bar bar5" />
                  <span className="navbar-toggler-bar bar6" />
                </button>
              </Col>
            </Row>
          </div>

          <Nav navbar>
          <NavItem className={splitLocation[1] === "zap" ? "active" : ""}>
              <Link className="nav-link" to="/zap" onClick={() => !isCollapsed && setCollapsed(true)}>
                <span className="d-block">Zapper</span>
             
                <span className="mn_icc">
                  <img
                    className="fr_def"
                    width="25"
                    height="auto"
                    background-color="#000000"
                    src={zapper}
                    alt="logo"
                  />
                  <img
                    className="fr_active"
                    width="25"
                    height="auto"
                    background-color="#000000"
                    src={zapper_b}
                    alt="logo"
                  />
                </span>
              </Link>
            </NavItem>
            {/* <NavItem className={splitLocation[1] === "" ? "active" : ""}>
              

              <Link className="nav-link" to="/" onClick={() => !isCollapsed && setCollapsed(true)}>
              <span className="d-block">Home</span>
              
              <span className="mn_icc">
                <img
                  className="fr_def"
                  width="25"
                  height="auto"
                  background-color="#000000"
                  src={home}
                  alt="logo"
                />
                <img
                  className="fr_active"
                  width="25"
                  height="auto"
                  background-color="#000000"
                  src={home_g}
                  alt="logo"
                />
              </span>
              </Link>
            </NavItem> */}
          {/*   <NavItem className={splitLocation[1] === "vault" ? "active" : ""}>
              <Link className="nav-link" to="/vault" onClick={() => !isCollapsed && setCollapsed(true)}>
                <span className="d-block">Vault</span>
                <span className="mn_icc">
                  <img
                    className="fr_def"
                    width="25"
                    height="auto"
                    background-color="#000000"
                    src={vault}
                    alt="logo"
                  />
                  <img
                    className="fr_active"
                    width="25"
                    height="auto"
                    background-color="#000000"
                    src={vault_g}
                    alt="logo"
                  />
                </span>
              </Link>
            </NavItem> */}
            <NavItem className={splitLocation[1] === "staking" ? "active" : ""}>
              <Link className="nav-link" to="/staking" onClick={() => !isCollapsed && setCollapsed(true)}>
                <span className="d-block">Stake</span>
              
                <span className="mn_icc">
                  <img
                    className="fr_def"
                    width="25"
                    height="auto"
                    background-color="#000000"
                    src={stk_img}
                    alt="logo"
                  />
                  <img
                    className="fr_active"
                    width="25"
                    height="auto"
                    background-color="#000000"
                    src={stk_img_h}
                    alt="logo"
                  />
                </span>
                
              </Link>
            </NavItem>

            <NavItem className={splitLocation[1] === "nft" ? "active" : ""}>
              <Link className="nav-link" to="/nft" onClick={() => !isCollapsed && setCollapsed(true)}>
                <span className="d-block">NFT</span>
                <span className="mn_icc">
                  <img
                    className="fr_def"
                    width="15"
                    height="auto"
                    background-color="#000000"
                    src={nft_icon}
                    alt="logo"
                  />
                  <img
                    className="fr_active"
                    width="15"
                    height="auto"
                    background-color="#000000"
                    src={nft_icon_gr}
                    alt="logo"
                  />
                </span>
              </Link>
            </NavItem>

          
            <NavItem className={splitLocation[1] === "thecauldron" ? "active" : ""}>
              <Link className="nav-link" to="/thecauldron" onClick={() => !isCollapsed && setCollapsed(true)}>
                <span className="d-block"><span>The</span>Cauldron</span>
                <span className="mn_icc">
                  <img
                    className="fr_def acvnn"
                    width="40"
                    height="auto"
                    background-color="#000000"
                    src={cldrn_ic}
                    alt="logo"
                  />
                  <img
                    className="fr_active acvnn"
                    width="40"
                    height="auto"
                    background-color="#000000"
                    src={cldrn_ic_gr}
                    alt="logo"
                  />
                  <img
                    className="fr_active_pg"
                    width="25"
                    height="auto"
                    background-color="#000000"
                    src={nft_icon_gr_pg}
                    alt="logo"
                  />
                  
                </span>
                {/* <span className="d-block fr_active_pg" ><span>Partnerships</span></span> */}
                <br></br>
                {/* <span className="d-block fr_def fr_active acvnn" ><span>Partnerships</span></span> */}

              </Link>
                          
              
            </NavItem>

            <NavItem className={splitLocation[1] === "mts" ? "active" : ""}>
              <Link className="nav-link" to="/mts" onClick={() => !isCollapsed && setCollapsed(true)}>
                <span className="d-block">Free Profits</span>
             
                <span className="mn_icc">
                  <img
                    className="fr_def"
                    width="25"
                    height="auto"
                    background-color="#000000"
                    src={zapper}
                    alt="logo"
                  />
                  <img
                    className="fr_active"
                    width="25"
                    height="auto"
                    background-color="#000000"
                    src={zapper_b}
                    alt="logo"
                  />
                </span>
              </Link>
            </NavItem>


            
        {/*     <NavItem className={splitLocation[1] === "check" ? "active" : ""}>
              <Link className="nav-link" to="/check" onClick={() => !isCollapsed && setCollapsed(true)}>
                <span className="d-block">Check</span>
              
                <span className="mn_icc">
                  <img
                    className="fr_def"
                    width="25"
                    height="auto"
                    background-color="#000000"
                    src={check_img}
                    alt="logo"
                  />
                  <img
                    className="fr_active"
                    width="25"
                    height="auto"
                    background-color="#000000"
                    src={check_img_h}
                    alt="logo"
                  />
                </span>
              </Link>
            </NavItem> */}
            

            <NavItem className={splitLocation[1] === "gitbook" ? "active" : ""}>
              
               {' '} 
               <a className="nav-link" tag="a" rel="noopener noreferrer" target="_blank" href="https://defi-magic.gitbook.io/defimagic/">
                  <span className="d-block">Gitbook</span>
                  <span className="mn_icc"> 
                  <img
                    width="25"
                    height="auto"
                    background-color="#000000"
                    alt="..."
                    className="fr_def"
                    src={require("assets/img/gitbook_icon.svg")}
                  />
                  <img
                    width="25"
                    height="auto"
                    background-color="#000000"
                    alt="..."
                    className="fr_active"
                    src={require("assets/img/gitbook_icon_g.svg")}
                  />
                  </span>
                </a>
               
            </NavItem>

            
          </Nav>

             
          <div className="space-100x"></div>


          <Button
                className="hdrwlltbtn"
                type="button"
                color="primary"
                onClick={connected ? disconnectWallet : connectWallet}
              >
                {connected ? (
                  <><img
                    alt="..."
                    src={require("assets/img/wallet_ic.svg")}
                    height="20px"
                  />
                    {" "}{""}

                    <canvas ref={canvasRef} style={{ display: 'none' }} />
                    {shortAddress}
                  
                  </>
                ) : (
                  <>
                                     <img
                      alt="..."
                      src={require("assets/img/wallet_ic.svg")}
                      height="25px"
                      width="25"
                      className="def_wlt_ic"
                    />
                    <img
                      alt="..."
                      src={require("assets/img/wallet_ic_w.svg")}
                      height="25px"
                      width="25"
                      className="def_wlt_ic_hvr"
                    /> {('Wallet')}
                  </>
                )}
              </Button>
        </Collapse>
      </Container>

   
    </Navbar>
  )
}

// 115   <ConnectButton style={{marginLeft: 8}} />

